import { verifyHasClass, componentSpacing, mobileFontSize, changeOneClass } from '../../helpers/helpers';
import { filter } from '../../helpers/completeListIcons';
import { hmltIconsElementString, allIcons } from '../../helpers/completeListIcons';

import ColorPicker from '../../helpers/colorPicker.js';
const colorPicker = new ColorPicker();

const contents = hmltIconsElementString(); // carrega todos os icones
let arrIds = [];

export function initForm(component) {
  arrIds = [];
  let i = 1;
  component.find('.item').each(function () {
    // if (component.find(`div.item:nth-child(${i})`).length) {
    const compView = $(this); //component.find(`div.item:nth-child(${i})`);

    arrIds.push({
      id: i,
      component: compView,
    });
    // }
    i++;
  });
}

export function initSettingFormButton(form, keditor, itemId, emptyContent) {
  mobileFontSize('.keditor-component-content', 20, form, null, keditor, 2);
  componentSpacing('.list-content', form, null, keditor, 2);

  form.find('#icon-size').on('change', function () {
    const icons = keditor.getSettingComponent().find('.item').find('i');
    icons.removeClass('fz-lg fz-2x fz-3x fz-4x fz-5x');
    icons.addClass(this.value);
  });

  form.find('#item-spacing').on('change', function () {
    const firstChild = keditor.getSettingComponent().find('.list-content').children().first();

    keditor.getSettingComponent().find('.item').not(firstChild).css('margin-top', `${this.value}px`);
  });

  //Color
  colorPicker.init(form, (color) => {
    const icons = keditor.getSettingComponent().find('.item').find('i');
    icons.css('color', color);
  });

  form.find('#btn-font-size').on('input', function () {
    // const text = keditor.getSettingComponent().find(".item-title");
    const text = keditor.getSettingComponent().find('.item').find('p');
    // .find("span");

    form.find('#btn-font').val(this.value);
    const value = this.value;
    text.css({ fontSize: '' });
    text.find('span').css({ fontSize: '' });
    text.each(function () {
      const item = $(this).children().last();
      item.css('fontSize', value + 'px');
    });
  });

  const addItem = form.find('#plus-item');
  const itemGroup = form.find('#item-group');

  addItem.on('click', function () {
    const component = keditor.getSettingComponent();
    const list = component.find(`.list-content`);

    const margin = !list.find('.item-0').length ? `style="margin-top: ${form.find('#item-spacing').val()}px"` : '';

    const random = Math.floor(Math.random() * 9999);
    const newItemId = !list.find('.item-0').length
      ? random //list.children().length + 1
      : 1;

    const item = `
    <div
      class="media item align-items-center item-${newItemId}"
      ${margin}
    >
      <i
        class="fa fa-check ${form.find('#icon-size').val()} icon-text mr-3"
        style="color: ${form.find('#input-color').find('input').val()}"
      ></i>
      <div class="media-body">
        <p>
          <span
            class="${form.find('.mobile-font-size').val()}"
            style="color: ${list.find('span').css('color')}; font-size: ${form.find('#btn-font-size').val()}px;"
          >Clique aqui para editar</span>
        </p>
      </div>
    </div>
    `;

    if (list.find('.item-0').length) {
      list.html(item);
      form.find('.list-config').show();
    } else list.append(item);

    const lastItemId = list.children().length;
    arrIds.push({
      id: random, // arrIds.length + 1,
      component: component.find('div.item').eq(lastItemId - 1), //component.find(`div.item:nth-child(${lastItemId})`),
    });
    // itemGroup.append(formListItem(lastItemId, '<i class="fa fa-check" ></i>'));
    itemGroup.append(formListItem(random, '<i class="fa fa-check" ></i>', lastItemId));

    // formIcon(form, keditor, contents, lastItemId);
    // formRemoveItem(form, keditor, lastItemId, emptyContent);
    formIcon(form, keditor, contents, random);
    formRemoveItem(form, keditor, random, emptyContent);
  });
}

export function showSettingFormButton(form, component, keditor, itemId, emptyContent) {
  const componentContent = component.find('.keditor-component-content').children().children();

  mobileFontSize(componentContent.find('span'), 20, form, component, keditor, 1);

  const listContent = component.find('.list-content');
  const itemGroup = form.find('#item-group').html('');
  const iconSize = form.find('#icon-size');
  const itemSpacing = form.find('#item-spacing');
  const inputFontSize = form.find('#btn-font-size');
  const labelFontSize = form.find('#btn-font');

  const icon = component.find('.item').find('i');
  const span = component.find('.item').find('p').find('span');

  iconSize.val(verifyHasClass(icon, ['fz-lg', 'fz-2x', 'fz-3x', 'fz-4x', 'fz-5x']) || 'fz-3x');

  inputFontSize.val(span.length ? span.css('font-size').split('px')[0] : 15);
  labelFontSize.val(span.length ? span.css('font-size').split('px')[0] : 15);

  if (!component.find('.item-0').length) {
    itemSpacing.val(
      component.find('.item:not(:first)').length
        ? component.find('.item:not(:first)').css('margin-top').split('px').join('')
        : 10
    );

    let i = 0;
    arrIds = [];
    component.find('.item').each(function () {
      // if (component.find(`div.item:nth-child(${i})`).length) {
      const compView = component.find(`div.item:nth-child(${i + 1})`);
      arrIds.push({
        id: i + 1,
        component: compView,
      });
      i++;
    });

    arrIds.forEach((el, index) => {
      const compView = el.component;
      const icon = compView.find('i');
      const dropdownIcon = verifyHasClass(icon, Object.keys(allIcons));
      itemGroup.append(formListItem(el.id, `<i class="fa ${dropdownIcon}" ></i>`, index + 1));

      formIcon(form, keditor, contents, el.id);
      formRemoveItem(form, keditor, el.id, emptyContent);
    });
  }

  colorPicker.show(form, icon.css('color') || 'rgb(33, 37, 41)');

  // Espaçamentos
  componentSpacing(listContent, form, component, keditor, 1);
}

function formListItem(id, icon, inputPos) {
  return `
  <div 
    class="d-flex flex-nowrap mt-2 item-config"
    id="item-${id}"
  >
    <div 
      class="dropdown mr-1 col-sm-4 mx-0 px-0" 
      id="dropdown-${id}"
    >
      <button
        class="btn btn-primary
          dropdown-toggle d-flex justify-content-between
          align-items-center w-100 h-100"
        type="button"
        id="item-icon-${id}"
        data-toggle="dropdown"
        aria-haspopup="true" 
        aria-expanded="false" 
        data-offset="-40,0"
      >
      ${icon}
      </button>
      <div 
        class="dropdown-menu col-sm-12" 
        aria-labelledby="item-icon" 
      >
        <div class="dropdown-header">
          <input
            type="search"
            class="form-control searchIcon"
            placeholder="address book"
            autofocus="autofocus"
          />
        </div>
        <div class="menuItems" id="menuItems-${id}"></div>
      </div>
    </div>
    <input 
      id="text-${id}"
      type="text"
      class="form-control text-field"
      placeholder="Item da lista"
      value="item #${inputPos}"
      readonly
    />
    <div class="col-sm-2 h-100 mx-0 px-0" >
      <button 
        class="btn btn-danger text-white remove-item" 
      >
        <i class="fa fa-trash fz-lg"></i>
      </button>
    </div>
  </div>`;
}

function formIcon(form, keditor, content, id) {
  // const items = form.find(`.menuItems`);
  const items = form.find(`#menuItems-${id}`);
  const search = form.find('.searchIcon');
  const dropdownButton = form.find(`#item-icon-${id}`);
  items.append(content);

  search.on('keyup', function () {
    filter(this.value, items);
  });

  items.find('.dropdown-item').on('click', function () {
    const component = keditor.getSettingComponent();
    const hasId = arrIds.find((el) => el.id == id);

    if (hasId) {
      const item = hasId.component;
      const icon = item.find('i');

      let type = $(this).attr('class').split(' fa ');

      if (icon.length !== 0) {
        const classString = changeOneClass(icon, 'fa-', type[1]);
        icon.removeClass();
        icon.addClass(classString);
      } else {
        item.prepend(`
            <i class="fa ${type[1]} fz-2x icon-text mr-3" ></i>
          `);
      }

      dropdownButton.html(`<i class="fa ${type[1]}" ></i>`);
    }
  });
}

function formRemoveItem(form, keditor, id, msg = '') {
  const item = form.find(`#item-${id}`);
  item.find(`.remove-item`).on('click', function () {
    const component = keditor.getSettingComponent();
    // const itemView = component.find(`div.item:nth-child(${id})`);
    const listLength = component.find('.list-content').children().length;

    const hasId = arrIds.find((el) => el.id == id);

    if (arrIds.indexOf(hasId) != -1) {
      arrIds.splice(arrIds.indexOf(hasId), 1);

      component.find(hasId.component).remove();
      item.remove();
      const textField = form.find(`.text-field`); //.find("input");
      let i = 1;
      textField.each(function () {
        this.value = 'item #' + i;
        i++;
      });

      arrIds = arrIds.map((el, index) => {
        const newId = el.id; //index + 1;

        form.find(`.item-config`).eq(index).attr('id', `item-${newId}`);
        return {
          id: newId,
          component: el.component,
        };
      });
    }

    if (listLength === 1) {
      component.find('.list-content').html(`
        <li 
          class="d-flex item align-items-center justify-content-center item-0"
        >${msg}</li>`);

      form.find('.list-config').hide();

      return;
    }
  });
}
