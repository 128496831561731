import KEditor from 'keditor'
import FormComponent from './containers/form'
import { hideMobileDesktop } from './helpers/form.settings'

import initTimeDelay from './helpers/timeDelay/initSettings'
import showTimeDelay from './helpers/timeDelay/showSettings'
import {initAnimations, showAnimations} from './helpers/animations'

let formComponent = new FormComponent()
KEditor.components['form-integration'] = {
  settingEnabled: true,

  settingTitle: 'Formulário',

  init: function (contentArea, container, component, keditor) {
    formComponent.keditor = keditor
    formComponent.component = component

    formComponent.create()
  },

  initSettingForm: function (form, keditor) {
    formComponent.form = form

    form.append(formComponent.template)
    formComponent.init()
    //Default calls
    initTimeDelay.call(self, form, keditor)
    initAnimations(form,keditor)
    hideMobileDesktop(form, null, keditor, '.form-content', 'edit')
  },

  showSettingForm: function (form, component, keditor) {
    formComponent.component = component
    formComponent.show()
    //Default calls
    showTimeDelay.call(self, form, component, keditor)
    showAnimations(form,keditor)
    hideMobileDesktop(form, component.find('.form-content'), keditor, null, '')
  },
}
