import { verifyHasClass } from '../../helpers/helpers'
import { BLUE_COLOR } from '../../../constants'
import { componentSpacing, mobileFontSize } from '../../helpers/helpers'
import { DEFAULT_ACTION_SELECT } from '../../../constants'
import {
  useFontSelector,
  useToggleButton,
  useFontSizeResponsive,
  useBorderRadius,
  useBorderWidth,
  useBoxShadow,
  useFontStyle,
  useBorderColor,
  useIconSelect,
  useDropdown,
  useAlignmentBlock,
  useBackgroundColor,
  useBackground,
  useBackgroundGradient
} from '../../microComponents'
const useComponents = [
  useFontSelector,
  useToggleButton,
  useBoxShadow,
  useBorderColor,
  useIconSelect,
  useDropdown,
  useAlignmentBlock,
]

import ColorPicker from '../../helpers/colorPicker'
import { MODAL_ID } from '../../../ReactComponents/Popup/shared/constants'
const colorPicker = new ColorPicker()

export default class ButtonComponent {
  #elements = {
    selectBtnFunctionality: {
      selector: '#set-functionality',
      data: null,
    },
    inputText: {
      selector: '#button-text',
      data: null,
    },
    inputSubText: {
      selector: '#sub-button-text',
      data: null,
    },
    btnFontSize: {
      selector: '#btn-font-size',
      data: null,
    },
    subBtnFontSize: {
      selector: '#sub-btn-font-size',
      data: null,
    },
    inputBtnLink: {
      selector: '#button-link',
      data: null,
    },
    inputBtnTarget: {
      selector: '.button-target-type',
      data: null,
    },
    btnTargetLabel: {
      selector: '#button-target-label',
      data: null,
    },
    inputAlign: {
      selector: '#button-align',
      data: null,
    },
    inputWidth: {
      selector: '#button-width',
      data: null,
    },
    btnStyle: {
      selector: '.button-style',
      data: null,
    },
    rollToElement: {
      selector: '#roll-to-element-config',
      data: null,
    },
    sendPageParams: {
      selector: '#send-page-params',
      data: null,
    },
    openNewTab: {
      selector: '#switch-open-new-page',
      data: null,
    },
  }
  #buttonRef = '.button-style'
  #buttonWrapperRef = '.button-wrapper'

  constructor(form, keditor, component) {
    this.form = form
    this.keditor = keditor
    this._component = component

    Object.keys(this.#elements).map((key) => {
      this.#elements[key].data = this.form.find(this.#elements[key].selector)
    })

    useComponents.map((component) => {
      const aux = component({
        form: this.form,
        keditor,
        elementRef: () => this.getRef(this.#buttonRef),
      })
      this[aux.componentName] = aux
    })

    //Background
    this.background = useBackground({
      customTabs: [
        {
          handle: useBackgroundColor({
            form: this.form, elementRef: () => this.getRef(this.#buttonRef)
          }),
          initCallback: (color) => {
            setTimeout(() =>
              this.boxShadow.setBoxShadow({
                target: { value: this.getRef(this.#buttonRef).attr('data-box-shadow-type') },
              }), 200
            )
          }
        },
        {
          handle: useBackgroundGradient({
            form: this.form, elementRef: () => this.getRef(this.#buttonRef)
          })
        }
      ]
    })

    this.borderRadius = useBorderRadius({
      form: this.form,
      keditor,
      elementRef: () => this.getRef(this.#buttonRef),
      customOptions: [{name: 'Quadrada', value: '0px'},{ name: '3px'},{ name: '5px'},{ name: '10px'},{ name: '15px'},{ name: 'Circular', value: '50px'}]
    })

    this.borderWidth = useBorderWidth({
      form: this.form,
      keditor,
      elementRef: () => this.getRef(this.#buttonRef),
      customOptions: [{ name: 'Nenhuma', value: '0px' }, { name: '1px' }, { name: '2px' }, { name: '3px' }, { name: '5px' }],
    })

    this.fontStyle = useFontStyle({
      form: this.form,
      keditor,
      elementRef: () => this.getRef(this.#buttonRef).find('.btn-title .text'),
    })

    this.buttonAlignment = useAlignmentBlock({
      form: this.form,
      keditor,
      elementRef: () => this.getRef(this.#buttonWrapperRef),
    })

    const fontSizeOptions = (customFormRef) => ({
      customFormRef,
      form: this.form,
      keditor,
      elementRef: () => this.getRef(this.#buttonRef),
    })
    this.fontSizeResponsiveTitle = useFontSizeResponsive(fontSizeOptions('font-size-responsive-Title'))
    this.fontSizeResponsiveSubtitle = useFontSizeResponsive(fontSizeOptions('font-size-responsive-Subtitle'))
  }

  set component(newComponent) {
    this._component = newComponent
  }

  getRef(target) {
    return this.keditor.getSettingComponent() && this.keditor.getSettingComponent().find(target)
  }

  validationHref(value) {
    if (!value) return null
    const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|#|www\.|)/
    let valueMatch = value.match(regex)

    if (valueMatch && valueMatch[0]) {
      const valTarget = valueMatch[0].indexOf('www.') != -1 ? valueMatch[0].split('www.')[0] : valueMatch[0]

      let label = '#'
      if (valTarget != '#' && valTarget.length) label = valTarget
      else if (!valTarget.length) label = 'https://'

      return label
    }
    return null
  }

  buttonTarget(type) {
    switch (type) {
      case 'http://':
        return 'http://'
      default:
        return 'https://'
    }
  }

  init()
  {
    mobileFontSize('.button-style', 20, this.form, null, this.keditor, 2)

    //Espaçamentos
    componentSpacing('.button-style', this.form, null, this.keditor, 2)

    //Text
    this.#elements.inputText.data.on('keyup', (event) => {
      const buttonRef = this.getRef(this.#buttonRef)
      const btnTitle = buttonRef.find('.btn-title .text')

      btnTitle.text(event.target.value)
    })

    //Cor dos textos
    colorPicker.init(
      this.form,
      (color) => {
        const buttonRef = this.getRef(this.#buttonRef)

        buttonRef.css(`color`, `${color}`)
      },
      '#input-text-color'
    )

    //SubText
    this.#elements.inputSubText.data.on('keyup', (event) => {
      const buttonRef = this.getRef(this.#buttonRef)
      const btnSubTitle = buttonRef.find('.btn-subtitle')

      const action = event.target.value === '' ? 'addClass' : 'removeClass'
      btnSubTitle[action]('d-none')
      btnSubTitle.html(event.target.value)
    })

    //Link do Botão
    this.#elements.inputBtnTarget.data.on('click', (event) => {
      const btnTargetLabel = this.#elements.btnTargetLabel.data
      btnTargetLabel.html($(event.target).html())
      btnTargetLabel.attr('data-value', $(event.target).attr('data-value'))

      const buttonRef = this.getRef(this.#buttonRef)
      const value = buttonRef.attr('href')

      const valueValidation = this.validationHref(value)

      let newHref = valueValidation
        ? value.replace(valueValidation, $(event.target).attr('data-value'))
        : $(event.target).attr('data-value') + value

      this.#elements.inputBtnLink.data.val(newHref)

      buttonRef.attr({
        href: newHref,
      })
    })

    // Elemento para qual sera redirecionado
    this.#elements.rollToElement.data.on('keyup', (event) => {
      const {
        target: { value },
      } = event
      const newValue = '#' + value.replace('#', '')
      const buttonRef = this.getRef(this.#buttonRef)
      buttonRef.removeAttr('target')
      buttonRef.attr({
        [this._setDataAtribute('roll-to-element')]: newValue,
        href: newValue,
      })
    })

    this.#elements.inputBtnLink.data.on('keyup', (event) => {
      const btnTargetLabel = this.#elements.btnTargetLabel.data
      const buttonRef = this.getRef(this.#buttonRef)

      let value = event.target.value

      const valueValidation = this.validationHref(value)

      if (valueValidation == '#' || !valueValidation) buttonRef.removeAttr('target')
      else if (this.form.find('#switch-open-new-page').is(':checked')) {
        buttonRef.attr('target', '_blank')
      }

      if (valueValidation) {
        btnTargetLabel.html(valueValidation != '#' ? valueValidation : 'https://')
        btnTargetLabel.attr('data-value', valueValidation)
        const vew = value.replace(valueValidation, '')
        // $(this).val(vew);
        value = valueValidation + vew
      } else {
        btnTargetLabel.html('https://')
        btnTargetLabel.attr('data-value', 'https://')
        if (value.length) value = 'https://' + value
      }

      buttonRef.attr({
        [this._setDataAtribute('default-config')]: value,
        href: value,
      })
    })

    //Align
    this.#elements.inputAlign.data.on('change', (event) => {
      const buttonWrapper = this.getRef(this.#buttonWrapperRef)

      buttonWrapper.removeClass('text-center text-left text-right')
      buttonWrapper.addClass(event.target.value)
    })

    //Width
    this.#elements.inputWidth.data.on('change', (event) => {
      const buttonRef = this.getRef(this.#buttonRef)
      let width = event.target.value

      buttonRef.removeClass('w-25 w-50 w-75 w-100')
      if (width) {
        buttonRef.addClass(width)
      }
    })

    //Cor do botão
    // colorPicker.init(
    //   this.form,
    //   (color) => {
    //     const buttonRef = this.getRef(this.#buttonRef)
    //     buttonRef.css('background-color', color)

        // setTimeout(
        //   () =>
        //     //Box shadow
        //     this.boxShadow.setBoxShadow({
        //       target: {
        //         value: buttonRef.attr('data-box-shadow-type'),
        //       },
        //     }),
        //   200
        // )
    //   },
    //   '#input-background'
    // )

    //background
    this.background.init()

    // Estilo do Botão
    this.#elements.btnStyle.data.on('click', (event) => {
      const buttonRef = this.getRef(this.#buttonRef)
      const btnTitle = buttonRef.find('.btn-title')
      const btnSubTitle = buttonRef.find('.btn-subtitle')
      const element = event.target
      const btnStyle = $(element).attr('data-btn-style')
      const btnConfig = { ...$(element).data('btn-config') }

      const btnTextColor = parseInt(btnStyle) === 2 ? BLUE_COLOR : '#FFF'
      buttonRef.css('color', btnTextColor)

      Object.keys(btnConfig.style).map((key) => {
        buttonRef.css(key, btnConfig.style[key])
      })
      setTimeout(
        () =>
          this.boxShadow.setBoxShadow({
            target: {
              value: btnConfig.advance['box-shadow-ref'],
            },
          }),
        200
      )
      setTimeout(() => this.show.bind(this)(), 300)
    })

    // Enviar parametros
    this.#elements.sendPageParams.data.on('click', (event) => {
      const element = this.getRef(this.#buttonRef)

      if ($(event.target).is(':checked')) {
        element.attr({
          'data-send-params': $(event.target).is(':checked'),
          onClick: `submitAction(event)`,
        })
        element.removeAttr('href')
      } else {
        element.attr({
          'data-send-params': $(event.target).is(':checked'),
          href: element.attr('data-default-href'),
        })
        element.removeAttr('onClick')
      }
    })

    // Abrir em outra página
    // this.#elements.openNewTab.data.on('click', (event) => {
    //   const element = this.getRef(this.#buttonRef)

    //   if ($(event.target).is(':checked')) {
    //     element.attr('target', '_blank')
    //   } else {
    //     element.removeAttr('target')
    //   }
    // })

    //Seleção da função do botão
    this.dropdown.init(
      this.setFunctionalityValue({
        form: this.form,
        options: this.dropdown.options,
      })
    )
    //Font Selector
    this.fontSelector.init()
    //Open new tab
    this.toggleButton.init()
    //Border Radius
    this.borderRadius.init()
    //Border Width
    this.borderWidth.init()
    //Box shadow
    this.boxShadow.init()
    //Font Weight
    this.fontStyle.init()
    //Border Color
    this.borderColor.init((color) =>
    {
      const isTransparent = color === 'transparent' || color === 'rgba(0, 0, 0, 0)'
      this.getRef(this.#buttonRef).css('border-style', isTransparent ? 'hidden' : 'solid')
    })

    //Icon select
    this.iconSelect.init()

    this.buttonAlignment.init()

    //Responsive font
    this.fontSizeResponsiveTitle.init(() => this.getRef(this.#buttonRef).find('.btn-title'))
    this.fontSizeResponsiveSubtitle.init(() => this.getRef(this.#buttonRef).find('.btn-subtitle'))

    // const btnSubTitle = buttonRef.find('.btn-subtitle')
  }

  show()
  {
    if (!this._component || !this.getRef(this.#buttonWrapperRef)) return

    const buttonRef = this._component.find('.button-style')
    const btnTitle = buttonRef.find('.btn-title')
    const btnSubTitle = buttonRef.find('.btn-subtitle')
    const buttonWrapper = this.getRef(this.#buttonWrapperRef)
    mobileFontSize(buttonRef, 20, this.form, this._component, this.keditor, 1)

    if (buttonRef.length == 0) return

    if (!buttonRef.attr('data-font')) buttonRef.attr('data-font', buttonRef.css('font-size').split('px')[0])

    this.#elements.inputText.data.val(btnTitle.text().trim())
    this.#elements.inputSubText.data.val(btnSubTitle.text().trim())

    const href = buttonRef.attr('href')
    const validationHref = this.validationHref(href)
    const target = !validationHref ? '' : validationHref

    if (!buttonRef.attr('data-config-type')) {
      const config = !target || target == '#' ? 'roll-to-element' : 'default-config'
      buttonRef.attr('data-config-type', config)
    }
    if (!buttonRef.attr('data-element-target') && (!target || target == '#'))
      buttonRef.attr('data-element-target', href)
    if (!buttonRef.attr('data-default-href') && target && target != '#') buttonRef.attr('data-default-href', href)

    const elementTarget = buttonRef.attr('data-element-target') || '#'
    const defaultHref = buttonRef.attr('data-default-href') || 'https://'

    const value = !buttonRef.attr('data-config-type') ? 'default-config' : buttonRef.attr('data-config-type')

    if (value != 'submit-form') buttonRef.removeAttr('onClick')

    this.#elements.rollToElement.data.val(elementTarget)
    this.#elements.inputBtnLink.data.val(defaultHref)

    this.#elements.btnTargetLabel.data.html(this.buttonTarget(target))
    this.#elements.btnTargetLabel.data.attr('data-value', target)

    this.#elements.inputAlign.data.val(verifyHasClass(buttonWrapper, ['text-center', 'text-left', 'text-right']))
    this.#elements.inputWidth.data.val(verifyHasClass(buttonRef, ['w-25', 'w-50', 'w-75', 'w-100']))

    //colorPicker.show(this.form, buttonRef.css('background-color'), '#input-background')
    this.background.show()

    colorPicker.show(this.form, buttonRef.css('color'), '#input-text-color')

    const sendParams = buttonRef.attr('data-send-params') && JSON.parse(buttonRef.attr('data-send-params'))

    this.dropdown.show(
      this.getFunctionalityValue({ element: buttonRef, form: this.form, options: this.dropdown.options })
    )

    if (sendParams) {
      buttonRef.removeAttr('href')
      buttonRef.attr('onClick', `submitAction(event)`)
    }

    this.#elements.sendPageParams.data.prop('checked', !!sendParams)
    //Font Selector
    this.fontSelector.show()
    //Open new tab
    this.toggleButton.show()
    //Border Radius
    this.borderRadius.show()
    //Border Width
    this.borderWidth.show()
    //Box shadow
    this.boxShadow.show()
    //Font Weight
    this.fontStyle.show()
    //Border Color
    this.borderColor.show()
    //Icon select
    this.iconSelect.show()

    this.buttonAlignment.show()

    //Responsive font
    this.fontSizeResponsiveTitle.show(() => this.getRef(this.#buttonRef).find('.btn-title'))
    this.fontSizeResponsiveSubtitle.show(() => this.getRef(this.#buttonRef).find('.btn-subtitle'))
    // Espaçamentos
    componentSpacing(buttonRef, this.form, this._component, this.keditor, 1)
  }

  // Helpers
  getFunctionalityValue(e) {
    if (!e) return

    const { form, element, options } = e
    const value = !element.attr('data-config-type') ? 'default-config' : element.attr('data-config-type')

    const findOption = options.find((el) => el.value == value)
    form.find('#dropdown-set-functionality').html(findOption ? findOption.text : DEFAULT_ACTION_SELECT)

    if (value != 'submit-form') element.removeAttr('onClick')

    this._switchVisibility(value)
  }

  setFunctionalityValue({ form, options }) {
    const self = this
    form
      .find('#set-functionality')
      .find('.dropdown-item')
      .on('click', function (event) {
        $('.unsaved').show()
        const element = self.getRef(self.#buttonRef)

        const { target } = event
        const value = $(target).attr('value')
        const currentInputValue = self._switchVisibility(value)
        //Muda o texto do botão (dropdown)
        const findOption = options.find((el) => el.value == value)
        form.find(`#dropdown-set-functionality`).html(findOption ? findOption.text : DEFAULT_ACTION_SELECT)

        // verifica o tipo do valor no href
        const valueValidation = value !== 'submit-form' && self.validationHref(currentInputValue)

        if (valueValidation == '#' || !valueValidation) element.removeAttr('target')

        if (form.find('#switch-open-new-page').is(':checked')) {
          element.attr('target', '_blank')
        }

        if (element.attr('data-send-params') && JSON.parse(element.attr('data-send-params'))) {
          element.removeAttr('href')
          element.attr('onClick', `submitAction(event)`)
        } else {
          element.removeAttr('onClick')
        }

        //armazena o valor do tipo de configuração (o que aparece no select)
        const href = (!valueValidation ? '#' : '') + currentInputValue
        element.removeAttr('href')
        element.removeAttr('onClick')
        element.removeAttr('data-toggle')
        element.removeAttr('data-target')

        switch (value) {
          case 'submit-form':
            element.attr({
              'data-config-type': value,
              onClick: 'submitForm()'
            })    
            break;
          case 'open-popup':
            element.attr({
              'data-config-type': value,
              'data-toggle': "modal",
              'data-target': `#${MODAL_ID}`
            })
            break;        
          default:
            
            element.attr({
              'data-config-type': value,
              [self._setDataAtribute(value)]: href,
              href: href,
            })
            break;
        }
      })
  }

  // Retorna o atributo que será setado
  _setDataAtribute(value) {
    const config = {
      'default-config': 'data-default-href',
      'roll-to-element': 'data-element-target',
      'submit-form': 'onClick',
    }
    return config[value]
  }

  // Método interno somente para mudar a visibilidade das configurações
  _switchVisibility(value) {
    switch (value) {
      case 'default-config':
        this.form.find('#roll-to-element').hide()
        this.form.find('#default-config').show()
        return this.form.find('#button-link').val()
      case 'submit-form':
        this.form.find('#roll-to-element').hide()
        this.form.find('#default-config').hide()
        break
      case 'roll-to-element':
        this.form.find('#roll-to-element').show()
        this.form.find('#default-config').hide()
        return this.form.find('#roll-to-element-config').val()
      default:
        this.form.find('#roll-to-element').hide()
        this.form.find('#default-config').hide()
        break
    }
  }
}
