import QuizModel from '../quizModel'
import changeView from './changeView'
import save from './save'
import { DEFAULT_ACTION_SELECT } from '../../../constants'

export function handleInputChange(event) {
    const { target } = event
    let { value } = target
    const element = $(target)
    const elId = element.data('question-id')
    const id = parseInt(elId.replace('config-', ''))

    value = parseInt(value)
    const component = QuizModel.getQuestion(id)

    component['correct'] = value

    //====================== VIEW
    if (parseInt(QuizModel.elements.selectView.data.val()) === id) changeView(id)

    save()
}

export function handleChangeQuestion(event) {
    const { target } = event
    let { value } = target
    const element = $(target)
    const elId = element.data('question-id')
    const id = parseInt(elId.replace('config-', ''))

    const config = QuizModel.form.find(`#${elId}`)
    const component = QuizModel.getQuestion(id)
    //====================== CONFIG

    config.find('.question').removeClass('is-invalid')
    if (!value.length) config.find('.question').addClass('is-invalid')

    const title = QuizModel.form.find(`[data-config="${element.data('question-id')}"]`).find('.collapse-title')
    title[0].innerHTML = value || 'Pergunta ' + (id + 1)

    component['question'] = value || 'Pergunta ' + (id + 1)
    //====================== VIEW

    if (parseInt(QuizModel.elements.selectView.data.val()) === id) {
        changeView(id)
    }

    save()
}

export function handleChangeAnswer(event) {
    const { target } = event
    let { value } = target
    const element = $(target)
    const answerIndex = parseInt(element.data('answer-index'))
    const elId = element.data('question-id')
    const id = parseInt(elId.replace('config-', ''))

    const config = QuizModel.form.find(`#${elId}`)
    const component = QuizModel.getQuestion(id)
    const answersLength = component['answers'].filter((el) => el.length).length

    const selectView = QuizModel.form.find(QuizModel.viewQuestionRef)
    //====================== CONFIG

    QuizModel.form.find(`#erro-${elId}`).hide()
    config.find('.answer-input').removeClass('is-invalid')
    if (answersLength == 1) {
        if (!value.length) {
            QuizModel.form.find(`#erro-${elId}`).show()
            config.find('.answer-input').toggleClass('is-invalid')
        }
    }

    component['answers'][answerIndex] = value

    //====================== VIEW
    if (parseInt(QuizModel.elements.selectView.data.val()) === id) {
        changeView(id)
    }

    save()
}

export function handleAction({ form, options }) {
    form.find(QuizModel.elements.quizAction.selector).on('click', '.dropdown-item', function (event) {
        const { target } = event
        const value = $(target).attr('value')

        const findOption = options.find((el) => el.value == value)

        form.find('#dropdown-set-functionality')[0].innerHTML = findOption ? findOption.text : DEFAULT_ACTION_SELECT

        QuizModel.showMessage = value == 'message'

        if (value == 'message') {
            form.find(QuizModel.redirectConfigRef).hide()
            form.find(QuizModel.messageConfigRef).show()
        }else{
            form.find(QuizModel.redirectConfigRef).show()
            form.find(QuizModel.messageConfigRef).hide()
        }
        save()
    })
}