
function validationHref(value) {
    if (!value) return null
    const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)/
    let valueMatch = value.match(regex)

    if (valueMatch && valueMatch[0]) {
      const valTarget = valueMatch[0].indexOf('www.') != -1 ? valueMatch[0].split('www.')[0] : valueMatch[0]

      let label = '#'
      if (valTarget != '#' && valTarget.length) label = valTarget
      else if (!valTarget.length) label = 'https://'

      return label
    }
    return null
  }

export default validationHref