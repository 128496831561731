const renderView = ({ el }) => {
    let answers = ''

    el.answers.map((answer, index) => {
      if (answer.length)
        answers += /*html*/ `<li style="background-color: ${el.colors.tertiary};color:${el.colors.primary};" class="answer quiz-primary-color quiz-tertiary-color-bg ${
          el.correct === index ? 'correct' : ''
        }" id="answer-${index}">${answer}</li>`
    })
    return /*html*/ `  
      <div class="progress quiz-primary-color-bg" style="background-color: ${el.colors.primary}; display: ${el.showProgress ? 'flex' : 'none'}">
        <div class="progress-bar quiz-secondary-color-bg" style="background-color: ${el.colors.secondary}; width: 10%;" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      
      <div class="quiz-img d-flex justify-content-center photo-panel ${el.imgsrc ? 'mt-4' : ''}">
        <img class="img-product" src=" ${el.imgsrc}" alt="" />
      </div>

      <div
        class="quiz-title quiz-primary-color mt-3"
        id="quiz-title"
        data-font_size_default="${el.default.question}"
        data-font_size_mobile="${el.mobile.question}"
        data-lock="${el.lock}"
        style="font-size: ${el.default.question}px; font-weight: bold;color:${el.colors.primary};"
      >
        ${el.question || 'Pergunta'}
      </div>

      <ul
        class="answers"
        id="answers"
        data-font_size_default="${el.default.answer}"
        data-font_size_mobile="${el.mobile.answer}"
        data-lock="${el.lock}"
        style="font-weight: bold; font-size: ${el.default.answer}px"
      > 
      ${answers}
      </ul>
    `
  }

export default renderView