import {
  useAlignmentBlock,
  useColor,
  useFontSizeResponsive,
  useInputNumber,
  useShowElement,
  useDropdown,
  useText,
  useInputURL,
  useSelect,
  useInputDateTime
} from '../../../microComponents'
import BaseComponent from '../../baseComponent'
import { timeTransform } from '../utils'

const useComponents = [
  { component: useFontSizeResponsive, name: 'font-size-number', customComponentName: 'fontSizeNumber' },
  { component: useFontSizeResponsive, name: 'font-size-label', customComponentName: 'fontSizeLabel' },
  { component: useColor, name: 'color-number', customComponentName: 'colorNumber' },
  { component: useColor, name: 'color-label', customComponentName: 'colorLabel' },
  { component: useAlignmentBlock },
]
export default class CountdownComponent extends BaseComponent {
  #countdownContainer = '.countdown-container'
  #counterTime = '.counter-time'
  #counterLabel = '.counter-label'
  #showDays = '.show-days'
  #days = '.days'
  #hours = '.hours'
  #minutes = '.minutes'
  #seconds = '.seconds'
  #message = '.final-text'

  cType = 'time'
  finalDateTime = ''
  showMessage = true
  redirectTo = ''

  constructor() {
    super()
  }

  set form(newForm) {
    this._form = newForm

    useComponents.map((el) => {
      const aux = el.component({
        customFormRef: el.name,
        customComponentName: el.customComponentName,
        form: this._form,
        keditor: this._keditor,
        elementRef: () => this.getRef(this.#countdownContainer),
      })
      this[aux.componentName] = aux
    })

    this.colorNumber = useColor({
      customFormRef: 'color-number',
      form: this._form,
      keditor: this._keditor,
      elementRef: () => this.getRef(this.#counterTime),
    })
    this.colorLabel = useColor({
      customFormRef: 'color-label',
      form: this._form,
      keditor: this._keditor,
      elementRef: () => this.getRef(this.#counterLabel),
    })

    this.typeSelector = useDropdown({
      form: this._form,
      keditor: this._keditor,
      customOptions: [
        {
          value: 'time',
          text: 'Tempo fixo',
          icon: 'fa fa-clock-o',
        },
        {
          value: 'date',
          text: 'Data',
          icon: 'fa fa-calendar',
        },
      ],
      customDropdownRef: 'countdownd-type-dropdown',
      customFormRef: 'countdown-counting-type',
    })

    this.actionSelector = useDropdown({
      form: this._form,
      keditor: this._keditor,
      customOptions: [
        {
          value: 'redirect',
          text: 'Ir para URL',
          icon: 'fa fa-globe',
        },
        {
          value: 'message',
          text: 'Exibir Mensagem',
          icon: 'fa fa-envelope',
        },
      ],
      customDropdownRef: 'countdownd-action-dropdown',
      customFormRef: 'countdown-action',
    })

    //Texto ao zerar
    this.messageValue = useText({
      form: this._form,
      keditor: this._keditor,
      customFormRef: 'final-text',
      elementRef: () => this.getRef(this.#message)
    })

    //URL para redirecionar ao zerar
    this.redirectToValue = useInputURL({
      form: this._form,
      keditor: this._keditor,
      customFormRef: 'countdown-link',
      elementRef: () => this.getRef(this.#countdownContainer)
    })

    //Posição dos Títulos
    this.titlePos = useSelect({
      form: this._form,
      customOptions: [
        {
          value: 'top',
          label: 'Em cima'
        },
        {
          value: 'bottom',
          label: 'Em baixo'
        },
      ],
      customFormRef: 'title-pos',
      elementRef: () => this.getRef(this.#countdownContainer)
    })

    //FinalDate
    this.finalDateValue = useInputDateTime({
      form: this._form,
      customFormRef: 'countdown-date',
      type: 'date',
      elementRef: () => this.getRef(this.#countdownContainer)
    })

    //FinalTime
    this.finalTimeValue = useInputDateTime({
      form: this._form,
      customFormRef: 'countdown-time',
      type: 'time',
      elementRef: () => this.getRef(this.#countdownContainer)
    })

    // MOSTRAR DIAS
    this.showDays = useShowElement({
      customFormRef: 'exibe-dias',
      form: this._form,
      keditor: this._keditor,
      elementRef: () => this.getRef(this.#showDays),
    })

    // TEMPORIZADOR
    this.daysValue = useInputNumber({
      customFormRef: 'time-days',
      form: this._form,
      keditor: this._keditor,
      elementRef: () => this.getRef(this.#days),
    })
    this.hoursValue = useInputNumber({
      customFormRef: 'time-hours',
      form: this._form,
      keditor: this._keditor,
      elementRef: () => this.getRef(this.#hours),
    })
    this.minutesValue = useInputNumber({
      customFormRef: 'time-minutes',
      form: this._form,
      keditor: this._keditor,
      elementRef: () => this.getRef(this.#minutes),
    })
    this.secondsValue = useInputNumber({
      customFormRef: 'time-seconds',
      form: this._form,
      keditor: this._keditor,
      elementRef: () => this.getRef(this.#seconds),
    })
  }

  init() {
    if (!this._form || !this._keditor) return

    this.alignmentSelect.init()

    this.fontSizeNumber.init(() => this.getRef(this.#counterTime))
    this.fontSizeLabel.init(() => this.getRef(this.#counterLabel))

    this.colorNumber.init()
    this.colorLabel.init()

    this.typeSelector.init(this.#handleType({ form: this._form, options: this.typeSelector.options }))
    this.actionSelector.init(this.#handleAction({ form: this._form, options: this.actionSelector.options }))

    this.showDays.init((isChecked) => this.#handleDays(isChecked))

    this.daysValue.init()

    this.hoursValue.init((e) =>
      this.#handleTimer({
        formTarget: e,
        timeBase: 24,
        fieldsToChange: ['hoursValue', 'daysValue'],
      })
    )

    this.minutesValue.init((e) =>
      this.#handleTimer({
        formTarget: e,
        timeBase: 60,
        fieldsToChange: ['minutesValue', 'hoursValue'],
        maxvalue: 1439
      })
    )

    this.secondsValue.init((e) =>
      this.#handleTimer({
        formTarget: e,
        timeBase: 60,
        fieldsToChange: ['secondsValue', 'minutesValue'],
        maxvalue: 3599
      })
    )
    
    //Texto quando zerar
    this.messageValue.init()

    //Url para redirecionar ao zerar
    this.redirectToValue.init({
      callback: (val, elref) => {
        this.redirectTo = val
        this.#saveData()
      }
    })

    //Posição dos Títulos
    this.titlePos.init({
      callback: (val, elref) => {
        if (val ==  "top") {
          elref().find(".text-top").show()
          elref().find(".text-bottom").hide()
        } else {
          elref().find(".text-bottom").show()
          elref().find(".text-top").hide()
        }
      }
    })

    this.finalDateValue.init({
      callback: (value, elRef) => {
        //if(value.match(/^[0-9]{4}-[0-1][0-9]-[0-9]{2}$/)){
          const curTime = this.finalDateTime.split(' ')[1]
          this.finalDateTime = value + ' ' + curTime
          this.#saveData()
        //}
      } 
    })

    this.finalTimeValue.init({
      callback: (value, elRef) => {
        //if(value.match(/^[0-9]{2}:[0-9]{2}$/)){
          const curDate = this.finalDateTime.split(' ')[0]
          this.finalDateTime = curDate + ' ' + value
          this.#saveData()
        //}
      } 
    })
  }

  show() {
    this.alignmentSelect.show()
    this.fontSizeNumber.show(() => this.getRef(this.#counterTime))
    this.fontSizeLabel.show(() => this.getRef(this.#counterLabel))

    this.colorNumber.show()
    this.colorLabel.show()

    this.showDays.show((isChecked) => this._form.find('#time-days').attr('disabled', !isChecked))

    this.daysValue.show()
    this.hoursValue.show()
    this.minutesValue.show()
    this.secondsValue.show()
    
    this.messageValue.show()

    const dataCountdown = JSON.parse(this.getRef(this.#countdownContainer).attr('data-countdown') || '{}')
    this.redirectTo = dataCountdown.redirectTo || ''
    this.showMessage = dataCountdown.showMessage || true
    this.finalDateTime = dataCountdown.finalDateTime || ''
    this.cType = dataCountdown.type || 'time'

    this.redirectToValue.show({
      customValue: this.redirectTo || 'https://'
    })

    this.finalDateValue.show({
      customValue: this.finalDateTime.split(' ')[0].match(/^[0-9]{4}-[0-1][0-9]-[0-9]{2}$/) ? this.finalDateTime.split(' ')[0] : null
    })

    this.finalTimeValue.show({
      customValue: this.finalDateTime.split(' ')[1]?.match(/^[0-9]{2}:[0-9]{2}$/) ? this.finalDateTime.split(' ')[1] : null
    })

    this.titlePos.show({
      callback : function (elref) {
        return elref().find(".text-top").is(":visible") ? "top" : "bottom"
      }
    })

    this.typeSelector.show(this.#showType({ form: this._form, options: this.typeSelector.options, type: this.cType }))   
    this.actionSelector.show(this.#showAction({ form: this._form, options: this.actionSelector.options, showMessage: this.showMessage }))   
  }


  #handleTimer({ formTarget, timeBase, fieldsToChange, maxvalue = null }) {

    const isDaysVisible = this.getRef(this.#days).is(':visible')

    //só se faz uso do timeTransform se não for o último field.
    if (!isDaysVisible && timeBase == 24) {
      return formTarget.value.length == 1 ? "0" + formTarget.value : formTarget.value;
    }

    //Evita ser necessário uso de recursividade para alterar campos dois ou mais níveis acima.
    if (maxvalue && maxvalue < formTarget.value) {
      formTarget.value = maxvalue
    }
    
    const [timeUpperScale, timeCurrentScale] = timeTransform(formTarget.value, timeBase)

    if (parseInt(timeUpperScale) > 0) {
      this[fieldsToChange[0]].show(timeCurrentScale)
      this[fieldsToChange[1]].show(timeUpperScale)

      this[fieldsToChange[1]].elementRef().html(timeUpperScale)
    }

    return timeCurrentScale;
  }

  #handleDays(isChecked) {
    const hoursCurValue = this.getRef(this.#hours).html()

    if (isChecked) {
      const [days, hours] = timeTransform(hoursCurValue, 24)

      this.getRef(this.#days).html(days)
      this.daysValue.show(days)
      this.getRef(this.#hours).html(hours)
      this.hoursValue.show(hours)
      this._form.find('#time-days').attr('disabled', false)
    } else {
      const daysCurValue = this.getRef(this.#days).html()
      const totalHours = parseInt(hoursCurValue) +  parseInt(daysCurValue) * 24

      let strTotalHours = totalHours.toString()
      if(strTotalHours.length == 1){
        strTotalHours =  "0" + strTotalHours;
      }

      this.getRef(this.#days).html('0')
      this.daysValue.show('0')
      this.getRef(this.#hours).html(strTotalHours)
      this.hoursValue.show(strTotalHours)
      this._form.find('#time-days').attr('disabled', true)
    }
  }

  #handleType({form, options}) {
    let self = this
    form.find('#countdown-counting-type').on('click','.dropdown-item', function (event) {
      const {target} = event
      const value = $(target).attr('value')

      const findOption = options.find((el) => el.value == value)

      if (findOption){
        form.find('#countdownd-type-dropdown')[0].innerHTML = findOption.text 
      }

      const isTimeType = value == 'time'
      
      form.find('#date-config')[isTimeType ? 'hide' : 'show']()
      form.find('#time-config')[isTimeType ? 'show' : 'hide']()

      self.cType = value
      self.#saveData()
    })
  }

  #showType({form, options, type}) {
    
    const findOption = options.find((el) => el.value == type)

    if (findOption){
      form.find('#countdownd-type-dropdown')[0].innerHTML = findOption.text 
    }

    const isTimeType = type == 'time'
    
    form.find('#date-config')[isTimeType ? 'hide' : 'show']()
    form.find('#time-config')[isTimeType ? 'show' : 'hide']()
  }

  #handleAction({form, options}) {
    let self = this
    form.find('#countdown-action').on('click','.dropdown-item', function (event) {
      const {target} = event
      const value = $(target).attr('value')

      const findOption = options.find((el) => el.value == value)

      if (findOption){
        form.find('#countdownd-action-dropdown')[0].innerHTML = findOption.text 
      }

      const showMessage = value == 'message'
      
      form.find('#redirect-config')[showMessage ? 'hide' : 'show']()
      form.find('#message-config')[showMessage ? 'show' : 'hide']()

      self.showMessage = showMessage
      self.#saveData()
    })
  }

  #showAction({form, options, showMessage}){
    const value = showMessage ? 'message' : 'redirect'
    const findOption = options.find((el) => el.value == value)

    if (findOption){
      form.find('#countdownd-action-dropdown')[0].innerHTML = findOption.text 
    }
    
    form.find('#redirect-config')[showMessage ? 'hide' : 'show']()
    form.find('#message-config')[showMessage ? 'show' : 'hide']()
  }

  #saveData(){
    $('.unsaved').show()
    const container = this.getRef(this.#countdownContainer)

    const data = JSON.stringify({
        type: this.cType,
        finalDateTime: this.finalDateTime,
        showMessage: this.showMessage,
        redirectTo: this.redirectTo,
    })
    container.attr('data-countdown', data)
  }

}
