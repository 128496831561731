import { ModalBase } from './template/modalBase'

export default function (refId, title, onInsertCode = null) {
  let self = this

  let modalFooter = /*html*/ `
            <button type="button" class="btn cancel" data-dismiss="modal">
                ${this.options.locale.close}
            </button>
            <button type="button" class="btn save">
                ${this.options.locale.insertCode}
            </button>`

  let modal = $(
    ModalBase({
      id: 'htmlmodal-'+refId,
      title: this.options.locale.modalHtmlComponent.title,
      body: /*html*/ `<div class="col-sm-12 editor" id="editor-custom-html-${refId}"></div><div class="mt-3"><span id="htmlErrorSave" style="font-size:14px;color:#ff2e2e;"></span></div>`,
      footer: modalFooter,
    })
  )

  modal.find('.save').on('click', function () {
    let error = -1;
    if (onInsertCode && onInsertCode instanceof Function) {
      error = onInsertCode.call(self, refId)
    }
    if (error > -1) {
      modal.find('#htmlErrorSave').text(self.options.locale.modalHtmlComponent.errors[error])
    }else{
      modal.find('#htmlErrorSave').text('')
      modal.modal('hide')
    }
  })

  return modal.appendTo(self.wrapper)
}
