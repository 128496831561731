import * as templates from './formLayouts'
import ModalEditor from './components/ModalEditor'
import { templateForm } from '../helpers/form.settings'
import updateView from './views/updateView'
import { useFontSelector, useFontSizeResponsive, useColor, useShowElement, useHeight, useImageFit, useToggleGeneric } from '../microComponents'

import CarouselModel from './carouselModel'

/**
 * Carousel Controller
 * Here you will find Setters and Getters plus Create, Init and Show Methods
 */
export default class CarouselControl {
    // SETTERS
    set component(newComponent) {
        CarouselModel.component = newComponent
    }

    set keditor(keditor) {
        CarouselModel.keditor = keditor
    }

    //Called before init() to instantiate micro-components
    set form(form) {
        CarouselModel.form = form
    
        Object.keys(CarouselModel.elements).map((key) => {
          CarouselModel.elements[key].data = CarouselModel.form.find(CarouselModel.elements[key].selector)
        })

        const defaultValues = {
          form: CarouselModel.form,
          keditor: CarouselModel.keditor,
          elementRef: () => CarouselModel.getRef(CarouselModel.config.uid),
        }

        this.fontTitleSelector = useFontSelector({
            form: CarouselModel.form,
            keditor: CarouselModel.keditor,
            elementRef: () => CarouselModel.getRef(CarouselModel.refs.titles),
            customFormRef: 'font-family-title',
            customComponentName: 'fontTitleSelector'
        })

        this.fontSubtitleSelector = useFontSelector({
            form: CarouselModel.form,
            keditor: CarouselModel.keditor,
            elementRef: () => CarouselModel.getRef(CarouselModel.refs.subtitles),
            customFormRef: 'font-family-subtitle',
            customComponentName: 'fontSubtitleSelector'
        })

        this.fontSizeTitle = useFontSizeResponsive({
            ...defaultValues,
            customFormRef: 'font-size-title',
            customComponentName: 'fontSizeTitle',
        })

        this.fontSizeSubtitle = useFontSizeResponsive({
            ...defaultValues,
            customFormRef: 'font-size-subtitle',
            customComponentName: 'fontSizeSubtitle',
        })

        this.titleColor = useColor({
            form: CarouselModel.form,
            keditor: CarouselModel.keditor,
            elementRef: () => CarouselModel.getRef(CarouselModel.refs.titles),
            customFormRef: 'title-color',
        })

        this.subtitleColor = useColor({
            form: CarouselModel.form,
            keditor: CarouselModel.keditor,
            elementRef: () => CarouselModel.getRef(CarouselModel.refs.subtitles),
            customFormRef: 'subtitle-color',
        })

        this.height = useHeight({
            form: CarouselModel.form,
            keditor: CarouselModel.keditor,
            elementRef: () => CarouselModel.getRef(CarouselModel.refs.itemsWrapper),
        })

        this.imgFit = useImageFit({
            form: CarouselModel.form,
            keditor: CarouselModel.keditor,
            elementRef: () => CarouselModel.getRef(CarouselModel.refs.itemsImgs),
            //isBgImg: false
        })

        /**
         * Foi utilizado showElement para evitar re-renderização da view inteira
         */
        this.showArrows = useShowElement({
            form: CarouselModel.form,
            keditor: CarouselModel.keditor,
            elementRef: () => CarouselModel.getRef(CarouselModel.refs.controls),
            customFormRef: 'showArrows',
            customShowPropValue: 'flex'
        })
        /**
         * Foi utilizado showElement para evitar re-renderização da view inteira
         */
        this.showDots = useShowElement({
            form: CarouselModel.form,
            keditor: CarouselModel.keditor,
            elementRef: () => CarouselModel.getRef(CarouselModel.refs.indicators),
            customFormRef: 'showDots',
            customShowPropValue: 'flex'
        })

        this.loop = useToggleGeneric({
            form: CarouselModel.form,
            keditor: CarouselModel.keditor,
            elementRef: () => CarouselModel.getRef(CarouselModel.refs.wrapper),
            customFormRef: 'loop',
            defaultChecked: true
        })

        this.autoplay = useToggleGeneric({
            form: CarouselModel.form,
            keditor: CarouselModel.keditor,
            elementRef: () => CarouselModel.getRef(CarouselModel.refs.wrapper),
            customFormRef: 'autoplay',
        })

    }

    //GETTERS
    get template() {
      return $(templateForm({ children: templates.FORM_LAYOUT }))
    }

    create(action = '') {
        if (!CarouselModel.component && !CarouselModel.keditor) return

        CarouselModel.loadConfig()

        if(!CarouselModel.config.uid || action == 'paste'){
            CarouselModel.config.uid = CarouselModel.keditor.generateId()
        }
        
        CarouselModel.loadItems()

        ModalEditor.initModal(updateView)
        
        updateView(true)
    }

    init() {
        if (!CarouselModel.form) return

        this.fontSizeTitle.init(() => CarouselModel.getRef(CarouselModel.refs.titles))
        this.fontSizeSubtitle.init(() => CarouselModel.getRef(CarouselModel.refs.subtitles))
        this.fontTitleSelector.init()
        this.fontSubtitleSelector.init()
        this.titleColor.init()
        this.subtitleColor.init()

        this.height.init((opt) =>{
            CarouselModel.config.height = opt
            CarouselModel.saveConfig()
        })
        this.imgFit.init((opt) =>{
            CarouselModel.config.imgFit = opt
            CarouselModel.saveConfig()
        })

        this.showArrows.init((opt) =>{
            CarouselModel.config.showArrows = opt
            CarouselModel.saveConfig()
        })
        this.showDots.init((opt) =>{
            CarouselModel.config.showDots = opt
            CarouselModel.saveConfig()
        })

        this.loop.init({
            callback: (isChecked, elementRef) => {
                CarouselModel.config.loop = isChecked
                elementRef().attr("data-wrap", isChecked)
                CarouselModel.saveConfig()
            }
        })
        this.autoplay.init({
            callback: (isChecked, elementRef) => {
                CarouselModel.config.autoplay = isChecked
                elementRef().attr("data-ride", isChecked ? "carousel" : "false")
                CarouselModel.saveConfig()
            }
        })
    }

    show() {
        if (!CarouselModel.component || !CarouselModel.form) return

        CarouselModel.loadItems()
        CarouselModel.loadConfig()

        this.fontSizeTitle.show(() => CarouselModel.getRef(CarouselModel.refs.titles))
        this.fontSizeSubtitle.show(() => CarouselModel.getRef(CarouselModel.refs.subtitles))
        this.fontTitleSelector.show()
        this.fontSubtitleSelector.show()
        this.titleColor.show()
        this.subtitleColor.show()
        this.height.show()
        this.imgFit.show()

        this.showArrows.show()
        this.showDots.show()

        this.loop.show({
            value: CarouselModel.config.loop
        })
        this.autoplay.show({
            value: CarouselModel.config.autoplay
        })

        ModalEditor.show()
    }
}