import { componentSpacing, verifyHasClass } from '../../helpers/helpers'
import { IMAGE_TARGET, IMAGE_PANEL } from './constant'
import showTimeDelay from '../../helpers/timeDelay/showSettings'
import { showAnimations } from '../../helpers/animations'
import { hideMobileDesktop } from '../../helpers/form.settings'
import ColorPicker from '../../helpers/colorPicker'
const colorPicker = new ColorPicker()

import { useAlignmentBlock, useImageWidth } from '../../microComponents'
/**
 * Show setting form for this type. This function will be called when user clicks on setting button of component when setting panel is hidden. You can fulfill form controls in this function.
 * @param {jQuery} form Form contains all setting of this type and is child of `div[id="keditor-setting-forms"]`
 * @param {jQuery} component Component will be applied setting
 * @param {KEditor} keditor KEditor instance which is calling this function
 * @param {jQuery} componentTargetSpacing Component will be applied spacing setting
 */
export default function (form, component, keditor, componentTargetSpacing = null) {
  const panel = component.find(IMAGE_PANEL)
  let img = panel.find(IMAGE_TARGET).length ? panel.find(IMAGE_TARGET) : panel.find('img')

  const AlignmentBlock = useAlignmentBlock({ form, elementRef: () => panel })
  const ImageWidth = useImageWidth({ form, keditor, elementRef: () => img })
  ImageWidth.show()
  AlignmentBlock.show()

  componentSpacing(componentTargetSpacing ? componentTargetSpacing : panel, form, component, keditor, 1)

  showTimeDelay.call(self, form, component, keditor)
  showAnimations(form,keditor)
  hideMobileDesktop(form, componentTargetSpacing ? componentTargetSpacing : panel, keditor, null, '')

  if (!img.hasClass('img-product')) img.addClass('img-product')

  //Code was migrated to ./methods/index.js
  
}
