import KEditor from 'keditor'
// import CKEDITOR? from 'ckeditor'

import { templateForm, fontSizeOptions, hideMobileDesktop } from './helpers/form.settings'

import { textOptions, mobileFontSize, componentSpacing } from './helpers/helpers'

import initTimeDelay from './helpers/timeDelay/initSettings'
import showTimeDelay from './helpers/timeDelay/showSettings'
import {initAnimations, showAnimations} from './helpers/animations'

import ColorPicker from './helpers/colorPicker'
const colorPicker = new ColorPicker()

// CKEDITOR?.disableAutoInline = true

// Text component
// ---------------------------------------------------------------------
KEditor.components['text'] = {
  settingEnabled: true,

  settingTitle: 'Bloco de Texto',

  options: textOptions(true, 'Font'),

  init: function (contentArea, container, component, keditor) {
    let self = this
    let options = keditor.options

    let componentContent = component.children('.keditor-component-content').find('h1,h2,h3,h4,h5,h6,p')
    componentContent.prop('contenteditable', true)
    component.find('.timer').prop('contenteditable', false)

    componentContent.on('input', function (e) {
      if (typeof options.onComponentChanged === 'function') {
        options.onComponentChanged.call(keditor, e, component)
      }

      if (typeof options.onContainerChanged === 'function') {
        options.onContainerChanged.call(keditor, e, container, contentArea)
      }

      if (typeof options.onContentChanged === 'function') {
        options.onContentChanged.call(keditor, e, contentArea)
      }
    })

    const CKEDITOR = keditor.iframeWindow.window.CKEDITOR
    let editor = CKEDITOR?.inline(componentContent[0], self.options)
    editor.on('instanceReady', function () {
      $('#cke_' + componentContent.attr('id')).appendTo(keditor.wrapper)

      if (typeof options.onComponentReady === 'function') {
        options.onComponentReady.call(contentArea, component, editor)
      }
    })
  },

  getContent: function (component, keditor) {
    const CKEDITOR = keditor.iframeWindow.window.CKEDITOR
    let componentContent = component.find('.keditor-component-content')
    let id = componentContent.attr('id')
    let editor = CKEDITOR?.instances[id]

    componentContent.find('h1,h2,h3,h4,h5,h6,p').removeAttr('contenteditable')
    componentContent.find('h1,h2,h3,h4,h5,h6,p').removeAttr('spellcheck')
    componentContent
      .find('h1,h2,h3,h4,h5,h6,p')
      .removeClass('cke_editable cke_editable_inline cke_contents_ltr cke_show_borders')
    if (editor) {
      return editor.getData()
    } else {
      return componentContent.html()
    }
  },

  destroy: function (component, keditor) {
    const CKEDITOR = keditor.iframeWindow.window.CKEDITOR
    let id = component.find('.keditor-component-content').attr('id')
    CKEDITOR?.instances[id] && CKEDITOR?.instances[id].destroy()
  },

  initSettingForm: function (form, keditor) {
    form.append(
      templateForm({
        children: `
			<div class="form-settings-head">
    Fundo<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
	</div>
	<div class="form-group row" style="margin: 1rem 0rem">
		<div class="col-sm-12">
			<div id="background-text" class="input-group">
        <input data-id="#color-picker" class="color-picker-component form-control" />				
			</div>
		</div>
	</div>
			<div class="form-settings-head">
    Responsivo<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
  </div>
  
    <div class="form-group row" style="margin: 1rem 0rem">
      <div class="col-sm-12">
        <label for="input-color">Tamanho da Fonte no Celular</label>
        <select class="form-control mobile-font-size">
            ${fontSizeOptions(20)}
        </select>
      </div>
    </div>`,
        isContainer: true,
      })
    )

    initTimeDelay.call(self, form, keditor)
    initAnimations(form,keditor)
    mobileFontSize('.keditor-component-content', 20, form, null, keditor, 2)

    //Espaçamentos
    componentSpacing('.keditor-component-content', form, null, keditor, 2)

    colorPicker.init(form, (color) => {
      const component = keditor.getSettingComponent().find('.keditor-component-content').children().children()

      component.css('background-color', color)
    })

    hideMobileDesktop(form, null, keditor, '.keditor-component-content', 'edit-text')
  },

  showSettingForm: function (form, component, keditor) {
    const componentContent = component.find('.keditor-component-content').children().children()

    showTimeDelay.call(self, form, component, keditor)
    showAnimations(form,keditor)
    colorPicker.show(form, componentContent.css('background-color'))

    mobileFontSize(componentContent.find('span').first(), 20, form, component, keditor, 1)

    // Espaçamentos
    componentSpacing(componentContent, form, component, keditor, 1)

    hideMobileDesktop(form, componentContent, keditor, null, 'load-text')
  },
}
