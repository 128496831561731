import {
  useFontSelector,
  useToggleButton,
  useFontStyle,
  useFontSizeResponsive,
  useDropdown,
  useAlignmentBlock,
  useBackground,
  useBackgroundColor,
  useBackgroundGradient
} from '../../../microComponents'

const FontSizeResponsiveTitle = useFontSizeResponsive({
  customFormRef: 'font-size-responsive-Title',
  label: 'Tamanho',
})
const FontSizeResponsiveSubtitle = useFontSizeResponsive({
  customFormRef: 'font-size-responsive-Subtitle',
  label: 'Tamanho',
})
const FontSelector = useFontSelector({ label: 'Fonte' })
const ToggleButton = useToggleButton({ label: 'Abrir em nova aba' })
const FontStyle = useFontStyle({
  label: 'Peso',
})

const Select = useDropdown({
  label: '',
})
const AlignmentBlock = useAlignmentBlock({
  label: '',
})

const Background = useBackground({
  customTabs: [
    {
      name: 'Sólido',
      showClearOption: false,
      handle: useBackgroundColor({
        label: 'Cor de Fundo', fullWidth: true, isFirst: true
      })
    },
    {
      name: 'Degradê',
      showClearOption: false,
      handle: useBackgroundGradient({isFirst: true})
    }
  ]
})

export default /*html*/ `
    <div class="form-settings-head">
      FUNÇÃO<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
    </div>
    <div class="form-group " style="margin: 1rem 0rem">
      <div class="tab-content col-sm-12 ">
        ${Select.template}
        <div class="mt-3" id="default-config">
          <label>Link do Botão</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <button class="form-control dropdown-toggle" id="button-target-label" style="background-color: #e9ecef;"
                type="button" data-value="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="input-group-text">px</span>
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item button-target-type" href="#" data-value="https://">https://</a>
                <a class="dropdown-item button-target-type" href="#" data-value="http://">http://</a>
              </div>
            </div>
            <input type="text" class="form-control" id="button-link" />
          </div>
          ${ToggleButton.template}
          <div class="d-flex col-sm-12 mt-3">
            <label class="col-sm-10 p-0 m-0">Enviar parâmetros da página</label>
            <div class="col-sm-2 p-0 m-0">
              <div class="button-switch col-sm-2">
                <input 
                  type="checkbox" 
                  id="send-page-params" 
                  class="switch" 
                />
                <label for="send-page-params" class="lbl-off"></label>
                <label for="send-page-params" class="lbl-on"></label>
              </div>
            </div>
          </div>
        </div>
        <!-- link-element -->
        <div class="mt-3" id="roll-to-element">
          <label>Link do Botão</label>
          <div class="input-group">
            <div class="input-group-prepend" style="height: 35px">
              <span class="input-group-text" id="basic-addon1">#</span>
            </div>
            <input 
              style="height: 35px" 
              type="text" 
              id="roll-to-element-config"
              class="form-control" 
              placeholder="ID-do-elemento" 
              aria-label="ID-do-elemento" 
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
      </div>
      
    </div>
    <div class="form-settings-head">
      PRINCIPAL<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
    </div>
    <div class="form-group " style="margin: 1rem 0rem">
      <div class="tab-content col-sm-12 mt-3">
        <label for="button-text">Texto</label>
        <input type="text" class="form-control" id="button-text" />
      </div>
    
      <div class="d-flex flex-wrap">
        ${FontSizeResponsiveTitle.template} ${FontStyle.template}
      </div>
    </div>
    <div class="form-settings-head">
      SECUNDÁRIO<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
    </div>
    <div class="form-group " style="margin: 1rem 0rem">
      <div class="tab-content col-sm-12 mt-3">
        <label for="sub-button-text">Texto</label>
        <input type="text" class="form-control" id="sub-button-text" />
      </div>
      <div class="d-flex">
        ${FontSizeResponsiveSubtitle.template}
      </div>
    </div>
    <div class="form-settings-head">
      Fundo<span
        ><i class="fa fa-caret-down" aria-hidden="true"></i
      ></span>
    </div>
    <div class="form-custom" style="margin: 1rem 0rem;">
      ${Background.template}
    </div>
    <div class="form-settings-head">
      LAYOUT<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
    </div>
    <div class="form-group " style="margin: 1rem 0rem">
    <div class="d-flex flex-wrap">
      <div class="tab-content col-sm-6 mt-3">
        <label for="input-text-color"> Cor dos Textos </label>
        <div class="input-group">
          <input data-id="#input-text-color" class="color-picker-component form-control" />
        </div>
      </div>
      <!--<div class="tab-content col-sm-6 mt-3">
        <label for="input-background"> Cor de Fundo </label>
        <div class="input-group">
          <input data-id="#input-background" class="color-picker-component form-control" />
        </div>
      </div>-->
      ${FontSelector.template}
      ${AlignmentBlock.template}
    </div>
  </div>    
`
