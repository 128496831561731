const renderView = ({config, items, textsStyle}) => {

    function renderIndicators(conf, ite) {
        let res = ''
        let resInd = ''
        //if (conf.nav.some(el => el === "dots")) {
        // if (conf.showDots) {
            ite.map((item, index) => {
                resInd += /*html*/  `<li data-target="#${conf.uid}}" data-slide-to="${index}" class="${conf.active === item.id ? 'active' : ''}"></li>` 
            })
            res = /*html*/ `<ol class="carousel-indicators" style="display:${conf.showDots ? "flex" : "none"}" >${resInd}</ol>`
        // }
        return res
    }

    function renderControls(conf) {
        let res = ''
        //if (conf.nav.some(el => el === "arrows")) {
        // if (conf.showArrows) {
            res = /*html*/ `
            <div class="carousel-controls" style="display:${conf.showArrows ? "flex" : "none"}">
                <a class="carousel-control-prev" href="#${conf.uid}" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#${conf.uid}" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>`
        // }
        return res
    }

    let hmtlItems = ''
    items.map((item, index) => {
        hmtlItems += /*html*/  `
            <div class="carousel-item ${config.active === (index + 1) ? 'active' : ''}" loading="lazy" style="height: 100%;">
                <div class="carousel-item-image d-block w-100 h-100" style="background-image:url(${item.imgsrc});background-size:${config.imgFit};background-position: center;background-repeat: no-repeat;"></div>
                <div class="carousel-caption d-none d-md-block">
                    <h5 class="carousel-item-title"
                    data-font-family="${textsStyle.title.family}"
                    data-font_size_default="${textsStyle.title.size}" data-font_size_mobile="${textsStyle.title.sizeMobile}" data-lock="${textsStyle.title.sizelock}"
                    style="font-weight:bold;font-family:${textsStyle.title.family};font-size:${textsStyle.title.size}px;color:${textsStyle.title.color}"
                    >${item.title}</h5>
                    <span class="carousel-item-subtitle"
                    data-font-family="${textsStyle.subtitle.family}"
                    data-font_size_default="${textsStyle.subtitle.size}" data-font_size_mobile="${textsStyle.subtitle.sizeMobile}" data-lock="${textsStyle.subtitle.sizelock}"
                    style="font-family:${textsStyle.subtitle.family};font-size:${textsStyle.subtitle.size}px;color:${textsStyle.subtitle.color}"
                    >${item.subtitle}</span>
                </div>
            </div>
        ` 
    })

    const indicators = renderIndicators(config,items)
    const controls = renderControls(config)

    return /*html*/ `<div id="${config.uid}" class="carousel slide w-100" data-wrap="${config.loop}" data-ride="${config.autoplay ? "carousel" : "false"}">
        ${indicators}
        <div class="carousel-inner" style="height:${config.height};" data-height="${config.height}">
            ${hmtlItems}
        </div>
        ${controls}
    </div>
    `
  }

export default renderView



/**
 * Acho que vou escrever o meu próprio carrossel
 * 
 * Pega a estrutura html do bootstrap
 * Utiliza classes com mf na frente
 * Implementa a transição de slides
 * Implementa o loop
 * Implementa o autoplay
 * Implementa possibilidade de até 3 items simultâneos com responsividade, jogando pra 1 em mobile
 * Implementa lazyload com data-src
 */


/**
 * fazer o load (ok)
 * Gerar uid no onCreat do elemento (ok)
 * fazer o changeView (ok)
 * Implementar stylos font (ok)
 * Implementar controls e Indicators Config (ok)
 * Save Model (Modal Edition) (ok)
 * height - Altura do carrossel  (ok)
 * imgFit (encaixe das imagens) (ok)
 * Loop (ver config do BS) (ok)
 * autoplay (ver config do BS) (ok)
 * LazyLoad (data-src na img hookando o evento de onshow (se tiver) ou usando intersectObserver)
 */