import '../styles/keditor-component-form.less';

import KEditor from 'keditor';
import { textOptions, textEditor } from './helpers/helpers';
import { templateForm, hideMobileDesktop } from './helpers/form.settings';

import initTimeDelay from './helpers/timeDelay/initSettings';
import showTimeDelay from './helpers/timeDelay/showSettings';
import {initAnimations, showAnimations} from './helpers/animations'

import * as methods from './containers/list/methods.old';
import * as templates from './containers/list/template.old';

let itemId = 0; // controle interno do ID do item
let CKEDITOR;
KEditor.components['list-simple'] = {
  emptyContent: `<p class="text-muted lead text-center"><br />[Nenhum item encontrado, favor adicionar um novo item]<br /><br /></p>`,

  settingEnabled: true,

  settingTitle: 'Lista Simples',

  options: textOptions(true),

  init: function (contentArea, container, component, keditor) {
    let self = this;
    let options = self.options;

    let componentContent = component.find('.keditor-component-content');
    let listContent = component.find('.list-content');

    if (!listContent.find('.media').hasClass('item')) {
      listContent.find('.media').each(function () {
        $(this).addClass('item align-items-center');
      });
    }
    methods.initForm(component);

    if (listContent.children().length === 0) {
      componentContent.html(
        `<div class="d-flex item align-items-center justify-content-center item-0">${self.emptyContent}</div>`
      );
    } else {
      let listLength = listContent.children().last().attr('class').split(' ');
      listLength = listLength[listLength.length - 1];
      const lastItem = listLength[listLength.length - 1]; // pega o ultimo item da lista

      itemId = listContent.find('p') === self.emptyContent ? 0 : lastItem;
    }
    textEditor(
      '.keditor-component-content',
      component,
      keditor,
      options,
      contentArea,
      container,
      '.icon-text-group',
      true
    );
    CKEDITOR = keditor.iframeWindow.window.CKEDITOR
  },

  getContent: function (component, keditor) {
    let componentContent = component.find('.keditor-component-content');
    let id = componentContent.attr('id');
    let editor = CKEDITOR.instances[id];
    if (editor) {
      return editor.getData();
    } else {
      return componentContent.html();
    }
  },

  destroy: function (component, keditor) {
    let id = component.find('.keditor-component-content').attr('id');
    CKEDITOR.instances[id] && CKEDITOR.instances[id].destroy();
  },

  initSettingForm: function (form, keditor) {
    let self = this;
    form.html(templateForm({ children: templates.FORM_LAYOUT }));

    initTimeDelay.call(self, form, keditor);
    initAnimations(form,keditor)

    methods.initSettingFormButton(form, keditor, itemId, this.emptyContent);

    hideMobileDesktop(form, null, keditor, '.list-content', 'edit');
  },

  showSettingForm: function (form, component, keditor) {
    methods.showSettingFormButton(form, component, keditor, itemId, this.emptyContent);

    showTimeDelay.call(self, form, component, keditor);
    showAnimations(form,keditor)
    hideMobileDesktop(form, component.find('.list-content'), keditor, null, '');
  },
};
