import QuizModel from '../quizModel'
import changeView from './changeView'

function save(changeTheView = true) {
    $('.unsaved').show()
    const container = QuizModel.getRef(QuizModel.quizContainerRef)

    const data = JSON.stringify({
        redirectTo: QuizModel.redirectTo,
        showMessage: QuizModel.showMessage,
        showCorrect: QuizModel.showCorrect,
        openNewTab: QuizModel.openNewTab,
        quiz: QuizModel.questions,
    })
    container.attr('data-quiz', data)

    if(changeTheView) changeView(container.attr('data-preview'), false);
}

export default save