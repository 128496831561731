class CarouselModel {
    constructor() {
      this._form // = form
      this._keditor //= keditor
      this._component // = component
    }

    //Component References
    refs = {
        container: '#carousel-container',
        wrapper: '.carousel',
        itemsWrapper: '.carousel-inner',
        items: '.carousel-item',
        itemsImgs: '.carousel-item-image',
        titles: '.carousel-item-title',
        subtitles: '.carousel-item-subtitle',
        controls: '.carousel-controls',
        indicators: '.carousel-indicators',
        dataAttrConfig: 'data-carousel',
        dataAttrPreview: 'data-preview'
    }

    //Form elements
    elements = {
    }

    config = {
        uid: '#carousel',
        loop: true, // true | false
        autoplay: false, // true | false
        showArrows: true, // true | false
        showDots: true, // true | false
        numToShow: 1, // 1 | 2 | 3 // # of items to show at once in non-mobile viewport
        height: "380px", // [180px | 380px | 720px | 100vh]
        imgFit: "cover", // [none | cover | contain]
        //nav: [], // ["dots","arrows"] //[Seta e Pontos | pontos | setas | Nenhuma]
        active: 1 // The item to show
    }

    items = []

    itemsTemp = [] //temp item list to manage when modal is open (unsaved modifications)

    defaultItem() {
      return { 
        //id: this.items.length + 1,
        imgsrc: 'https://assets.makefunnels.com.br/media/users/u10/media-10-3266356d2de4763b401.png',
        title: '',
        subtitle: ''
      }
    }

    getRef(target) {
      return this._keditor.getSettingComponent() && this._keditor.getSettingComponent().find(target)
    }

    loadConfig() {
      const carouselContainer = this._component.find(this.refs.container)
      this.config = JSON.parse(carouselContainer.attr(this.refs.dataAttrConfig))
    }

    saveConfig() {
      const dataConfig = JSON.stringify({...this.config})
      this._component.find(this.refs.container).attr(this.refs.dataAttrConfig, dataConfig)
    }

    loadItems() {

      const componentItems = this._component.find(this.refs.items) //captura os itens do component
      this.items = [] //zera a lista de itens do model

      //se encontrar itens do component, popular a lista do model
      if (componentItems && componentItems.length > 0) { 
        componentItems.each((index, el) => {
          let cItemJ = $(el)
          this.items.push({
            //id: index + 1,
            imgsrc: cItemJ.find(this.refs.itemsImgs).css("background-image").replace(/^url\(['"](.+)['"]\)/, '$1'),//.attr('src'),
            title: cItemJ.find(this.refs.titles).text(),
            subtitle: cItemJ.find(this.refs.subtitles).text()
          })
        })
      }else{
        this.items = [this.defaultItem()]
      }

    }

    set component(newComponent) {
      this._component = newComponent
    }
  
    get component() {
      return this._component
    }
  
    set keditor(keditor) {
      this._keditor = keditor
    }
  
    get keditor() {
      return this._keditor
    }
  
    set form(form) {
      this._form = form
    }
  
    get form() {
      return this._form
    }

}  

const CarouselModelInstance = new CarouselModel()

export default CarouselModelInstance



//Definir as funcionalidades e configurações do carrossell
//Definir o layout do carrossell
//Definir como vai ser a configuração via modal

/**
 * Carrossel de Imagem:

- Número de exibião (slides per view) [1 (w 100%) | 2 (w 50%) | 3 (w 33%)] (quantidade de itens par mostrar de uma vez, desde que caiba pelo menos 200px por item (responsivo))  (ok)
- Layout das imagens [Cobrir | auto | conter]
- Navegação [Seta e Pontos | pontos | setas | Nenhuma] (ok)

//- Largura do container [Pequeno (300px) | Medio (600px) | Grande (900px) | Tela Cheia (100%)] (ou espaçamento entre imagens)
- Altura do carrossel [auto | Pequeno (180px) | Médio (380px) | Grande (720px) | Altura da Tela (1vh)](min 100px max 1000px) (altura da div pai)  (ok)

- Loop (toogle)  (ok)
- Auto Play (toogle)  (ok)

Transition speed: 500ms
Autoplay speed: 5s
Pause on hover option?


VER COMO FUNCIONA O LAZY LOAD DE BG IMG (VE SE O BROWSER JA NAO TA FAZENDO COM DISPLY NONE)

AUTO + BOLD

 */