// import '../styles/keditor-component-form.less'
import KEditor from 'keditor'
import { componentSpacing } from './helpers/helpers'
import { hideMobileDesktop } from './helpers/form.settings'
import initTimeDelay from './helpers/timeDelay/initSettings'
import showTimeDelay from './helpers/timeDelay/showSettings'
import {initAnimations, showAnimations} from './helpers/animations'
import QuizComponent from './containers/quiz/methods'

let quizComponent = new QuizComponent()
KEditor.components['quiz'] = {
  emptyContent: `<p class="text-muted lead text-center"><br />[Nenhum item encontrado, favor adicionar um novo item]<br /><br /></p>`,

  settingEnabled: true,

  settingTitle: 'Quiz',

  init: function (contentArea, container, component, keditor) {
    quizComponent.keditor = keditor
    quizComponent.component = component
    quizComponent.create()
  },

  initSettingForm: function (form, keditor) {
    form.html(quizComponent.template)
    quizComponent.form = form
    quizComponent.init()

    initTimeDelay.call(self, form, keditor)
    initAnimations(form,keditor)
    hideMobileDesktop(form, null, keditor, '.quiz', 'edit')
    //Espaçamentos
    componentSpacing('.quiz', form, null, keditor, 2)
  },

  showSettingForm: function (form, component, keditor) {
    quizComponent.component = component
    quizComponent.show()

    showTimeDelay.call(self, form, component, keditor)
    showAnimations(form,keditor)
    hideMobileDesktop(form, component.find('.quiz'), keditor, null, '')
    // Espaçamentos
    componentSpacing(component.find('.quiz'), form, component, keditor, 1)
  },
}
