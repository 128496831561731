import KEditor from 'keditor'
import { templateForm } from './helpers/form.settings'
import * as template from './containers/photo/templates'
import showSettings from './containers/photo/showSettings'
import initSettings from './containers/photo/initSettings'
import { verifyHasClass } from './helpers/helpers'
import PhotoComponent from './containers/photo/methods'
import { ADVANCED } from './containers/photo/templates'
import { FORM_ADVANCED } from './containers/photo/templates/advanced'

let photoComponent = new PhotoComponent()
KEditor.components['photo'] = {
  settingEnabled: true,

  settingTitle: 'Imagem',
  /**
   * Function will be called when initializing a component with this type
   * @param {jQuery} contentArea
   * @param {jQuery} container
   * @param {jQuery} component
   * @param {KEditor} keditor KEditor instance which is calling this function
   */
  init: function (contentArea, container, component, keditor) {
    let componentContent = component.children('.keditor-component-content')
    let img = componentContent.find('img')

    photoComponent.keditor = keditor
    const hasNewClasses = verifyHasClass(component.find('.photo-panel'), [
      'default-text-center',
      'default-text-left',
      'default-text-right',
      'default-text-justify',
      'mobile-text-center',
      'mobile-text-left',
      'mobile-text-right',
      'mobile-text-justify',
    ])

    if (!hasNewClasses) {
      component.find('.photo-panel').addClass(`default-text-${component.find('.photo-panel').css('textAlign')}`)
      component.find('.photo-panel').addClass(`mobile-text-${component.find('.photo-panel').css('textAlign')}`)
      component.find('.photo-panel').css('textAlign', '')
    }

    img.css('display', 'inline-block')
    img.css('max-width', '100%')

    img.on('click', function (e) {
      e.preventDefault()
    })
  },

  /**
   * Initialize setting form of this type
   * @param {jQuery} form Form contains all setting of this type and is child of `div[id="keditor-setting-forms"]`
   * @param {KEditor} keditor KEditor instance which is calling this function
   */
  initSettingForm: function (form, keditor) {
    form.html(
      templateForm({
        children: template.FORM_LAYOUT({ isPhoto: true, customTabConfig: FORM_ADVANCED }),
        // advancedTemplate: FORM_ADVANCED,
      })
    )
    initSettings.call(self, form, keditor)
    photoComponent.form = form
    photoComponent.keditor = keditor
    photoComponent.init()
  },

  /**
   * Show setting form for this type. This function will be called when user clicks on setting button of component when setting panel is hidden. You can fulfill form controls in this function.
   * @param {jQuery} form Form contains all setting of this type and is child of `div[id="keditor-setting-forms"]`
   * @param {jQuery} component Component will be applied setting
   * @param {KEditor} keditor KEditor instance which is calling this function
   */
  showSettingForm: function (form, component, keditor) {
    showSettings.call(self, form, component, keditor)
    photoComponent.component = component
    photoComponent.show()
  },
}
