import { useBackgroundColor, useColor, useFontSizeResponsive } from '../../../microComponents'

const barColor = useBackgroundColor({
  label: 'Cor',
  isFirst: true,
})

const textColor = useColor({
  label: 'Cor do texto',
  isFirst: true,
  customFormRef: 'text-color-editor',
})

const fontSizeMobile = useFontSizeResponsive({
  label: 'Tamanho',
})

export default /*html*/ `
<div class="form-settings-head">
  LAYOUT <span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group" style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    ${barColor.template}
    ${textColor.template}
    <div class="col-sm-6 mt-3">
      <label for="percent">Porcentagem (%)</label>
      <input type="number" id="percent" class="form-control" min="0" max="100" />
    </div>
    <div class="col-sm-6 mt-3">
      <label for="bar-height">Altura</label>
      <select id="bar-height" class="form-control">
        <option value="20">Pequeno</option>
        <option value="40">Médio</option>
        <option value="60">Grande</option>
      </select>
    </div>
  </div>
</div>

<div class="form-settings-head">
  TIPOGRAFIA <span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>

<div class="form-group" style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    <div class="col-sm-12">
      <label for="bar-text">Descrição</label>
      <input type="text" id="bar-text" class="form-control" placeholder="Digite aqui o texto da barra de progressão" />
    </div>
    ${fontSizeMobile.template}
    
  </div>
</div>

`
