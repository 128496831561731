// import '../styles/keditor-component-form.less'
import KEditor from 'keditor'
import { componentSpacing } from '../helpers/helpers'
import { hideMobileDesktop } from '../helpers/form.settings'
import initTimeDelay from '../helpers/timeDelay/initSettings'
import showTimeDelay from '../helpers/timeDelay/showSettings'
import {initAnimations, showAnimations} from '../helpers/animations'
import QuizControl from './quizControl'

const QuizComponent = new QuizControl()

KEditor.components['quiz-v2'] = {
  emptyContent: `<p class="text-muted lead text-center"><br />[Nenhum item encontrado, favor adicionar um novo item]<br /><br /></p>`,

  settingEnabled: true,

  settingTitle: 'Quiz',

  init: function (contentArea, container, component, keditor) {
    QuizComponent.keditor = keditor
    QuizComponent.component = component
    QuizComponent.create()
  },

  initSettingForm: function (form, keditor) {
    form.html(QuizComponent.template)
    QuizComponent.form = form
    QuizComponent.init()

    initTimeDelay.call(self, form, keditor)
    initAnimations(form,keditor)
    hideMobileDesktop(form, null, keditor, '.quiz-v2', 'edit')
    //Espaçamentos
    componentSpacing('.quiz-v2', form, null, keditor, 2)
  },

  showSettingForm: function (form, component, keditor) {
    QuizComponent.component = component
    QuizComponent.show()

    showTimeDelay.call(self, form, component, keditor)
    showAnimations(form,keditor)
    hideMobileDesktop(form, component.find('.quiz-v2'), keditor, null, '')
    // Espaçamentos
    componentSpacing(component.find('.quiz-v2'), form, component, keditor, 1)
  },
}
