import {BLUE_COLOR} from '../../../../constants'

const content = /*html*/ `
<div class="form-group row" style="margin: 1rem 0rem">
  <div class="tab-content col-sm-12">
      <div class="button-style btn-variant w-100" data-btn-style="1" data-btn-config='{
        "style": {
          "border-radius": "0px",
          "background-color": "${BLUE_COLOR}",
          "border-color": "${BLUE_COLOR}"
        },
        "advance": {
          "box-shadow-ref": 0
        }
      }'>Clássico</div>
      <div class="button-style btn-variant btn-variant-1 w-100" data-btn-style="2" data-btn-config='{
        "style": {
          "border-radius": "50px",
          "background-color": "transparent",
          "border": "1px solid ${BLUE_COLOR}"
        },
        "advance": {
          "box-shadow-ref": 0
        }
      }'>Variante 1</div>
      <div class="button-style btn-variant btn-variant-2 w-100" data-btn-style="3" data-btn-config='{
        "style": {
          "border-radius": "10px",
          "background-color": "${BLUE_COLOR}",
          "border-color": "${BLUE_COLOR}"
        },
        "advance": {
          "box-shadow-ref": 3
        }
      }'>Variante 2</div>
  </div>
</div>
`

export default {
    id: 'theme-button',
    href: 'theme_button_tab',
    label: 'Estilos',
    content,
}
