import initModalPhoto from '../../../keditor/modal/initModalPhoto'
import { IMAGE_TARGET, IMAGE_PANEL } from './constant'
import ColorPicker from '../../helpers/colorPicker'
import { useAlignmentBlock, useImageWidth } from '../../microComponents'
import imageEventListener from '../../helpers/imageEventListener'
const colorPicker = new ColorPicker()

/**
 * @param {jQuery} form Form contains all setting of this type and is child of `div[id="keditor-setting-forms"]`
 * @param {KEditor} keditor KEditor instance which is calling this function
 * @param {Boolean} hasAlignConfig If the component has the alignment configuration
 */
export default function (form, keditor, hasAlignConfig = true) {
  const AlignmentBlock = useAlignmentBlock({ form, elementRef: () => keditor.getSettingComponent().find(IMAGE_PANEL) })
  const ImageWidth = useImageWidth({
    form,
    keditor,
    elementRef: () => keditor.getSettingComponent().find(IMAGE_TARGET),
  })
  AlignmentBlock.init()
  ImageWidth.init()
  /**
   * Evento para quando o usuário clicar no botão apra abrir o modal da galeria
   */
  form.on('click', '#photo-galery, #photo-galery-2', function (e) {
    keditor.options.locale.modalImage.isContainer = false
    keditor.options.locale.modalImage.isIframe = false
    keditor.options.locale.modalImage.target = keditor.getSettingComponent().find(IMAGE_PANEL)
    keditor.options.locale.modalImage.form = form
  })

  /**
   * Evento para definir o alt de uma imagem
   */
  form.find('#image-alt').on('change', function (e) {
    keditor.getSettingComponent().find(IMAGE_TARGET).attr('alt', this.value)
  })

  /**
   * Evento para quando o usuário adicionar um link de imagem
   */
  // form.find('#external-url').on('change', function (e) {
  //   const target = keditor.getSettingComponent().find(IMAGE_PANEL)
  //   const targetImage = target.find(IMAGE_TARGET)
  //   if (this.value.length > 0) {
  //     form.find('.loading-config').html(`
  //     <div style="width: 100%; text-align: center" class="my-3">
  //       Carregando informações da imagem...
  //       <div class="spinner-border text-primary mt-2" role="status">
  //         <span class="visually-hidden"></span>
  //       </div>
  //     </div>
  //     `)
  //     const loading = {
  //       timer: 2500,
  //       message: 'Carregando as informações da imagem...',
  //     }
  //     if (target.find('.demo-photo').is(':visible')) {
  //       target.removeClass('d-flex justify-content-center')
  //       target.css('text-align', 'center')
  //       target.find('.demo-photo').css({ display: 'none' })
  //       target.find('.img-product').attr({
  //         src: this.value,
  //         'data-cke-saved-src': this.value,
  //       })
  //       form.find('#photo-style').val('')
  //     } else {
  //       target.find('.img-product').attr({
  //         src: this.value,
  //         'data-cke-saved-src': this.value,
  //       })
  //       loading.message = 'Recarregando as informações da imagem...'
  //       loading.timer = 1500
  //     }

  //     // Mostra o restante das configurações da imagem
  //     form.find('.photo-config').css('display', 'none')
  //     form.find('.border-config').hide()
  //     form.find('.config-alert').show()
  //     form.find('.loading-config').html(`
  //       <div style="width: 100%; text-align: center" class="my-3">
  //         <div>
  //           ${loading.message}
  //         </div>
  //         <div class="spinner-border text-primary mt-2" role="status">
  //           <span class="visually-hidden"></span>
  //         </div>
  //       </div>
  //     `)

  //     targetImage.on('load', function () {
  //       form.find('.photo-config').css('display', 'block')
  //       form.find('.border-config').show()
  //       form.find('.config-alert').hide()
  //       form.find('.loading-config').html('')

  //       form.find('.custom-class').val(target.prop('class'))
  //       form.find('#photo-align').val(target.css('text-align'))

  //       colorPicker.show(form, targetImage.css('border-color'))
  //       form.find('#photo-width').val(targetImage.css('width').split('px')[0])

  //       form.find('#image-link').val(target.find('.image-anchor').attr('href'))

  //       form.find('#link-target').val(target.find('.image-anchor').attr('target'))

  //       form.find('#image-alt').val(targetImage.attr('alt'))
  //     })
  //   } else {
  //     form.find('.photo-config').css('display', 'none')
  //     form.find('.border-config').hide()
  //     form.find('.config-alert').show()
  //     target.addClass('d-flex justify-content-center')

  //     target.find('.demo-photo').css({ display: 'flex' })
  //     targetImage.attr({
  //       src: '',
  //       'data-cke-saved-src': '',
  //     })
  //     targetImage.css({ borderWidth: '0px' })
  //     form.find('#img-border').val('0')
  //   }
  // })

  /**
   * Evento para quando o usuário quiser alterar o alinhamento
   */
  // if (hasAlignConfig) {
  //   form.find('#photo-align').on('change', function () {
  //     let panel = keditor.getSettingComponent().find(IMAGE_PANEL)
  //     panel.css('text-align', this.value)
  //   })
  // }

  /**
   * Evento para quando o usuário quiser alterar o estilo da borda da imagem
   */
  // form.find('#photo-style').on('change', function () {
  //   let img = keditor.getSettingComponent().find(IMAGE_TARGET)
  //   let val = this.value
  //   img.removeClass('rounded rounded-circle img-thumbnail')
  //   if (this.value != '') form.find('.raio-group').hide()
  //   else form.find('.raio-group').show()
  //   if (val) {
  //     img.addClass(val)
  //   }
  //   if (!img.hasClass('rounded-circle') || !img.hasClass('rounded')) {
  //     let borderRadius = 0
  //     if (img.css('border-radius').split('px').length != 1) borderRadius = img.css('border-radius').split('px').join('')
  //     else if (img.css('border-radius').split('%').length != 1)
  //       borderRadius = img.css('border-radius').split('%').join('')

  //     form.find('#border-radius').val(borderRadius)
  //   }
  // })

  // /**
  //  * Evento para quando o usuário quiser alterar a cor da borda da imagem
  //  */

  // colorPicker.init(form, (color) => {
  //   keditor.getSettingComponent().find(IMAGE_TARGET).css('border-color', color)
  // })

  // /**
  //  * Evento para quando o usuário quiser alterar a espessura da borda da imagem
  //  */
  // form.find('#img-border').on('change', function () {
  //   let img = keditor.getSettingComponent().find(IMAGE_TARGET)

  //   img.css('border-style', this.value <= 0 ? 'none' : 'solid')
  //   img.css('border-width', this.value)
  // })

  // /**
  //  * Evento para quando o usuário quiser alterar a curvatura da borda da imagem
  //  */
  // form.find('#border-radius').on('change', function () {
  //   keditor.getSettingComponent().find(IMAGE_TARGET).css('border-radius', `${this.value}px`)
  // })

  // /**
  //  * Evento para definir o redirecionamento da imagem
  //  */
  // form.find('#image-link').on('change', function (e) {
  //   const image = keditor.getSettingComponent().find(IMAGE_TARGET)
  //   const panel = keditor.getSettingComponent().find(IMAGE_PANEL)

  //   if (panel.find('.image-anchor').length) {
  //     panel.find('.image-anchor').attr('href', this.value)
  //     if (!this.value.length) image.unwrap()
  //   } else {
  //     image.wrap(`<a class='image-anchor' href='${this.value}' target='${form.find('#link-target').val()}'></a>`)
  //   }

  //   image.on('click', function (e) {
  //     e.preventDefault()
  //   })
  // })

  // /**
  //  * Evento para definir o redirecionamento da imagem
  //  */
  // form.find('#link-target').on('change', function (e) {
  //   const image = keditor.getSettingComponent().find(IMAGE_TARGET)
  //   const panel = keditor.getSettingComponent().find(IMAGE_PANEL)

  //   if (panel.find('.image-anchor').length) {
  //     panel.find('.image-anchor').attr('target', this.value)
  //   } else {
  //     image.wrap(`<a class='image-anchor' href='#' target='${this.value}'></a>`)
  //   }

  //   image.on('click', function (e) {
  //     e.preventDefault()
  //   })
  // })
}
