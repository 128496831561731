class QuizModel {
  constructor() {
    this._form // = form
    this._keditor //= keditor
    this._component // = component
  }

  elements = {
    selectView: {
      selector: '#select-view',
      data: null,
    },
    quizHrefType: {
      selector: '#quiz-target-type',
      data: null,
    },
    quizLink: {
      selector: '#quiz-link',
      data: null,
    },
    quizAction: {
      selector: '#quiz-action',
      data: null,
    },
    inputMessage: {
      selector: '#message-text',
      data: null,
    },
    showCorrect: {
      selector: '#show-correct',
      data: null,
    },
    openNewTab: {
      selector: '#open-new-tab',
      data: null,
    },
  }

  questions = [
    {
      id: 1,
      type: "multipleChoice",
      question: 'Escreva aqui a pergunta',
      correct: 0,
      answers: ['Escreva a resposta 1 aqui', 'Escreva a resposta 2 aqui', 'Escreva a resposta 3 aqui', 'Escreva a resposta 4 aqui'],
      imgsrc: ''
    },
  ]

  defaultMultipleChoiceQuestion() {
    return { 
      id: this.questions.length + 1,
      type: "multipleChoice",
      question: 'Escreva aqui a pergunta',
      correct: 0,
      answers: ['Escreva a resposta 1 aqui', 'Escreva a resposta 2 aqui', 'Escreva a resposta 3 aqui', 'Escreva a resposta 4 aqui'],
      imgsrc: ''
    }
  }

  redirectTo = ''
  showMessage = true
  showCorrect = true
  openNewTab = false

  href = 'https://'

  titleRef = '#quiz-title'
  quiz = '#quiz-v2'
  progressRef = '.progress'
  answersRef = '.answers'
  viewQuestionRef = '#select-view'
  quizContainerRef = '#quiz-v2-container'
  redirectConfigRef = '#redirect-config'
  messageConfigRef = '#message-config'
  message = '#message'
  openGallery = '#photo-galery, #photo-galery-2'
  primaryColor = ".quiz-primary-color"
  primaryColorBg = ".quiz-primary-color-bg"
  secondaryColorBg = ".quiz-secondary-color-bg"
  tertiaryColorBg = ".quiz-tertiary-color-bg"

  curShowProgressBool = true
  curPrimaryColor = '#212529';
  curSecondaryColor = '#FF2A87';
  curTertiaryColor = '#EEEEEE';

  getQuestion(index) {
    return this.questions[index] || {}
  }

  // METHODS
  getRef(target) {
    return this._keditor.getSettingComponent() && this._keditor.getSettingComponent().find(target)
  }

  set component(newComponent) {
    this._component = newComponent
  }

  get component() {
    return this._component
  }

  set keditor(keditor) {
    this._keditor = keditor
  }

  get keditor() {
    return this._keditor
  }

  set form(form) {
    this._form = form
  }

  get form() {
    return this._form
  }

  resetColors()
  {
    this.curPrimaryColor = '#212529';
    this.curSecondaryColor = '#FF2A87';
    this.curTertiaryColor = '#EEEEEE';
  }

}

const QuizModelInstance = new QuizModel()

export default QuizModelInstance

/** ReviewThis
 * Criar duas instâncias do Quiz (Main | Temp)
 * Exportar ambas as instâncias
 * Importar as instâncias, de acordo com necessidade, em todos as ocorrências de importação do atual QuizModel
*/