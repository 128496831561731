import { changeOneClass } from "./helpers";
import { filter } from "./completeListIcons";

// configuracões do form
export function dropdownIconConfig(form, keditor, content, id) {
  // const items = form.find(`.menuItems`);
  const items = form.find(`#menuItems-${id}`);
  const search = form.find(".searchIcon");
  const dropdownButton = form.find(`#item-icon-${id}`);
  items.append(content);

  search.on("keyup", function () {
    filter(this.value, items);
  });

  form
    .find(`#menuItems-${id}`)
    .find(".dropdown-item")
    .on("click", function () {
      const item = keditor.getSettingComponent().find(`.list-item-${id}`);

      const icon = keditor.getSettingComponent().find(`#icon-${id}`);
      let type = $(this).attr("class").split(" fa ");

      if (icon.length !== 0) {
        const classString = changeOneClass(icon, "fa-", type[1]);
        icon.removeClass();
        icon.addClass(classString);
      } else {
        item.prepend(`
          <div class="m-2">
            <i id="icon-${id}" class="fa ${type[1]} fz-2x icon-text" ></i>
          </div>
        `);
      }

      dropdownButton.html(`<i class="fa ${type[1]}" ></i>`);
    });
}

export function textConfig(form, keditor, id, hasTextarea = false) {
  const text = form.find(`#item-input-${id}`);

  const titleCard = form.find(`.btn-item-${id}`);
  text.on("keyup", function () {
    const component = keditor.getSettingComponent();
    const itemText = !hasTextarea
      ? component.find(`.item-text-${id}`).find("span")
      : component.find(`.item-title-${id}`).find("span");

    itemText.html(this.value);
    titleCard.html(this.value);
  });

  if (hasTextarea) {
    form.find(`#texteare-item-${id}`).on("keyup", function () {
      const component = keditor.getSettingComponent();
      const itemText = component.find(`.item-text-${id}`);

      itemText.html(this.value);
    });
  }
}

export function removeItem(form, keditor, id, msg = "") {
  const item = form.find(`#item-${id}`);
  const remove = form.find(`#remove-item-${id}`);
  remove.on("click", function () {
    const component = keditor.getSettingComponent();

    const itemView = component.find(`.list-item-${id}`);
    item.remove();
    itemView.remove();

    const listContentChildren = component.find(".list-content").children();
    const textField = form.find(`.form-text`); //.find("input");

    let i = 1;
    textField.each(function () {
      this.value = "item #" + i;
      i++;
    });

    if (listContentChildren.length === 0) {
      component
        .find(".keditor-component-content")
        .html(`<div class="list-content">${msg}</div>`);

      form.find(".list-config").hide();
    }
  });
}

export const newSimpleItem = (id) => `<div 
  class="p-2 row d-flex flex-nowrap bd-highlight question"
  id="question-${id}"
>
  <div class="flex-grow-1 bd-highlight">
    ${_inputList(id, false)}
  </div>
  ${_removeItem(id)}
</div>`;

export const newItem = (id) => `<div
  class="d-flex flex-nowrap mt-2"
  id="item-${id}"
>
  ${_dropdownIcon(id)}
  <input
    type="text"
    class="form-control form-text"
    id="item-input-${id}"
    placeholder="Item da lista"
    readonly
  />
  ${_removeItem(id)}
</div>`;

// visualizacão do form
export const colorConfig = `
<div class="form-group">
  <label for="input-color">Cor dos Icones</label>
  <div id="input-color" class="input-group">
    <input data-id="#color-picker" class="color-picker-component form-control" />
  </div>
</div>`;

export const iconConfig = `

<div class="form-group">
  <label for="icon-size" >Tamanho</label>
  <select id="icon-size" class="form-control">
    <option value="fz-lg">Muito Pequeno</option>
    <option value="fz-2x">Pequeno</option>
    <option value="fz-3x">Médio</option>
    <option value="fz-4x">Grande</option>
  </select>
</div>`;

// FUNÇÕES INTERNAS
const _dropdownIcon = (id) => `
<div 
  class="dropdown mr-1 col-sm-4 mx-0 px-0" 
  id="dropdown-${id}" 
>
  <button
    class="btn btn-primary
      dropdown-toggle d-flex justify-content-between
      align-items-center w-100 h-100 "
    type="button"
    id="item-icon-${id}"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    Icone
  </button>
  <div 
    class="dropdown-menu col-sm-12" 
    aria-labelledby="item-icon" 
  >
    <div class="dropdown-header">
      <input
        type="search"
        class="form-control searchIcon"
        placeholder="address book"
        autofocus="autofocus"
      />
    </div>
    <div class="menuItems" id="menuItems-${id}"></div>
  </div>
</div>
`;

// - Lista simples
const _inputList = (id, isReadonly = true) => `
  <input
    type="text"
    class="form-control form-text"
    id="item-input-${id}"
    placeholder="Item da lista"
    aria-label="lista item"
    aria-describedby="basic-addon1"
    ${isReadonly ? "readonly" : ""}
  />
`;

const _removeItem = (id, text = "") => {
  return `<div class="col-sm-2 h-100 mx-0 px-0" >
  <button 
    class="btn btn-danger text-white" 
    id="remove-item-${id}"
  >
    ${text} <i class="fa fa-trash fz-lg"></i>
  </button>
</div>`;
};
