import KEditor from 'keditor'
import ProgressComponent from './containers/progress'
import { hideMobileDesktop } from './helpers/form.settings'
import initTimeDelay from './helpers/timeDelay/initSettings'
import showTimeDelay from './helpers/timeDelay/showSettings'
import {initAnimations, showAnimations} from './helpers/animations'

let progressComponent = new ProgressComponent()
KEditor.components['progress'] = {
  settingEnabled: true,

  settingTitle: 'Barra de Progressão',

  init: function (contentArea, container, component, keditor) {},

  initSettingForm: function (form, keditor) {
    form.html(progressComponent.template)
    progressComponent.keditor = keditor
    progressComponent.form = form
    progressComponent.init()

    initTimeDelay.call(self, form, keditor)
    initAnimations(form,keditor)
    hideMobileDesktop(form, null, keditor, '.progress', 'edit')
  },

  showSettingForm: function (form, component, keditor) {
    progressComponent.component = component
    progressComponent.show()
    showTimeDelay.call(self, form, component, keditor)
    showAnimations(form,keditor)
    hideMobileDesktop(form, component.find('.progress'), keditor, null, '')
  },
}
