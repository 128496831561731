import QuizModel from '../quizModel'
import save from './save'
import renderView from './renderView'

function changeView(value, isChanging = true) {
    QuizModel.component.find(QuizModel.quiz)[0].innerHTML = renderView({
        el: {
            ...QuizModel.questions[value],
            mobile: {
                question: QuizModel.component.find(QuizModel.titleRef).data('font_size_mobile'),
                answer: QuizModel.component.find(QuizModel.answersRef).data('font_size_mobile'),
            },
            default: {
                question: QuizModel.component.find(QuizModel.titleRef).data('font_size_default'),
                answer: QuizModel.component.find(QuizModel.answersRef).data('font_size_default'),
            },
            lock: QuizModel.component.find(QuizModel.answersRef).data('lock'),
            colors: {
                primary: QuizModel.curPrimaryColor,
                secondary: QuizModel.curSecondaryColor,
                tertiary: QuizModel.curTertiaryColor,
            },
            showProgress: QuizModel.curShowProgressBool
        },
    })

    if (isChanging) {
        save()
    }
}
export default changeView