import { useFontSelector, useFontSizeResponsive, useDropdown } from '../../../microComponents'

const fontFamily = useFontSelector({
  label: 'Fonte',
  isFirst: true,
  fullWidth: true,
})

const fontSizeMobile = useFontSizeResponsive({
  label: 'Tamanho',
  customFormRef: 'font-size-text',
  isFirst: true,
  inputMaxLength: 35,
})

const fontSizeAnswers = useFontSizeResponsive({
  label: 'Tamanho',
  customFormRef: 'font-size-answers',
  isFirst: true,
  inputMaxLength: 24,
})

const Select = useDropdown({
  label: '',
  customOptions: [
    {
      value: 'redirect',
      text: 'Ir para URL',
      icon: 'fa fa-globe',
    },
    {
      value: 'message',
      text: 'Exibir Mensagem',
      icon: 'fa fa-envelope',
    },
  ],
  customFormRef: 'quiz-action',
})

const ToggleConfig = ({ label, formRef }) => /*html*/ `
<div class="d-flex mt-3">
  <label class="col-sm-10 p-0 m-0">${label}</label>
  <div class="col-sm-2 p-0 m-0">
    <div class="button-switch col-sm-2">
      <input 
        type="checkbox" 
        id="${formRef}" 
        class="switch" 
      />
      <label for="${formRef}" class="lbl-off"></label>
      <label for="${formRef}" class="lbl-on"></label>
    </div>
  </div>
</div>
`

const quizAction = /*html*/ `
${Select.template}
<div class="mt-3" id="redirect-config" style="display: none">
  <label>Link do Botão</label>
  <div class="input-group">
    <div class="input-group-prepend">
      <button class="form-control dropdown-toggle" id="quiz-target-label" style="background-color: #e9ecef;"
        type="button" data-value="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          https://
      </button>
      <div class="dropdown-menu" id="quiz-target-type">
        <a class="dropdown-item" href="#" data-value="https://">https://</a>
        <a class="dropdown-item" href="#" data-value="http://">http://</a>
      </div>
    </div>
    <input type="text" class="form-control" id="quiz-link" />
  </div>

  ${ToggleConfig({ label: 'Abrir em nova aba', formRef: 'open-new-tab' })}
</div>
<div class="mt-3" id="message-config">
  <label>Mensagem de conclusão</label>
  <input type="text" class="form-control" id="message-text" />
</div>
`

const functionConfig = /*html*/ `
<div class="form-settings-head">
  AÇÃO AO CONCLUIR<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group " style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    <div class="col-sm-12">
      ${quizAction}
    </div>
  </div>
</div>
`

const itemsConfigBlock = /*html*/ `
<div class="form-settings-head">
  LAYOUT<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group " style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    ${fontFamily.template}
    <hr class="col-sm-12" style="width: 100%"/>
    <h6 class="col-sm-12 mb-2" style="width: 100%">Pergunta</h6>
    ${fontSizeMobile.template}
    <hr class="col-sm-12" style="width: 100%"/>
    <h6 class="col-sm-12 mb-2" style="width: 100%">Respostas</h6>
    ${fontSizeAnswers.template}
  </div>
</div>
`

export default /*html*/ `
${functionConfig}
${itemsConfigBlock}
<div class="form-settings-head">
  QUIZ<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group " style="margin: 1rem 0rem">
  <div class="tab-content col-sm-12">
    <label> Previsualizar: </label>
    <select class="form-control" id="select-view">
    </select>
    ${ToggleConfig({ label: 'Exibir opção correta', formRef: 'show-correct' })}
    <!--  -->
    <hr />
    <button class="btn btn-outline-primary w-100" style="color: #fff; border: 1px solid #4C6FFF" id="add-item">
    <i class="fa fa-plus" aria-hidden="true"></i> Adicionar Pergunta
    </button>
  </div>
  <div class="tab-content col-sm-12 mt-3" id="list-items"></div>
</div>
`
