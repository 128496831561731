import Config from './config'
import Advanced from './advanced'

export const FORM_CONFIG = `
  ${Config}
`

export const FORM_ADVANCED = `
  ${Advanced}
`
