import CarouselModel from '../carouselModel'
import renderView from './renderView'

function updateView(insertConfig = false) {

    const carouselContainer = CarouselModel.component.find(CarouselModel.refs.container)
    const titles = carouselContainer.find(CarouselModel.refs.titles)
    const subtitles = carouselContainer.find(CarouselModel.refs.subtitles)

    carouselContainer[0].innerHTML = renderView({
        config: {...CarouselModel.config},
        items:[...CarouselModel.items],
        textsStyle: {
            title:{
                family: titles.data("font-family") || "Roboto",
                size: titles.data("font_size_default") || "20",
                sizeMobile: titles.data("font_size_mobile") || "20",
                sizelock: titles.data("lock") || "true",
                color: titles.css("color") || "white"
            },
            subtitle:{
                family: subtitles.data("font-family") || "Roboto",
                size: subtitles.data("font_size_default") || "16",
                sizeMobile: subtitles.data("font_size_mobile") || "16",
                sizelock: subtitles.data("lock") || "true",
                color: subtitles.css("color") || "white"
            }
        }
    })

    if (insertConfig) {
        const dataConfig = JSON.stringify({
            ...CarouselModel.config
        })
        carouselContainer.attr(CarouselModel.refs.dataAttrConfig, dataConfig)

        $('.unsaved').show()
    }
}
export default updateView