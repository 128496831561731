import { useFontSizeResponsive, useBackgroundColor, useFontSelector } from '../../../microComponents'

const FontSelector = useFontSelector({ label: 'Fonte' })

const FontSizeTitle = useFontSizeResponsive({
  customFormRef: 'font-size',
  label: 'Tamanho',
  isFirst: true,
})

const BackgroundColor = useBackgroundColor({
  label: 'Cor de Fundo',
})

const inputCards = ({ value = '', size = '3' }) => /*html*/ `
<li class="nav-item col-sm-${size} mx-0.5" style="padding: 1px">
  <a
    class="nav-link nav-item-custom d-flex justify-content-center"

    id="${value}"
    data-toggle="pill"
    href="#"
    role="tab"
    data-value="${value}"
    aria-controls=""
    aria-selected="true"
  >
    <i class="fa fa-align-${value}" data-value="${value}"></i>
  </a>
</li>
`

const Alinhamento = (type = '') => /*html*/ `
<ul class="nav nav-pills" id="text-alignment${type}" role="tablist">
  ${inputCards({
    value: 'left',
  })}
  ${inputCards({
    value: 'center',
  })}
  ${inputCards({
    value: 'right',
  })}
  ${inputCards({
    value: 'justify',
  })}
</ul>`

const FORM_CONFIG = /*html*/ `
<div class="form-settings-head">
  TIPOGRAFIA<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>

<div class="form-group" style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    ${FontSizeTitle.template}
    ${BackgroundColor.template}
    ${FontSelector.template}
  </div>
</div>

<div class="form-settings-head">
  ALINHAMENTO<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-custom" style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    <div class="col-sm-6 mt-3">
      <label>Computador</label>
      ${Alinhamento()}
    </div>
  
    <div class="col-sm-6 mt-3">
      <div class="d-flex align-items-center" style="margin-bottom: .5rem;">
        <span
        id="block-mobile"
        class="d-flex p-1 mr-1 rounded text-center justify-content-center align-items-center lock-config-icon" 
        >
          <i class="fa fa-unlock" aria-hidden="true"></i>
        </span> 
        <label class="mb-0">Celular</label> 
      </div>
      ${Alinhamento('-mobile')}
    </div>
  </div>
</div>
`

export default FORM_CONFIG
