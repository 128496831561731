import { hideMobileDesktop } from "../../helpers/form.settings";
import { componentSpacing } from "../../helpers/helpers";
import events from "./events";
import initTimeDelay from "../../helpers/timeDelay/initSettings";
import { initAnimations } from '../../helpers/animations';

/**
 * @param {jQuery} form Form contains all setting of this type and is child of `div[id="keditor-setting-forms"]`
 * @param {KEditor} keditor KEditor instance which is calling this function
 * @param {jQuery} componentTargetSpacing Component will be applied spacing setting
 */
export default function (
  form,
  keditor,
  componentTargetSpacing = ".photo-panel"
) {
  events.call(self, form, keditor);
  hideMobileDesktop(form, null, keditor, componentTargetSpacing, "edit");
  componentSpacing(componentTargetSpacing, form, null, keditor, 2);

  initTimeDelay.call(self, form, keditor);
  initAnimations(form,keditor)
}
