import {
  useBorderRadius,
  useBorderColor,
  useBoxShadow,
  useBackgroundColor,
  useBorderWidth,
  useWidth,
} from '../../../../microComponents'

// Depoimento
const BoxShadow = useBoxShadow({
  label: 'Sombra',
  customOptions: [
    {
      name: 'Sem sombra',
      value: 0,
      hex: 'none',
    },
    {
      name: 'Sombra leve',
      value: 1,
      hex: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    },
    {
      name: 'Sombra alta',
      value: 2,
      hex: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    },
  ],
})

const BackgroundColor = useBackgroundColor({
  label: 'Cor de Fundo',
})
const Width = useWidth({
  label: 'Largura',
})

const BorderRadius = useBorderRadius({
  label: 'Curvatura das Bordas',
  fullWidth: false,
  customOptions: [
    {
      name: 'Quadrada',
      value: '0px',
    },
    {
      name: '5px',
    },
    {
      name: '10px',
    },
    {
      name: '15px',
    },
    {
      name: 'Circular',
      value: '50px',
    },
  ],
})

// Imagem

const ImageBorderRadius = useBorderRadius({
  label: 'Estilo',
  customFormRef: 'image-border-radius',
  customOptions: [
    {
      name: 'Quadrada',
      value: '0px',
    },
    {
      name: 'Arredondado',
      value: '4px',
    },
    {
      name: 'Circular',
      value: '50%',
    },
  ],
})

const ImageBorderColor = useBorderColor({
  customFormRef: 'image-border-color',
  label: 'Cor da Borda',
})

const BorderWidth = useBorderWidth({
  label: 'Espessura da Borda',
})

export default /*html */ `
<div class="form-group " style="margin: 1rem 0rem">
  <div class="col-sm-6 mt-3"><b>Depoimento</b></div>
  <div class="d-flex flex-wrap">
    ${BackgroundColor.template}
    ${BoxShadow.template}
    ${BorderRadius.template}
    ${Width.maxWidthTemplate}
  </div>
  <hr class="mb-0 pb-0"/>
  <div class="col-sm-6 mt-3"><b>Imagem</b></div>
  <div 
    class="config-alert col-sm-12" 
    style="font-size: 14px; color: #999; text-align:center;margin: 12px;"
  >
    Adicione uma imagem para utilizar essa configuração
  </div>
  <div class="flex-wrap border-config" style="display: flex">
    ${ImageBorderRadius.template}
    ${BorderWidth.template}
    ${ImageBorderColor.template}
  </div>
</div>
`
