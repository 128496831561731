// import '../styles/keditor-component-form.less'
import KEditor from 'keditor'
import { componentSpacing } from './helpers/helpers'
import { hideMobileDesktop } from './helpers/form.settings'
import initTimeDelay from './helpers/timeDelay/initSettings'
import showTimeDelay from './helpers/timeDelay/showSettings'
import ListComponent from './containers/list/methods'
import {initAnimations, showAnimations} from './helpers/animations'

let listComponent = new ListComponent()
KEditor.components['list-simple-2'] = {
  emptyContent: `<p class="text-muted lead text-center"><br />[Nenhum item encontrado, favor adicionar um novo item]<br /><br /></p>`,

  settingEnabled: true,

  settingTitle: 'Lista Simples',

  init: function (contentArea, container, component, keditor) {
    listComponent.keditor = keditor
    listComponent.component = component
    listComponent.create()
  },

  initSettingForm: function (form, keditor) {
    form.html(listComponent.template)
    listComponent.form = form
    listComponent.init()

    initTimeDelay.call(self, form, keditor)
    initAnimations(form,keditor)
    hideMobileDesktop(form, null, keditor, '.list-content', 'edit')
    //Espaçamentos
    componentSpacing('.list-content', form, null, keditor, 2)
  },

  showSettingForm: function (form, component, keditor) {
    listComponent.component = component
    listComponent.show()

    showTimeDelay.call(self, form, component, keditor)
    showAnimations(form,keditor)
    hideMobileDesktop(form, component.find('.list-content'), keditor, null, '')
    // Espaçamentos
    componentSpacing(component.find('.list-content'), form, component, keditor, 1)
  },
}
