import KEditor from 'keditor'
import { templateForm } from './helpers/form.settings'

import * as template from './containers/photo/templates'

import showSettings from './containers/photo/showSettings'
import initSettings from './containers/photo/initSettings'

KEditor.components['card'] = {
  settingEnabled: true,

  settingTitle: 'Caixa de Oferta',
  init: function (contentArea, container, component, keditor) {
    let componentContent = component.children('.keditor-component-content')
    let img = componentContent.find('img')

    img.css('display', 'inline-block')
    img.css('max-width', '100%')

    img.on('click', function (e) {
      e.preventDefault()
    })
  },

  initSettingForm: function (form, keditor) {
    let self = this

    // form.html(templateForm(formLayout));
    form.html(
      templateForm({
        children: template.FORM_LAYOUT({ customConfig }),
        advancedTemplate: template.FORM_ADVANCED_LAYOUT,
      })
    )

    $("input[data-type='currency']").on({
      keyup: function () {
        _formatCurrency($(this))
      },
      blur: function () {
        _formatCurrency($(this), 'blur')
      },
    })

    // TEXTO
    textConfig(form, keditor)

    // BOTÃO
    buttonConfig(form, keditor)

    // IMAGEM
    initSettings.call(self, form, keditor, '.card')
  },

  showSettingForm: function (form, component, keditor) {
    showSettings.call(self, form, component, keditor, component.find('.card'))

    // TEXTS
    const oldValue = form.find('#old-value')
    const newValue = form.find('#new-value')
    const aVista = form.find('#a-vista')
    const newValueLabel = form.find('.label-new-value')

    const sendNow = form.find('#send-now')
    const installments = form.find('#installments')
    const groupInstallments = form.find('.group-installments')
    const showInstallments = form.find('#show-installments')

    const oldTxt = component.find('.old-value')
    const newTxt = component.find('.new-value')
    const decimalPlace = component.find('.decimal-place')

    const newInstallments = component.find('.parcelas')
    const cardTitle = component.find('.card-title')
    const fullPrice = component.find('.full-price')

    oldValue.val(oldTxt.html())
    newValue.val(`${newTxt.html()}${decimalPlace.html()}`)
    aVista.val(fullPrice.html())
    sendNow.val(cardTitle.html().trim())
    installments.val(newInstallments.html().split('x')[0])

    if (!newInstallments.is(':hidden')) {
      showInstallments.prop('checked', true)
      groupInstallments.show()
      newValueLabel.html('Valor da Parcela')
    } else {
      showInstallments.prop('checked', false)
      groupInstallments.hide()
      newValueLabel.html('Valor Final')
    }

    // BUTTONS
    const newValueButton = form.find('#new-button')
    const showSafeShopping = form.find('#safe')
    const redirectTo = form.find('#redirect-to')

    const newButtonTxt = component.find('.button')
    const link = component.find('.btn')
    const showIcons = component.find('.icons-option')

    newValueButton.val(newButtonTxt.html())
    redirectTo.val(link.attr('href'))

    if (!showIcons.is(':hidden')) {
      showSafeShopping.prop('checked', true)
    } else {
      showSafeShopping.prop('checked', false)
    }
  },
}

const customConfig = `
<div class="form-check mt-3">
  <input 
    class="form-check-input" 
    type="checkbox" 
    id="safe" 
    checked
  />
  <label 
    class="form-check-label" 
    for="safe"
    style="font-size: 14px"
  >
    Mostrar Compra Segura
  </label>
</div>

<div class="form-check mt-3">
  <input 
    class="form-check-input" 
    type="checkbox" 
    id="show-installments" 
    checked
  />
  <label 
    class="form-check-label" 
    for="show-installments"
    style="font-size: 14px"
  >
    Mostrar compra com parcela
  </label>
</div>

<label for="old-value" class="mt-3">Valor antigo</label>
<div class=" input-group input-group-sm">
  <div class="input-group-prepend">
    <span class="input-group-text">R$</span>
  </div>
  <input 
    type="text" 
    name="currency-field" 
    class="form-control" 
    id="old-value" 
    pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" 
    value="" 
    data-type="currency" 
    placeholder="R$1.000.000,00"
  />
</div>

<label for="new-value" class="label-new-value mt-3">Valor Final</label>
<div class=" input-group input-group-sm">
  <div class="input-group-prepend">
    <span class="input-group-text">R$</span>
  </div>
  <input 
    type="text" 
    name="currency-field" 
    class="form-control" 
    id="new-value" 
    pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" 
    value="" 
    data-type="currency" 
    placeholder="R$1.000.000,00" 
  />
</div>

<hr />

<div class="group-installments mt-3">
  <label for="installments">Total de partelas</label>
  <input 
    type="number" 
    class="form-control" 
    id="installments" 
    min="1" 
    step='1' 
    value="12" 
  />

  <label for="new-value" class="mt-3">Valor à vista</label>
  <div class=" input-group input-group-sm">
    <div class="input-group-prepend">
      <span class="input-group-text">R$</span>
    </div>
    <input 
      type="text" 
      name="currency-field" 
      class="form-control" 
      id="a-vista" 
      pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" 
      value="" 
      data-type="currency" 
      placeholder="R$1.000.000,00"
    />
  </div>
</div>

<hr/>

<label for="new-button" class="mt-3">Texto do botão</label>
<input type="text" class="form-control" id="new-button" />

<label for="new-button" class="mt-3">Link do botão</label>
<div class="input-group input-group-sm">
  <input 
    type="text" 
    class="form-control" 
    id="redirect-to"
    placeholder="#"
  />
</div>
`

function textConfig(form, keditor) {
  // TEXTO
  let oldValue = form.find('#old-value')
  let newValue = form.find('#new-value')
  let aVista = form.find('#a-vista')
  let sendNow = form.find('#send-now')
  let installments = form.find('#installments')
  let showInstallments = form.find('#show-installments')

  sendNow.on('keyup', function () {
    let newVal = keditor.getSettingComponent().find('.card-title')
    newVal.html(this.value)
  })

  oldValue.on('keyup', function () {
    let oldVal = keditor.getSettingComponent().find('.old-value')
    oldVal.html(this.value)
  })

  newValue.on('keyup', function () {
    const newVal = keditor.getSettingComponent().find('.new-value')
    const decimalPlace = keditor.getSettingComponent().find('.decimal-place')

    let value = this.value.split(',')

    newVal.html(value[0])
    if (value.length > 1) decimalPlace.html(`,${value[1]}`)
    else decimalPlace.html(``)
  })

  aVista.on('keyup', function () {
    let newVal = keditor.getSettingComponent().find('.full-price')
    newVal.html(this.value)
  })

  installments.on('change', function () {
    let newInstallments = keditor.getSettingComponent().find('.parcelas')

    newInstallments.html(`${this.value}x`)
  })

  showInstallments.on('change', function () {
    let newInstallments = keditor.getSettingComponent().find('.parcelas')
    let newPrice = keditor.getSettingComponent().find('.show-full-price')
    let groupInstallments = form.find('.group-installments')
    let newValueLabel = form.find('.label-new-value')

    if (showInstallments.is(':checked')) {
      newInstallments.show()
      groupInstallments.show()
      newPrice.show()
      newValueLabel.html('Valor da Parcela')
    } else {
      newValueLabel.html('Valor Final')
      newInstallments.hide()
      groupInstallments.hide()
      newPrice.hide()
    }
  })
}

function buttonConfig(form, keditor) {
  const newValueButton = form.find('#new-button')
  const showSafeShopping = form.find('#safe')
  const redirectTo = form.find('#redirect-to')

  newValueButton.on('keyup', function () {
    let newVal = keditor.getSettingComponent().find('.button')
    newVal.html(this.value)
  })

  redirectTo.on('keyup', function () {
    let link = keditor.getSettingComponent().find('.btn')
    link.attr('href', this.value.toString())
  })

  showSafeShopping.on('change', function () {
    let showIcons = keditor.getSettingComponent().find('.icons-option')
    if (showSafeShopping.is(':checked')) {
      showIcons.show()
    } else {
      showIcons.hide()
    }
  })
}

function _formatNumber(n) {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

function _formatCurrency(input, blur) {
  var input_val = input.val()

  if (input_val === '') {
    return
  }

  var original_len = input_val.length

  var caret_pos = input.prop('selectionStart')

  if (input_val.indexOf(',') >= 0) {
    var decimal_pos = input_val.indexOf(',')

    var left_side = input_val.substring(0, decimal_pos)
    var right_side = input_val.substring(decimal_pos)

    left_side = _formatNumber(left_side)

    right_side = _formatNumber(right_side)

    if (blur === 'blur') {
      right_side += '00'
    }

    right_side = right_side.substring(0, 2)

    input_val = left_side + ',' + right_side
  } else {
    input_val = _formatNumber(input_val)
    input_val = input_val

    if (blur === 'blur') {
      input_val += ',00'
    }
  }

  input.val(input_val)

  var updated_len = input_val.length
  caret_pos = updated_len - original_len + caret_pos
  input[0].setSelectionRange(caret_pos, caret_pos)
}
