import CarouselModel from '../../carouselModel'
import { ModalBase } from '../../../../keditor/modal/template/modalBase'
import BaseComponent from '../../../containers/baseComponent'
import { carouselItem, itemTextsEdit, itemImageChoose } from './ModalItem'
import {MODAL_ID} from './constants'


const editButton = /*html*/ `
<div class="d-flex flex-wrap">
  <div class="col-sm-12 my-3">
    <div 
      class="upload-button"
      id="open-modal"
      data-toggle="modal" 
      data-target="#${MODAL_ID}"
    >
      Editar Carrossel
    </div>
  </div>
</div>
`

const MODAL_BODY = /*html*/ `
    <div id="add-item" class="mb-2">
      <span id="new-item">
          Adicionar item
      </span>
    </div>
    <hr>
    <div id="form-config"></div>
    <div id="empty-content">
        Lista vazia
    </div>
`

const MODAL_FOOTER = /*html*/ `
    <button type="button" class="btn cancel" data-dismiss="modal">
        Cancelar
    </button>
    <button type="button" class="btn save save-list-modal"  data-dismiss="modal">
        Salvar
    </button>
`


class ModalEditor extends BaseComponent {
  editButton = editButton
  #main = '#form-config'
  #emptyContent = '#empty-content' 

  #method(target, action, callback) {
    $(`#${MODAL_ID}`).find('.modal-body').off(action, target)
    $(`#${MODAL_ID}`).find('.modal-body').on(action, target, callback)
  }

  #fmethod(target, action, callback) {
    $(`#${MODAL_ID}`).find('.modal-footer').off(action, target)
    $(`#${MODAL_ID}`).find('.modal-footer').on(action, target, callback)
  }

  initItemsNavEvents() {
    const self = this
    this.#method('.move-down', 'click', function (e) {
      const index = $(this).data('index')
      if (index + 1 < CarouselModel.itemsTemp.length)
        [CarouselModel.itemsTemp[index], CarouselModel.itemsTemp[index + 1]] = [
          CarouselModel.itemsTemp[index + 1],
          CarouselModel.itemsTemp[index],
        ]

      self.#render()
    })

    this.#method('.move-up', 'click', function (e) {
      const index = $(this).data('index')
      if (index > 0)
        [CarouselModel.itemsTemp[index], CarouselModel.itemsTemp[index - 1]] = [
          CarouselModel.itemsTemp[index - 1],
          CarouselModel.itemsTemp[index],
        ]

      self.#render()
    })

    this.#method('.delete', 'click', function (e) {
      const index = $(this).data('index')
      if(CarouselModel.itemsTemp.length === 1) return alert('Não é possivel ter um Carrossel sem itens!')
      if (confirm('Tem certeza que deseja excluir este item?')) {
        CarouselModel.itemsTemp = CarouselModel.itemsTemp.filter((el, i) => i !== index)
      }

      self.#render()
    })

    this.#method('#new-item', 'click', function (e) {
      CarouselModel.itemsTemp.push(
        CarouselModel.defaultItem()
      )
      self.#render()
    })
  }

  initModalEvents(onSave){

    this.#fmethod('.save-list-modal', 'click', function (e) {
      //Escrever na lista de itens
      CarouselModel.items = [...CarouselModel.itemsTemp]
      //Unsaved changes
      $('.unsaved').show()
      //updateView
      if(typeof onSave === "function" ) onSave()
    })

    $(`#${MODAL_ID}`).off('show.bs.modal')
    $(`#${MODAL_ID}`).on('show.bs.modal', () => {
      const self = this
      //Carrega a lista de itens na lista temporária
      CarouselModel.itemsTemp = [...CarouselModel.items]
      //Renderiza os itens
      self.#render()
    })

  }

  initModal(onSave) {
    const modal = $(
      ModalBase({
        id: MODAL_ID,
        title: 'Configure seu Carrossel',
        footer: MODAL_FOOTER,
        body: MODAL_BODY,
        info: '',
      })
    )

    modal.appendTo(CarouselModel.keditor.wrapper)
    this.initItemsNavEvents()
    this.initModalEvents(onSave)
  }

  #render() {
    let list = ''
    CarouselModel.itemsTemp.map((el, index) => {
      list += carouselItem(el, index)
    })

    $(`#${MODAL_ID}`).find(this.#main).html(list)
    itemTextsEdit()
    itemImageChoose()

    $(`#${MODAL_ID}`).find(this.#emptyContent).hide()
    if (!CarouselModel.itemsTemp.length) $(`#${MODAL_ID}`).find(this.#emptyContent).show()
  }

  show() {
    //this.#render()
  }

  //criar objeto temporário
  //initmodalevents (modaldismiss -> se save, escrever no model e chamar o updateView -> se não, limpar o obj temporaario)

}

const ModalEditorInstance = new ModalEditor()
export default ModalEditorInstance


/**
 * O problema de sempre salvar, mesmo que cancele o modal, vai persistir.
 * NavEditions (order, insert and deletion) precisa ficar só visual, atualizar o model só no save.
 * Assim como os eventos de edição de itens também (itemTextsEdit e itemImageChoose)
 * 
 * A menos que salve em um objeto temporário auxiliar e sobrescreva o original no save (provável melhor opção)... limpando ele sempre após o save e cancel
 */