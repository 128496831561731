export default class BaseComponent {
  constructor() {
    this._form
    this._component
    this._keditor
  }

  // SETTERS
  set keditor(newKeditor) {
    this._keditor = newKeditor
  }

  set form(newForm) {
    this._form = newForm
  }

  set component(newComponent) {
    this._component = newComponent
  }

  getRef(target) {
    return this._keditor.getSettingComponent() && this._keditor.getSettingComponent().find(target)
  }

  method(target, action, callback) {
    return this._form.find(`#${target}`).on(action, callback)
  }
}
