import openGalleryButton from '../../helpers/gallery.template.js'

const useAlignmentBlock = require('../../microComponents/AlignmentBlock.js')
const useImageWidth = require('../../microComponents/ImageWidth.js')

const AlignmentBlock = useAlignmentBlock.default({ isFirst: true })
const ImageWidth = useImageWidth.default({})

const FORM_LAYOUT = ({ customTabConfig = '', customConfig = '', isPhoto }) => {
  return /*html*/ `
<div class="form-settings-head">
  Layout<span
    ><i class="fa fa-caret-down" aria-hidden="true"></i
  ></span>
</div>
<div class="form-group row" style="margin: 1rem 0rem">
  <div id="galerybtnwrapper">
    ${openGalleryButton()}
  </div>
  <div class="tab-content col-sm-12 loading-config"></div> 
  <div class="tab-content border-config">
    <div class="mt-3 col-sm-12">
      <div>
        <label for="image-alt">Descrição da imagem (opcional)</label>
      </div>
      <input 
        type="text"
        id="image-alt"
        class="form-control" 
        value=""
        placeholder="Alt Text da imagem" 
        aria-label="Alt da imagem"
      />
    </div>
    <hr/>
    ${
      isPhoto
        ? /*html*/ `
        <div class="d-flex flex-wrap">
          ${AlignmentBlock.template}
        </div>
      `
        : /*html*/ `
      <div class="col-sm-12">
        <label for="photo-align">Alinhamento</label>
        <select id="photo-align" class="form-control">
          <option value="left">Esquerda</option>
          <option value="center">Centro</option>
          <option value="right">Direita</option>
        </select>
      </div>
      `
    }
    <div class="d-flex">
      ${ImageWidth.template}
    </div>
  </div>
  ${
    customConfig
      ? /*html*/ `
      <div class="form-group row" style="margin: 1rem 0rem">
        <div class="tab-content col-sm-12">
          ${customConfig}
        </div>
      </div>
      `
      : ''
  }
</div>

${customTabConfig}
`
}

const FORM_STYLE_LAYOUT = ``

export { FORM_LAYOUT, FORM_STYLE_LAYOUT }
