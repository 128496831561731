import QuizModel from '../../quizModel'
import { MODAL_ID, MULTIPLE_CHOICE, OPEN_TEXT } from './constants'
import openGalleryButton from '../../../helpers/gallery.template.js' //'../../helpers/gallery.template.js'

const findById = (id, arr) => arr.findIndex((element) => element.id && element.id === id)

export const multipleChoice = (item, index) => {
  const options = (list, correct) => {
    let cc = ''
    list.map((answer, optionIndex) => {
      cc += /*html*/ `    
          <div class="row">
            <div class="col-sm-8">
              <input 
                class="form-control answer-input mt-2" 
                value="${answer || ''}" 
                placeholder="Digite aqui a resposta"                 
                maxLength="26"
                data-state='{"type":"answer", "id":${item.id}, "index":${optionIndex}}'
                type="text" 
              />
            </div>
            <div class="col-sm-4 text-center">
              <input 
                class="correct-answer-radio mt-3"
                type="radio"
                name="correctanswerfor-${item.id}" 
                value="${optionIndex}"
                data-state='{"type":"correct", "id":${item.id}, "index":${optionIndex}}'
                ${correct == optionIndex ? 'checked' : ''}
              />
            </div>
          </div>
          `
    })
    return cc
  }

  return /*html*/ `
      <div class="field-group" id="${index}">
        <div class="fields-config">
          <div class="field-moves">
            <a 
              class="move-icon move-up" 
              role="button"
              data-index="${index}"
            >
              <img src="bundle/assets/icons/arrow_up.svg" />
            </a>
            <a 
              class="move-icon move-down" 
              role="button"
              data-index="${index}"
              >
              <img style="transform: rotate(180deg);" src="bundle/assets/icons/arrow_up.svg" />
            </a>
            <a 
              class="move-icon delete" 
              role="button"
              data-index="${index}"
              >
              <img src="bundle/assets/icons/trash.svg" />
            </a>
          </div>
        </div>
      
        
        <div class="fields-inputs">
          <h5>Múltipla Escolha</h5>          
          <input 
            class="form-control question" 
            placeholder="Digite aqui a pergunta" 
            type="text" 
            maxLength="60"
            data-state='{"type":"question", "id":${item.id}}'
            value="${item.question}"
          />
          <div class="row">
          <div class="col-sm-8 mt-4">
            <h5>Opções</h5>
          </div>  
          <div class="col-sm-4 mt-4">
            <h5 class="text-center">Resposta Correta</h5>
          </div> 
          </div>       
          ${options(item.answers, item.correct)}
          <div class="mt-4">
            <h5>Adicionar uma imagem (opcional)</h5>
          </div> 
          <div class="col-sm-6 p-0">
            <a href="#" style="font-size: 14px" id="clear-img-question">Remover imagem</a>
            <div class="row" >
              ${openGalleryButton(true, true, {"type":"image", "id":item.id}, item.imgsrc)}
            </div>
          </div>
        </div>
      </div>
      `
}


function findQuestion(id) {
  const index = QuizModel.questions.findIndex(el => el.id === id)
  return index !== -1 ? index : null
}

function onChangeTextEditor(event) {
  const { target } = event
  let { value } = target
  const element = $(target)
  const state = element.data('state')
  
  const questionId = findQuestion(state.id) 
  const question = questionId !== null && {...QuizModel.questions[questionId]}

  if (state.type === 'question') {
    if(question){
      question.question = value
    }
  } else {
    if(question){      
      question.answers[state.index] = value
    }
  }
  if(question) QuizModel.questions[questionId] = question
}

function onChangeCorrectAnswer(event) {
  const { target } = event
  let { value } = target
  const element = $(target)
  const state = element.data('state')
  
  if (state.type !== 'correct') return ;
  
  const questionId = findQuestion(state.id) 
  const question = questionId !== null && {...QuizModel.questions[questionId]}

  if(question) QuizModel.questions[questionId].correct = state.index
}

function onChangeImage(target, form, src) {
  console.log(form)
  console.log(src)
  const state = form.find('.change-image-button').data('state')
  
  if (state.type !== 'image') return ;

  const questionId = findQuestion(state.id) 
  const question = questionId !== null && {...QuizModel.questions[questionId]}

  if(question) QuizModel.questions[questionId].imgsrc = src
}

export function multipleChoiceTextEditor() {
  $(`#${MODAL_ID}`)
    .find('.modal-body')
    .on('keyup', '.question', (e) => onChangeTextEditor(e))

  $(`#${MODAL_ID}`)
    .find('.modal-body')
    .on('keyup', '.answer-input', (e) => onChangeTextEditor(e))

    $(`#${MODAL_ID}`)
      .find('.modal-body')
      .on('click', '.correct-answer-radio', (e) => onChangeCorrectAnswer(e))
}


export function multipleChoiceImageChoose() {
  $(`#${MODAL_ID}`)
  .find('.modal-body')
  .on('click', QuizModel.openGallery, (e) => {

    /**
     * If user click at the div.info inside div#photo-galery-2 the e.target will be it e.e'
     * The line below handle this and get the right parent (the button wrapper)
     */
    const openGalleryButtonWrapper = $(e.target).hasClass('info') ? $(e.target).parent().parent() : $(e.target).parent()
    
    QuizModel.keditor.options.locale.modalImage.form = openGalleryButtonWrapper
    QuizModel.keditor.options.locale.modalImage.isGeneric = true
    QuizModel.keditor.options.locale.modalImage.callback = onChangeImage
      
  })

  $(`#${MODAL_ID}`)
  .find('.modal-body')
  .on('click', '#clear-img-question', (e) => {

    const imgformContainer = $(e.target).parent()
    const galleryBtns = imgformContainer.find(QuizModel.openGallery)
    
    onChangeImage(null, imgformContainer, '')
    
    imgformContainer.find('.row:first').html(
      openGalleryButton(true, true, $(galleryBtns[0]).data('state'), '')
    )

  })
}
