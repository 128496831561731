import {
  useIconSelect,
  useBorderRadius,
  useBorderColor,
  useBoxShadow,
  useBorderWidth,
} from '../../../microComponents'

const IconSelect = useIconSelect({
  label: 'Icone',
})

const BoxShadow = useBoxShadow({
  label: 'Sombra',
})

const BorderRadius = useBorderRadius({
  label: 'Curvatura das Bordas',
  customOptions: [{name: 'Quadrada', value: '0px'},{ name: '3px'},{ name: '5px'},{ name: '10px'},{ name: '15px'},{ name: 'Circular', value: '50px'}]
})

const borderWidth = useBorderWidth({
  label: 'Espessura da borda',
  customOptions: [{ name: 'Nenhuma', value: '0px' }, { name: '1px' }, { name: '2px' }, { name: '3px' }, { name: '5px' }],
})

const BorderColor = useBorderColor({
  label: 'Cor da Borda',
})

export default /*html*/ `  
  <hr class="mt-0">
  <div class="d-flex flex-wrap">
    ${IconSelect.template}
    <hr class="col-12 mb-0">
    ${BorderRadius.template}
    ${BorderColor.template}
    ${BoxShadow.template}
    ${borderWidth.template}
  </div>
  <hr>
  <div class="tab-content col-sm-6 my-3">
    <label for="button-width">Largura do Botão</label>
    <select id="button-width" class="form-control">
      <option value="">auto</option>
      <option value="w-25">25%</option>
      <option value="w-50">50%</option>
      <option value="w-75">75%</option>
      <option value="w-100">100%</option>
    </select>
  </div>
`