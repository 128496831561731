import {
  useFontSizeResponsive,
  useBorderRadius,
  useBoxShadow,
  useFontStyle,
  useBorderColor,
  useBackgroundColor,
  useText,
  useFontSelector,
  useImageSelect,
  usePosition,
  useRating,
  useColor,
  useAlignment,
  useBorderWidth,
  useWidth,
  useShowElement,
  useImageWidth,
} from '../../../microComponents'

const useComponents = [useBorderRadius, useBoxShadow, useBorderColor, useImageSelect, useWidth]

const useComponentsText = [useText]

export default class TestimonialComponent {
  #cardRef = '.card-testimonial'
  #testimonialSpanRef = '.perfil-name'
  #testimonialNameRef = '.testimonial-name'
  #testimonialRef = '.text-editable'
  #panel = '.photo-panel'
  #testimonialImageRef = '.img-product'
  #rating = '.rating'
  #openGallery = '#photo-galery, #photo-galery-2'

  constructor(form, keditor, component) {
    this.form = form
    this.keditor = keditor
    this._component = component

    useComponents.map((component) => {
      const aux = component({
        form: this.form,
        keditor,
        elementRef: () => this.getRef(this.#cardRef),
      })

      this[aux.componentName] = aux
    })

    const fontSizeOptions = (customFormRef, customRef = this.#testimonialSpanRef) => ({
      customFormRef,
      form: this.form,
      keditor,
      elementRef: () => this.getRef(customRef),
    })

    useComponentsText.map((component) => {
      const aux = component({
        form: this.form,
        keditor,
        elementRef: () => this.getRef(this.#testimonialSpanRef),
      })

      this[aux.componentName] = aux
    })

    //Background
    this.backgroundColor = useBackgroundColor(fontSizeOptions(null, this.#cardRef))

    // Imagem
    this.imagePositionSelect = usePosition(fontSizeOptions('image-position', this.#cardRef))
    this.imageBorderRadius = useBorderRadius({
      ...fontSizeOptions('image-border-radius', this.#testimonialImageRef),
      customOptions: [
        {
          name: 'Quadrada',
          value: '0px',
        },
        {
          name: 'Arredondado',
          value: '4px',
        },
        {
          name: 'Circular',
          value: '50%',
        },
      ],
    })
    this.imageBorderWidth = useBorderWidth(fontSizeOptions(null, this.#testimonialImageRef))
    this.imageWidth = useImageWidth({ ...fontSizeOptions('photo-width', this.#testimonialImageRef), isMaxWidth: false })
    this.imageBorderColor = useBorderColor(fontSizeOptions('image-border-color', this.#testimonialImageRef))
    this.showImage = useShowElement(fontSizeOptions('show-img', this.#panel))

    // Classificação
    this.ratingPositionSelect = usePosition(fontSizeOptions('rating-position', this.#cardRef))
    this.rating = useRating(fontSizeOptions(null, this.#cardRef))
    this.showRating = useShowElement(fontSizeOptions(null, this.#rating))
    this.colorRating = useColor(fontSizeOptions('rating-color', this.#rating))
    this.alignmentRating = useAlignment(fontSizeOptions('rating-alignment', this.#rating))

    // Nome do Testemunho
    this.testimonialPositionSelect = usePosition(fontSizeOptions('testimonial-position', this.#cardRef))
    this.fontSizeResponsiveTitle = useFontSizeResponsive(fontSizeOptions('font-size-responsive-Title'))
    this.fontSizeResponsiveTestimonial = useFontSizeResponsive(
      fontSizeOptions('font-size-responsive-Testimonial', this.#testimonialRef)
    )
    this.colorTitle = useColor(fontSizeOptions('text-color', this.#testimonialNameRef))
    this.fontSelector = useFontSelector(fontSizeOptions(null, this.#testimonialNameRef))
    this.alignmentTitle = useAlignment(fontSizeOptions('text-alignment', this.#testimonialNameRef))
    this.fontSizeTitle = useFontSizeResponsive(fontSizeOptions('font-size-Title'))
    this.fontSizeTestimonial = useFontSizeResponsive(fontSizeOptions('font-size-Testimonial', this.#testimonialRef))
    this.fontStyle = useFontStyle(fontSizeOptions(null, this.#testimonialNameRef))
  }

  set component(newComponent) {
    this._component = newComponent
  }

  getRef(target) {
    return this.keditor.getSettingComponent() && this.keditor.getSettingComponent().find(target)
  }

  /**
   * Responsável pela edição
   */
  init() {
    // Card
    this.backgroundColor.init()
    this.borderColor.init()
    this.borderRadius.init()
    this.boxShadow.init()
    this.widthSelector.init()

    // Imagem
    this.form.on('click', this.#openGallery, (event) => {
      this.keditor.options.locale.modalImage.isContainer = false
      this.keditor.options.locale.modalImage.isIframe = false
      this.keditor.options.locale.modalImage.target = this.getRef(this.#panel)
      this.keditor.options.locale.modalImage.form = this.form
    })
    this.imagePositionSelect.init(() => this.getRef(this.#panel), 1)
    this.imageBorderRadius.init()
    this.imageBorderWidth.init()
    this.imageBorderColor.init()
    this.imageWidth.init()

    this.showImage.init((isChecked) => {
      if (isChecked) {
        this.getRef(this.#panel).removeClass('d-none')
        this.getRef(this.#panel).addClass('d-flex justify-content-center')
      }else{
        this.getRef(this.#panel).removeClass('d-flex justify-content-center')
        this.getRef(this.#panel).addClass('d-none')
      }
    })

    // Classificação
    this.rating.init(() => this.getRef(this.#rating), 3)
    this.ratingPositionSelect.init(() => this.getRef(this.#rating), 3)
    this.alignmentRating.init()
    this.colorRating.init()
    this.showRating.init()

    // Nome do Testemunho
    this.colorTitle.init()
    this.alignmentTitle.init()
    this.text.init()
    this.fontStyle.init()
    this.fontSelector.init()
    this.fontSizeTitle.init(() => this.getRef(this.#testimonialSpanRef))
    this.fontSizeTestimonial.init(() => this.getRef(this.#testimonialRef))
    this.testimonialPositionSelect.init(() => this.getRef(this.#testimonialSpanRef).parent(), 2)
  }

  /**
   * @returns Retorna as informações dos inputs
   */
  show() {
    const hasRef = () => !this.getRef(this.#cardRef)
    const imgConfig = this.form.find('.photo-config')
    const img = this.getRef(this.#testimonialImageRef)
    const demoImg = this.getRef('.demo-photo')
    if (!this._component || hasRef()) return

    // Card
    this.backgroundColor.show()
    this.borderColor.show()
    this.borderRadius.show()
    this.boxShadow.show()
    this.widthSelector.show()

    if (!demoImg.is(':visible')) {
      imgConfig.show()
      this.form.find('.change-image-button').css({ backgroundImage: `url(${img.attr('src')})` })
      this.form.find('.upload-button').hide()
      this.form.find('.change-image-button').show()
    } else {
      imgConfig.hide()
      this.form.find('.upload-button').show()
      this.form.find('.change-image-button').hide()
    }
    this.imagePositionSelect.show(() => this.getRef(this.#panel), 1)
    this.imageBorderColor.show()
    this.imageBorderRadius.show()
    this.imageBorderWidth.show()
    this.imageWidth.show()

    // Classificação
    this.showRating.show()
    this.colorRating.show()
    this.alignmentRating.show()
    this.rating.show(() => this.getRef(this.#rating), 3)
    this.ratingPositionSelect.show(() => this.getRef(this.#rating), 3)

    // Nome do Testemunho
    this.colorTitle.show()
    this.alignmentTitle.show()
    this.text.show()
    this.fontSelector.show()
    this.fontStyle.show()
    this.fontSizeTestimonial.show(() => this.getRef(this.#testimonialRef))
    this.fontSizeTitle.show(() => this.getRef(this.#testimonialSpanRef))
    this.testimonialPositionSelect.show(() => this.getRef(this.#testimonialSpanRef).parent(), 2)
  }
}
