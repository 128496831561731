/**
 * 
 * @param {number} value 
 * @param {number} base 
 */
function intToBase(value, base) {
    let decimal = Math.floor(value / base);
    return [decimal, value % base];
}

/**
 * Converte value em um Array de duas strings, sendo:
 * Na primeira posição o valor numérico da quantidade de tempo que passou para escala acima.
 * Na segunda posição o valor numérico da quantidade de tempo restante na escala atual.
 * Ex: value 70 e base 60, retorna ["01","10"] (1 "hora" e 10 "minutos")
 * Ex: value 50 e base 24, retorna ["02","02"] (2 "dias" e 2 "horas")
 * @param {string} value valor a ser convertido
 * @param {number} base valor máximo da base numérica do tempo. Geralmente 60 (segundos ou minutos) ou 24 (horas).
 * @returns {Array<string>}
 */
export function timeTransform(value, base) {
    
    let finalValue = "00";
    let upperValue = "00";

    let intValue = parseInt(value);

    if (intValue >= base) {

        [upperValue, finalValue] = intToBase(intValue, base);
        //Adiciona Zero à esquerda se necessário
        finalValue = finalValue.toString().length == 1 ? "0" + finalValue : finalValue.toString();

        if(upperValue > 0 && upperValue < 10 && value.charAt(0) != "0"){
            upperValue =  "0" + upperValue;
        }else{
            upperValue = upperValue.toString();
        }

    }else if(intValue > 9 || value.charAt(0) == "0"){

        finalValue = value;

    }else if(intValue > 0){
        
        finalValue =  "0" + value;

    }

    return [upperValue, finalValue];
}