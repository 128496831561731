export default function (form) {
  return /*html*/ `
  <div id="form-config"></div>
  <div id="empty-content">
    Lista vazia
  </div>

  <div id="add-item">
    <span id="new-item">
      Adicionar novo item
    </span>
  </div>
  `
}
