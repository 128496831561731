import KEditor from 'keditor'

import initHtmlModal from '../keditor/modal/initHtmlModal'

import { loadEditor } from '../keditor/helpers/helpers'

/**
 * Função chamada no evento de click do botão "Inserir Código" do modal.
 * Captura o código HTML do editor e atualiza o array de htmls.
 * @param {string} curComponentId
 */
function onInsertHTML(curComponentId) {
  let aceCodeEditor = loadEditor('editor-custom-html-' + curComponentId, 'html')
  let curHTMLCode = aceCodeEditor.getValue()

  //Custom HTML Max Length
  const MAXLEN = 10922;
  if (curHTMLCode.length > MAXLEN) {
    return 0;
  }


  let finded = false

  this.options.contentHTMLs.htmls.forEach((objHtml) => {
    if (objHtml.dataid == curComponentId) {
      objHtml.code = curHTMLCode
      finded = true
    }
  })

  if (!finded) {
    this.options.contentHTMLs.htmls.push({ dataid: curComponentId, code: curHTMLCode })
  }

  if (this.options.onComponentChanged instanceof Function) {
    this.options.onComponentChanged.call(this, undefined, $(curComponentId))
  }
  return -1;
}

KEditor.components['custom-html'] = {
  settingEnabled: true,

  settingTitle: 'HTML Customizável',

  init: function (contentArea, container, component, keditor) {
    //Insere a modal na DOM
    initHtmlModal.call(keditor, component.attr('id'), 'Editor de HTML', onInsertHTML)

    const curElement = component.find('.custom-html').parent()
    curElement.removeAttr('data-margin_default')
    curElement.removeAttr('data-margin_mobile')
    curElement.css('marginTop', '')

    //Insere a id do elemento no atributo data-html-id (lido pela API)
    component.find('.custom-html').attr('data-html-id', component.attr('id'))
  },

  initSettingForm: function (form, keditor) {
    form.append(`
    <div class="form-settings-head">
      Layout<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
    </div>
    <div class="form-group row" style="margin: 1rem 0rem">
      <div class="tab-content col-sm-12">
        <button type="button" class="btn btn-primary btn-design-html w-100" data-toggle="modal" data-target="">
          <i class="fa fa-code"></i> Editar HTML
        </button>
        <p style="font-size: 14px!important; line-height: 14px; margin-top:16px;color:#999">
          *O código inserido nesse elemento só será adicionado à página após salvar as alterações realizadas. Você poderá visualizar o código inserido ao visitar o link final da página.
        </p>
        <p style="margin-top:16px; font-size: 14px !important; line-height: 14px; font-style:italic; color:#c3c3c3">
          ** Duplicar elementos HTML não duplicam o seu código interno. Se deseja duplicar este elemento, por favor, copie o código interno deste elemento e cole no novo.
        </p>
      </div>
    </div>
    `)

    //Abre a Modal
    form.find('.btn-design-html').on('click', function (e) {
      let curComponentId = keditor.getSettingComponent().attr('id')

      let aceCodeEditor = loadEditor('editor-custom-html-' + curComponentId, 'html')

      //Captura o HTML do Componente
      let curHtmlCode = keditor.options.contentHTMLs.htmls
        .map((objHtml) => {
          if (objHtml.dataid == curComponentId) return objHtml.code
        })
        .join('')

      //Insere HTML Respectivo no Editor
      aceCodeEditor.setValue(curHtmlCode)

      $(`#htmlmodal-${curComponentId}`).modal('show')
    })
  },

  showSettingForm: function (form, component, keditor) {},

  /**
   * Function will be called when deleting component
   * @param {jQuery} component
   * @param {KEditor} keditor KEditor instance which is calling this function
   */
  destroy: function (component, keditor) {
    keditor.options.contentHTMLs.htmls = keditor.options.contentHTMLs.htmls.filter(function (item) {
      return !(item.dataid == component.attr('id'))
    })
  },
}
