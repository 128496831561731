import { hideMobileDesktop } from '../../helpers/form.settings'
import { componentSpacing } from '../../helpers/helpers'
import initTimeDelay from '../../helpers/timeDelay/initSettings'
import { initAnimations } from '../../helpers/animations'

export default function (form, keditor) {
  //Sets General Events
  hideMobileDesktop(form, null, keditor, '.countdown-container', 'edit')
  componentSpacing('.countdown-container', form, null, keditor, 2)
  initTimeDelay.call(self, form, keditor)
  initAnimations(form,keditor)
}
