import KEditor from 'keditor'
// import CKEDITOR from 'ckeditor'

import { templateForm, hideMobileDesktop } from './helpers/form.settings'

import {
  textOptions,
  componentSpacing,
  verifyHasClass,
} from './helpers/helpers'

import initTimeDelay from './helpers/timeDelay/initSettings'
import showTimeDelay from './helpers/timeDelay/showSettings'
import { initAnimations, showAnimations } from './helpers/animations'

import template from './containers/text/template'
import TextComponent from './containers/text/methods'

// CKEDITOR.disableAutoInline = true

let textComponent

// Text component
// ---------------------------------------------------------------------
KEditor.components['text-2'] = {
  settingEnabled: true,

  settingTitle: 'Bloco de Texto',

  options: textOptions(true, 'Font'),

  init: function (contentArea, container, component, keditor) {
    let self = this
    let options = keditor.options
    let componentContent = component.find('.text-content')
    let componentContentParent = component.find('.keditor-component-content')

    const defaultArr = [
      'default-text-center',
      'default-text-left',
      'default-text-right',
      'default-text-justify',
    ]
    const mobileArr = [
      'mobile-text-center',
      'mobile-text-left',
      'mobile-text-right',
      'mobile-text-justify',
    ]

    if (!verifyHasClass(componentContent, [...mobileArr, ...defaultArr])) {
      componentContent.addClass(
        `default-text-${component
          .find('.text-content')
          .css('textAlign')} mobile-text-${component
            .find('.text-content')
            .css('textAlign')}`
      )
      component.find('.text-content').css('textAlign', '')
    }

    if (!componentContent.attr('data-line_height_default'))
      componentContent.attr('data-line_height_default', '')
    if (!componentContent.attr('data-letter_spacing_default'))
      componentContent.attr('data-letter_spacing_default', '')

    componentContent.prop('contenteditable', true)
    component.find('.timer').prop('contenteditable', false)

    componentContent.on('input', function (e) {
      if (typeof options.onComponentChanged === 'function') {
        options.onComponentChanged.call(keditor, e, component)
      }

      if (typeof options.onContainerChanged === 'function') {
        options.onContainerChanged.call(keditor, e, container, contentArea)
      }

      if (typeof options.onContentChanged === 'function') {
        options.onContentChanged.call(keditor, e, contentArea)
      }
    })
    try {
      const CKEDITOR = keditor.iframeWindow.window.CKEDITOR
      componentContent.attr('id', componentContentParent.attr('id'))
      CKEDITOR.inline(componentContent[0], self.options)
    } catch (error) {
      console.warn(error)
    }
  },

  getContent: function (component, keditor) {
    let componentContent = component.find('.keditor-component-content')

    component.find('.text-content').removeAttr('contenteditable')
    component.find('.text-content').removeAttr('spellcheck')
    component
      .find('.text-content')
      .removeClass(
        'cke_editable cke_editable_inline cke_contents_ltr cke_show_borders'
      )

    return componentContent.html()
  },

  destroy: function (component, keditor) {
    const CKEDITOR = keditor.iframeWindow.window.CKEDITOR
    let id = component.find('.keditor-component-content').attr('id')
    CKEDITOR.instances[id] && CKEDITOR.instances[id].destroy()
  },

  initSettingForm: function (form, keditor) {
    form.append(
      templateForm({
        children: template.FORM_CONFIG,
        advancedTemplate: template.FORM_ADVANCE_CONFIG,
        isContainer: true,
      })
    )

    textComponent = new TextComponent({ form, keditor })
    textComponent.keditor = keditor
    textComponent.form = form
    textComponent.init()

    initTimeDelay.call(self, form, keditor)
    initAnimations(form, keditor)
    //Espaçamentos
    componentSpacing('.text-container', form, null, keditor, 2)
    hideMobileDesktop(form, null, keditor, '.text-container', 'edit')
  },

  showSettingForm: function (form, component, keditor) {
    textComponent.component = component
    textComponent.show()

    showTimeDelay.call(self, form, component, keditor)
    showAnimations(form, keditor)
    // Espaçamentos
    componentSpacing(
      component.find('.text-container'),
      form,
      component,
      keditor,
      1
    )

    hideMobileDesktop(
      form,
      component.find('.text-container'),
      keditor,
      null,
      'load'
    )
  },
}
