import {
  useAlignmentBlock,
  useDropdown, 
  useColor,
  useFontSizeResponsive,
  useInputNumber,
  useShowElement,
  useText,
  useInputURL,
  useSelect,
  useInputDateTime
} from '../../microComponents'

const AlignmentBlock = useAlignmentBlock({ isFirst: true })

const FontSizeNumber = useFontSizeResponsive({
  customFormRef: 'font-size-number',
  label: 'Tamanho',
  isFirst: false,
})

const FontSizeLabel = useFontSizeResponsive({
  customFormRef: 'font-size-label',
  label: 'Tamanho',
  isFirst: false,
})

const actionDropdown = useDropdown({
  label: '',
  customOptions: [
    {
      value: 'redirect',
      text: 'Ir para URL',
      icon: 'fa fa-globe',
    },
    {
      value: 'message',
      text: 'Exibir Mensagem',
      icon: 'fa fa-envelope',
    },
  ],
  customDropdownRef: 'countdownd-action-dropdown',
  customFormRef: 'countdown-action',
})

const countingTypeSelect = useDropdown({
  label: 'Tipo de contagem',
  selectDefaultLabel: 'Selecionar Tipo',
  customOptions: [
    {
      value: 'time',
      text: 'Tempo fixo',
      icon: 'fa fa-clock-o',
    },
    {
      value: 'date',
      text: 'Data',
      icon: 'fa fa-calendar',
    },
  ],
  customDropdownRef: 'countdownd-type-dropdown',
  customFormRef: 'countdown-counting-type',
})

const titlePosSelect = useSelect({
  customOptions: [
    {
      value: 'top',
      label: 'Em cima'
    },
    {
      value: 'bottom',
      label: 'Em baixo'
    },
  ],
  label:'Posição dos títulos',
  customFormRef: 'title-pos',
  fullWidth: true, 
  isFirst: false
})

const messageWhenZero = useText({
  label: 'Mensagem de conclusão',
  placeHolder:  'Digite aqui o texto que aparecerá ao zerar',
  customFormRef: 'final-text'
})

const redirectWhenZero = useInputURL({
  customFormRef: 'countdown-link'
})

const finalDate = useInputDateTime({
  label: 'Dia que o contador acabará',
  customFormRef: 'countdown-date',
  type: 'date',
  isFirst: true
})

const finalTime = useInputDateTime({
  label: 'Hora que o contador acabará',
  customFormRef: 'countdown-time',
  type: 'time',
  isFirst: true
})

const ColorNumber = useColor({
  label: 'Cor',
  customFormRef: 'color-number',
})

const ColorLabel = useColor({
  label: 'Cor',
  customFormRef: 'color-label',
})

const ShowDays = useShowElement({
  customFormRef: 'exibe-dias',
  label: 'Exibir dias',
})

const TimeDays = useInputNumber({
  customFormRef: 'time-days',
  label: 'Dias',
})

const TimeHours = useInputNumber({
  customFormRef: 'time-hours',
  label: 'Horas',
})

const TimeMinutes = useInputNumber({
  customFormRef: 'time-minutes',
  label: 'Minutos',
})

const TimeSeconds = useInputNumber({
  customFormRef: 'time-seconds',
  label: 'Segundos',
})


const countdownAction = /*html*/ `
${actionDropdown.template}
<div class="mt-3" id="redirect-config" style="display: none">
  ${redirectWhenZero.template}
</div>
<div class="mt-3" id="message-config">
  <div class="row">
    ${messageWhenZero.template}
  </div>
</div>
`

const functionConfig = /*html*/ `
<div class="form-settings-head">
  AÇÃO AO CONCLUIR<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group " style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    <div class="col-sm-12">
      ${countdownAction}
    </div>
  </div>
</div>
`

const countdownTimeConfig = /*html*/ `
  ${countingTypeSelect.template}
  <div class="mt-3" id="date-config"  style="display: none">
    <div class="row">
      ${finalDate.template}
      <hr class="col-sm-12" style="width: 100%"/>
      ${finalTime.template}
    </div>
    <p class="text-muted mt-3" style="font-size: 13px !important">
      * Hora calculada pelo horário de Brasília.<br>* A mudança dessa configuração só será possível vê-la na página final publicada. 
    </p>
  </div>
  <div class="mt-4" id="time-config">
    ${ShowDays.template}
    <hr class="col-sm-12" style="width: 100%"/>
    <label class="">Tempo do contador</label>
    <div class="d-flex flex-wrap">
      ${TimeDays.template}
      ${TimeHours.template}
      ${TimeMinutes.template}
      ${TimeSeconds.template}
    </div>
  </div>
`

const timeConfig = /*html*/ `
<div class="form-settings-head">
 CONTAGEM REGRESSIVA<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group " style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    <div class="col-sm-12">
      ${countdownTimeConfig}
    </div>
  </div>
</div>
`

const TYPOGRAPHY = /*html*/ `
<div class="form-settings-head">
  TIPOGRAFIA<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>

<div class="form-group" style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
  <h6 class="col-sm-12 mb-2" style="width: 100%">Números</h6>
  ${FontSizeNumber.template}
  ${ColorNumber.template}
  
  <hr class="col-sm-12" style="width: 100%"/>
  <h6 class="col-sm-12 mb-2" style="width: 100%">Títulos</h6>
  ${FontSizeLabel.template}
  ${ColorLabel.template}
  </div>
</div>
`

const LAYOUT = /*html*/ `
<div class="form-settings-head">
  LAYOUT<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>

<div class="form-group" style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    ${AlignmentBlock.template}
    ${titlePosSelect.template}
  </div>
</div>
`

export const FORM_LAYOUT = /*html*/ `
${timeConfig}
${functionConfig}
${LAYOUT}
${TYPOGRAPHY}
`
