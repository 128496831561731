import QuizModel from '../quizModel'
import renderItemConfig from './renderItemConfig'
import configId from './configId'

function renderConfigView() {
    if (!QuizModel.form) return

    const preview = QuizModel.getRef(QuizModel.quizContainerRef).attr('data-preview')

    const selectPages = QuizModel.form.find(QuizModel.viewQuestionRef)

    // Carrega as informações da lista de itens
    let list = ''
    let options = ''

    QuizModel.questions.length &&
        QuizModel.questions.map((el, index) => {
            const _obj = {
                id: configId(index),
                config: { ...el },
            }
            // options += /*html*/ `<option id="${index}" value="${index}">${el.question}</option>`
            options += /*html*/ `<option id="${index}" value="${index}">Pergunta ${index + 1}</option>`
            list += renderItemConfig(_obj)
        })

    selectPages[0].innerHTML = options

    selectPages.val(preview)
}
export default renderConfigView
