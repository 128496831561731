import { fontSizeOptions } from '../../helpers/form.settings';
import { colorConfig } from '../../helpers/configList';

export const FORM_LAYOUT = `
<div class="form-settings-head">
  Layout<span
    ><i class="fa fa-caret-down" aria-hidden="true"></i
  ></span>
</div>
<div class="form-group row" style="margin: 1rem 0rem">
  <div class="tab-content col-sm-12">
    <div class="list-config">
      ${colorConfig}
      
      <label for="icon-size" >Tamanho dos Icones</label>
      <select id="icon-size" class="form-control">
        <option value="fz-lg">Muito Pequeno</option>
        <option value="fz-2x">Pequeno</option>
        <option value="fz-3x">Médio</option>
        <option value="fz-4x">Grande</option>
      </select>
      <div class="tab-content mt-3">
        <label>Tamanho do Texto</label>
        <div class="row">
          <div class="col-sm-9" style="display: flex; margin-right: 0px !important;">
            <input  id="btn-font-size" type="range" style="width: 100%" max="72" min="10" />
          </div>
          <div class="col-sm-3">
            <div class="input-group input-group-sm">
              <input type="text" id="btn-font" class="form-control" value="00" readonly />
            </div>
          </div>
        </div>
      </div>
      <hr />
      
      <label for="item-spacing">Espaçamento entre os itens</label>
      <input
        type="number"
        class="form-control"
        id="item-spacing"
        min="0"
      />
      
      <hr />
    </div>
    <button 
      class="mt-2 btn btn-primary w-100"
      id="plus-item"
    >
      Adicionar item
    </button>
    <hr />
    <div id="item-group" ></div>
    
  </div>
</div>
<div class="form-settings-head">
  Responsivo<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
  <div class="form-group row" style="margin: 1rem 0rem">
    <div class="col-sm-12">
      <label for="input-color">Tamanho da Fonte no Celular</label>
      <select class="form-control mobile-font-size">
          ${fontSizeOptions(20)}
      </select>
    </div>
  </div>
`;
