import KEditor from 'keditor'
import IconComponent from './containers/icon'
import { hideMobileDesktop } from './helpers/form.settings'
import { componentSpacing } from './helpers/helpers'
import initTimeDelay from './helpers/timeDelay/initSettings'
import showTimeDelay from './helpers/timeDelay/showSettings'
import {initAnimations, showAnimations} from './helpers/animations'

let iconComponent = new IconComponent()
KEditor.components['icon'] = {
  settingEnabled: true,

  settingTitle: 'Icone',

  init: function (contentArea, container, component, keditor) {
    iconComponent.keditor = keditor
    iconComponent.component = component
    iconComponent.create()

    component.find('.anchor').on('click', function (e) {
      e.preventDefault()
    })
  },

  initSettingForm: function (form, keditor) {
    form.html(iconComponent.template)
    iconComponent.keditor = keditor
    iconComponent.form = form
    iconComponent.init()

    componentSpacing('.icon-wrapper', form, null, keditor, 2)
    initTimeDelay.call(self, form, keditor)
    initAnimations(form,keditor)
    hideMobileDesktop(form, null, keditor, '.icon', 'edit')
  },

  showSettingForm: function (form, component, keditor) {
    iconComponent.component = component
    iconComponent.show()
    componentSpacing(component.find('.icon-wrapper'), form, component, keditor, 1)
    showTimeDelay.call(self, form, component, keditor)
    showAnimations(form,keditor)
    hideMobileDesktop(form, component.find('.icon'), keditor, null, '')
  },
}
