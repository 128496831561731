import { useFontSelector, useFontSizeResponsive, useColor, useDropdown } from '../../microComponents'
import ColorPicker from '../../helpers/colorPicker'
import { templateForm } from '../../helpers/form.settings'
import * as templates from './templates'
import { DEFAULT_ACTION_SELECT } from '../../../constants'

const colorPicker = new ColorPicker()

const useComponents = [
  { config: useFontSelector },
  { config: useFontSizeResponsive, customFormRef: 'font-size-text', customComponentName: 'fontSizeTitle' },
  { config: useFontSizeResponsive, customFormRef: 'font-size-answers', customComponentName: 'fontSizeAnswers' },
  { config: useColor },
  {
    config: useDropdown,
    customFormRef: 'quiz-action',
    customOptions: [
      {
        value: 'redirect',
        text: 'Ir para URL',
        icon: 'fa fa-globe',
      },
      {
        value: 'message',
        text: 'Exibir Mensagem',
        icon: 'fa fa-envelope',
      },
    ],
  },
]

export default class QuizComponent {
  #elements = {
    addItem: {
      selector: '#add-item',
      data: null,
    },
    selectView: {
      selector: '#select-view',
      data: null,
    },
    quizHrefType: {
      selector: '#quiz-target-type',
      data: null,
    },
    quizLink: {
      selector: '#quiz-link',
      data: null,
    },
    quizAction: {
      selector: '#quiz-action',
      data: null,
    },
    inputMessage: {
      selector: '#message-text',
      data: null,
    },
    showCorrect: {
      selector: '#show-correct',
      data: null,
    },
    openNewTab: {
      selector: '#open-new-tab',
      data: null,
    },
  }

  #questions = [
    {
      question: 'Escreva aqui a pergunta',
      correct: 0,
      answers: ['Aqui vai a resposta', 'Aqui vai a resposta', 'Aqui vai a resposta'],
    },
  ]
  #redirectTo = ''
  #showMessage = true
  #showCorrect = true
  #openNewTab = false

  #href = 'https://'

  #titleRef = '#quiz-title'
  #quiz = '#quiz'
  #answersRef = '.answers'
  #viewQuestionRef = '#select-view'
  #quizContainerRef = '#quiz-container'
  #redirectConfigRef = '#redirect-config'
  #messageConfigRef = '#message-config'
  #message = '#message'

  configId = (index) => `config-${index}`

  constructor() {
    this._form // = form
    this._keditor //= keditor
    this._component // = component
  }

  // SETTERS
  set component(newComponent) {
    this._component = newComponent
  }

  set keditor(keditor) {
    this._keditor = keditor
  }

  set form(form) {
    this._form = form

    Object.keys(this.#elements).map((key) => {
      this.#elements[key].data = this._form.find(this.#elements[key].selector)
    })

    useComponents.map((component) => {
      const aux = component.config({
        customFormRef: component.customFormRef,
        form: this._form,
        keditor: this._keditor,
        elementRef: () => this.getRef(this.#quiz),
        customComponentName: component.customComponentName,
        customOptions: component.customOptions,
      })

      this[aux.componentName] = aux
    })
  }

  //GETTERS
  get template() {
    return $(templateForm({ children: templates.FORM_LAYOUT }))
  }

  getQuestion(index) {
    return this.#questions[index] || {}
  }

  // METHODS
  getRef(target) {
    return this._keditor.getSettingComponent() && this._keditor.getSettingComponent().find(target)
  }

  create() {
    if (!this._component && !this._keditor) return
    const quizContainer = this._component.find(this.#quizContainerRef)
    const data = JSON.parse(quizContainer.attr('data-quiz'))

    this.#questions = data.quiz

    this.changeView(quizContainer.attr('data-preview'), false)
  }

  init() {
    if (!this._form) return
    const self = this

    this.fontSizeTitle.init(() => this.getRef(this.#titleRef))
    this.fontSizeAnswers.init(() => this.getRef(this.#answersRef))
    this.fontSelector.init()

    this.#elements.addItem.data.on('click', (e) => {
      const question = {
        question: 'Escreva aqui a pergunta',
        correct: 0,
        answers: [
          'Escreva a resposta aqui',
          'Escreva a resposta aqui',
          'Escreva a resposta aqui',
          'Escreva a resposta aqui',
        ],
      }
      this.#questions.push(question)

      this.renderConfigView()
      // this.item()
      this.save()
    })

    this.#elements.selectView.data.on('change', (e) => {
      this.getRef(this.#quizContainerRef).attr('data-preview', e.target.value)
      this.changeView(parseInt(e.target.value))
    })

    this.dropdown.init(this.handleAction({ form: this._form, options: this.dropdown.options }))

    this.#elements.showCorrect.data.on('click', (e) => {
      const isChecked = $(e.target).prop('checked')
      this.#showCorrect = isChecked
      this.save()
    })

    this.#elements.openNewTab.data.on('click', (e) => {
      const isChecked = $(e.target).prop('checked')
      this.#openNewTab = isChecked
      this.save()
    })

    this.#elements.quizHrefType.data.on('click', '.dropdown-item', (e) => {
      const value = $(e.target).attr('data-value')
      this._form.find('#quiz-target-label')[0].innerHTML = value

      this.#href = value

      const valueValidation = this.validationHref(this.#redirectTo)

      this.#redirectTo = this.#redirectTo.replace(valueValidation, value)

      this.save()
    })

    this.#elements.inputMessage.data.on('keyup', function (e) {
      const { value } = e.target
      self.getRef(self.#message)[0].innerHTML = value
    })

    this.#elements.quizLink.data.on('keyup', function (e) {
      const { value } = e.target
      const valueValidation = self.validationHref(value)
      const newLink = valueValidation ? value.replace(valueValidation, self.#href) : self.#href + value

      if (valueValidation) {
        self._form.find('#quiz-target-label')[0].innerHTML = valueValidation
        self.#href = valueValidation
      }
      self.#redirectTo = newLink
      self.save()
    })

    this.item()
  }

  show() {
    if (!this._component || !this._form) return

    this.fontSizeTitle.show(() => this.getRef(this.#titleRef))
    this.fontSizeAnswers.show(() => this.getRef(this.#answersRef))
    this.fontSelector.show()

    this.#elements.inputMessage.data.val(this.getRef(this.#message)[0].innerHTML)

    const quizContainer = this.getRef(this.#quizContainerRef)
    const data = JSON.parse(quizContainer.attr('data-quiz'))
    const valueValidation = this.validationHref(data.redirectTo)
    this.#href = valueValidation
    this.#redirectTo = data.redirectTo
    this.#questions = data.quiz
    this.#showMessage = data.showMessage
    this.#showCorrect = data.showCorrect
    this.#openNewTab = data.openNewTab

    this.#elements.quizLink.data.val(data.redirectTo)

    this.#elements.openNewTab.data.prop('checked', data.openNewTab)
    this.#elements.showCorrect.data.prop('checked', data.showCorrect)

    // this.#elements.quizAction.data.val(!data.showMessage ? 'redirect' : 'message')

    this.dropdown.show(this.getFunctionalityValue({ form: this._form, data, options: this.dropdown.options }))

    // =============
    this.renderConfigView()
    this.changeView(quizContainer.attr('data-preview'), false)
    this.buttons()
  }

  // RENDER
  renderItemConfig({ id, config }) {
    const dropdownGroup = /*html*/ `
    <p class="btn-group w-100 collapse-group" role="group">
      <a 
        class="btn btn-light col-sm-9 overflow-hidden text-truncate collapse-title" 
        data-toggle="collapse" 
        href="#${id}" 
        role="button"
        data-target="#${id}" 
        aria-expanded="true" 
        aria-controls="${id}"
        style="border: 1px solid #ccc; color: #222"
      >
        ${config.question}
      </a>
      <a 
        class="btn btn-light col-sm-1 move-down px-0" 
        role="button"
        data-move-id="${id}"
        style="border: 1px solid #ccc"
      >
        <i style="cursor: pointer;" class="fa fa-arrow-down"></i>
      </a>
      <a 
        class="btn btn-light col-sm-1 move-up px-0" 
        role="button" 
        data-move-id="${id}"
        style="border: 1px solid #ccc"
      >
        <i style="cursor: pointer;" class="fa fa-arrow-up"></i>
      </a>
      <a 
        class="btn btn-danger col-sm-1 px-0 delete" 
        role="button"
        data-delete-id="${id}"
      >
        <i style="cursor: pointer; color: #fff" class="fa fa-trash-o"></i>
      </a>
    </p>
    `

    let answers = ''
    let options = ''
    const abcd = 'ABCD'
    for (let i = 0; i < 4; i++) {
      answers +=
        /*html*/
        `
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text py-0">${abcd[i]}</span>
          </div>
          <input 
            class="form-control answer-input" 
            value="${config.answers[i] || ''}" 
            placeholder="Digite aqui a resposta" 
            id="${id}-answer-${i}"
            maxLength="26"
            data-question-id="${id}"
            data-answer-index="${i}"
            type="text" 
          />
        </div>
      `
      const isChecked = config.correct == i
      options += /*html*/ `
        <option ${isChecked ? 'selected' : ''} value="${i}">
          ${abcd[i]}
        </option>
      `
    }

    return /*html*/ `
    <div class="my-3" data-config="${id}">
      ${dropdownGroup}
      <div class="collapse" id="${id}">
        <div class="card p-3">
          <label>Pergunta</label>
          <input 
            class="form-control question" 
            placeholder="Digite aqui a pergunta" 
            type="text" 
            maxLength="60"
            data-question-id="${id}"
            value="${config.question}"
          />
          <div class="invalid-feedback">
            É obrigatório ter uma pergunta
          </div>
          <hr />
          <label> Respostas </label>
          <div 
            class="text-danger" 
            id="erro-${id.replace('config-', '')}" 
            style="display: none"
          >É obrigatório ter pelo menos uma resposta</div>
          ${answers}
          <label class="mt-3"> Resposta correta </label>
          <select 
            class="form-control correct-select"
            data-question-id="${id}"
          >
            ${options}
          </select>
        </div>
      </div>
    </div>
    `
  }

  renderView({ el }) {
    let answers = ''

    el.answers.map((answer, index) => {
      if (answer.length)
        answers += /*html*/ `<li class="answer ${
          el.correct === index ? 'correct' : ''
        }" id="answer-${index}">${answer}</li>`
    })
    return /*html*/ `
      <div
        class="quiz-title"
        id="quiz-title"
        data-font_size_default="${el.default.question}"
        data-font_size_mobile="${el.mobile.question}"
        data-lock="${el.lock}"
        style="font-size: ${el.default.question}px; font-weight: bold; padding: 15px 5px;"
      >
        ${el.question || 'Pergunta'}
      </div>

      <ul
        class="answers"
        id="answers"
        data-font_size_default="${el.default.answer}"
        data-font_size_mobile="${el.mobile.answer}"
        data-lock="${el.lock}"
        style="font-weight: bold; font-size: ${el.default.answer}px"
      > 
      ${answers}
      </ul>
    `
  }

  renderConfigView() {
    if (!this._form) return

    const preview = this.getRef(this.#quizContainerRef).attr('data-preview')

    const listItems = this._form.find('#list-items')
    const selectPages = this._form.find(this.#viewQuestionRef)

    // Carrega as informações da lista de itens
    let list = ''
    let options = ''

    this.#questions.length &&
      this.#questions.map((el, index) => {
        const _obj = {
          id: this.configId(index),
          config: { ...el },
        }
        // options += /*html*/ `<option id="${index}" value="${index}">${el.question}</option>`
        options += /*html*/ `<option id="${index}" value="${index}">Pergunta ${index + 1}</option>`
        list += this.renderItemConfig(_obj)
      })

    listItems[0].innerHTML = list
    selectPages[0].innerHTML = options

    selectPages.val(preview)

    listItems.find('.delete').attr('disabled', false)
    if (this.#questions.length == 1) listItems.find('.delete').attr('disabled', true)
  }

  changeView(value, isChanging = true) {
    this._component.find('#quiz')[0].innerHTML = this.renderView({
      el: {
        ...this.#questions[value],
        mobile: {
          question: this._component.find(this.#titleRef).data('font_size_mobile'),
          answer: this._component.find(this.#answersRef).data('font_size_mobile'),
        },
        default: {
          question: this._component.find(this.#titleRef).data('font_size_default'),
          answer: this._component.find(this.#answersRef).data('font_size_default'),
        },
        lock: this._component.find(this.#answersRef).data('lock'),
      },
    })

    if (isChanging) {
      this.save()
    }
  }

  // HANDLERS
  handleInputChange(event) {
    const { target } = event
    let { value } = target
    const element = $(target)
    const elId = element.data('question-id')
    const id = parseInt(elId.replace('config-', ''))

    value = parseInt(value)
    const component = this.getQuestion(id)

    component['correct'] = value

    //====================== VIEW
    if (parseInt(this.#elements.selectView.data.val()) === id) this.changeView(id)

    this.save()
  }

  handleChangeQuestion(event) {
    const { target } = event
    let { value } = target
    const element = $(target)
    const elId = element.data('question-id')
    const id = parseInt(elId.replace('config-', ''))

    const config = this._form.find(`#${elId}`)
    const component = this.getQuestion(id)
    //====================== CONFIG

    config.find('.question').removeClass('is-invalid')
    if (!value.length) config.find('.question').addClass('is-invalid')

    const title = this._form.find(`[data-config="${element.data('question-id')}"]`).find('.collapse-title')
    title[0].innerHTML = value || 'Pergunta ' + (id + 1)

    component['question'] = value || 'Pergunta ' + (id + 1)
    //====================== VIEW

    if (parseInt(this.#elements.selectView.data.val()) === id) {
      this.changeView(id)
    }

    this.save()
  }

  handleChangeAnswer(event) {
    const { target } = event
    let { value } = target
    const element = $(target)
    const answerIndex = parseInt(element.data('answer-index'))
    const elId = element.data('question-id')
    const id = parseInt(elId.replace('config-', ''))

    const config = this._form.find(`#${elId}`)
    const component = this.getQuestion(id)
    const answersLength = component['answers'].filter((el) => el.length).length

    const selectView = this._form.find(this.#viewQuestionRef)
    //====================== CONFIG

    this._form.find(`#erro-${elId}`).hide()
    config.find('.answer-input').removeClass('is-invalid')
    if (answersLength == 1) {
      if (!value.length) {
        this._form.find(`#erro-${elId}`).show()
        config.find('.answer-input').toggleClass('is-invalid')
      }
    }

    component['answers'][answerIndex] = value

    //====================== VIEW
    if (parseInt(this.#elements.selectView.data.val()) === id) {
      this.changeView(id)
    }

    this.save()
  }

  handleAction({ form, options }) {
    const self = this
    form.find(this.#elements.quizAction.selector).on('click', '.dropdown-item', function (event) {
      const { target } = event
      const value = $(target).attr('value')

      const findOption = options.find((el) => el.value == value)

      form.find('#dropdown-set-functionality')[0].innerHTML = findOption ? findOption.text : DEFAULT_ACTION_SELECT

      self.#showMessage = value == 'message'

      form.find(self.#redirectConfigRef).show()
      form.find(self.#messageConfigRef).hide()
      if (value == 'message') {
        form.find(self.#redirectConfigRef).hide()
        form.find(self.#messageConfigRef).show()
      }
      self.save()
    })
  }

  item() {
    this._form.find('#list-items').on('keyup', '.question', (e) => this.handleChangeQuestion(e))
    this._form.find('#list-items').on('keyup', '.answer-input', (e) => this.handleChangeAnswer(e))
    this._form.find('#list-items').on('change', '.correct-select', (e) => this.handleInputChange(e))
  }

  getFunctionalityValue(e) {
    if (!e) return

    const { form, data, options } = e
    const value = data.showMessage ? 'message' : 'redirect'

    const findOption = options.find((el) => el.value == value)
    form.find('#dropdown-set-functionality').html(findOption ? findOption.text : DEFAULT_ACTION_SELECT)

    form.find(this.#redirectConfigRef).show()
    form.find(this.#messageConfigRef).hide()
    if (data.showMessage) {
      form.find(this.#redirectConfigRef).hide()
      form.find(this.#messageConfigRef).show()
    }
  }

  validationHref(value) {
    if (!value) return null
    const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)/
    let valueMatch = value.match(regex)

    if (valueMatch && valueMatch[0]) {
      const valTarget = valueMatch[0].indexOf('www.') != -1 ? valueMatch[0].split('www.')[0] : valueMatch[0]

      let label = '#'
      if (valTarget != '#' && valTarget.length) label = valTarget
      else if (!valTarget.length) label = 'https://'

      return label
    }
    return null
  }

  buttonTarget(type) {
    switch (type) {
      case 'http://':
        return 'http://'
      default:
        return 'https://'
    }
  }

  save() {
    $('.unsaved').show()
    const container = this.getRef(this.#quizContainerRef)

    const data = JSON.stringify({
      redirectTo: this.#redirectTo,
      showMessage: this.#showMessage,
      showCorrect: this.#showCorrect,
      openNewTab: this.#openNewTab,
      quiz: this.#questions,
    })
    container.attr('data-quiz', data)
  }

  // FORM CARDS
  buttons() {
    if (!this._form) return
    const self = this

    this._form.find('#list-items').off('click', '.move-down')
    this._form.find('#list-items').off('click', '.move-up')
    this._form.find('#list-items').off('click', '.delete')

    this._form.find('#list-items').on('click', '.move-down', function (e) {
      const id = parseInt($(this).data('move-id').replace('config-', ''))
      const currentItemConfig = self._form.find('#list-items').find(`[data-config='${self.configId(id)}']`)
      if (self.#questions.length != id + 1)
        [self.#questions[id], self.#questions[id + 1]] = [self.#questions[id + 1], self.#questions[id]]

      currentItemConfig.before(currentItemConfig.next())

      self.renderConfigView()
      self.changeView(parseInt(self.#elements.selectView.data.val()))
    })

    this._form.find('#list-items').on('click', '.move-up', function (e) {
      const id = parseInt($(this).data('move-id').replace('config-', ''))
      const currentItemConfig = self._form.find('#list-items').find(`[data-config='${self.configId(id)}']`)
      if (id > 0) [self.#questions[id], self.#questions[id - 1]] = [self.#questions[id - 1], self.#questions[id]]

      currentItemConfig.after(currentItemConfig.prev())

      self.renderConfigView()

      self.changeView(parseInt(self.#elements.selectView.data.val()))
    })

    this._form.find('#list-items').on('click', '.delete', function (e) {
      const id = parseInt($(this).data('delete-id').replace('config-', ''))
      const currentItemConfig = self._form.find('#list-items').find(`[data-config='${self.configId(id)}']`)
      const preview = parseInt(self.getRef(self.#quizContainerRef).attr('data-preview'))

      if (self.#questions.length == 1) return

      if (!confirm('Tem certeza?')) return

      let newArr = self.#questions.filter((el, i) => {
        return i != id
      })

      currentItemConfig.remove()
      self.#questions = newArr

      let val = preview

      if (preview == newArr.length) {
        val = preview - 1
      }

      self.changeView(val)
      self.getRef(self.#quizContainerRef).attr('data-preview', val)

      self.renderConfigView()
    })
  }
}
