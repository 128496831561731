import KEditor from 'keditor'
import { templateForm, fontSizeOptions } from './helpers/form.settings'
import {
    textOptions,
    textEditor,
    mobileFontSize,
} from './helpers/helpers'

import showSettings from './containers/photo/showSettings'
import initSettings from './containers/photo/initSettings'

import * as template from './containers/photo/templates'

let CKEDITOR;
KEditor.components['photo-text'] = {
    settingEnabled: true,

    settingTitle: 'Texto & Imagem',

    options: textOptions(),

    init: function (contentArea, container, component, keditor) {
        let componentContent = component.children('.keditor-component-content')
        let img = componentContent.find('img')

        img.css('display', 'inline-block')
        img.css('max-width', '100%')

        img.on('click', function (e) {
            e.preventDefault()
        })

        textEditor(
            `.keditor-component-content`,
            component,
            keditor,
            keditor.options,
            contentArea,
            container,
            '.photo-panel'
        )
        CKEDITOR = keditor.iframeWindow.window.CKEDITOR
    },

    getContent: function (component, keditor) {
        let componentContent = component.find('.keditor-component-content')
        let id = componentContent.attr('id')
        let editor = CKEDITOR.instances[id]
        if (editor) {
            return editor.getData()
        } else {
            return componentContent.html()
        }
    },

    destroy: function (component, keditor) {
        let id = component.find('.keditor-component-content').attr('id')
        CKEDITOR.instances[id] && CKEDITOR.instances[id].destroy()
    },

    initSettingForm: function (form, keditor) {
        form.html(
            templateForm({
                children: template.FORM_LAYOUT(`<div class="form-settings-head">
    Responsivo<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
    </div>
    
    <div class="form-group row" style="margin: 1rem 0rem">
      <div class="col-sm-12">
        <label for="input-color">Tamanho da Fonte no Celular</label>
        <select class="form-control mobile-font-size">
            ${fontSizeOptions(20)}
        </select>
      </div>
    </div>`),
                advancedTemplate: template.FORM_ADVANCED_LAYOUT,
            })
        )
        initSettings.call(self, form, keditor, '.photo-text')
    },

    showSettingForm: function (form, component, keditor) {
        // showTimeDelay.call(self, form, component, keditor);
        const componentContent = component
            .find('.keditor-component-content')
            .children()
            .children()

        mobileFontSize(
            componentContent.find('span'),
            20,
            form,
            component,
            keditor,
            1
        )

        showSettings.call(
            self,
            form,
            component,
            keditor,
            component.find('.photo-text')
        )
    },
}
