import KEditor from 'keditor'
import { hideMobileDesktop, templateForm } from './helpers/form.settings'

import initTimeDelay from './helpers/timeDelay/initSettings'
import showTimeDelay from './helpers/timeDelay/showSettings'

import {initAnimations, showAnimations} from './helpers/animations'

import ButtonComponent from './containers/button/methods'
import * as templates from './containers/button/templates'

let buttonComponent

KEditor.components['button'] = {
  settingEnabled: true,

  settingTitle: 'Botão',

  init: function (contentArea, container, component, keditor) {
    const componentContent = component.children('.keditor-component-content')
    const buttonWrapper = componentContent.find('.button-wrapper')
    buttonWrapper.find('.button-style').prop('onclick', null).off('click')

    const sendParams =
      buttonWrapper.find('.button-style').attr('data-send-params') &&
      JSON.parse(buttonWrapper.find('.button-style').attr('data-send-params'))

    if (buttonWrapper.find('.button-style').attr('data-config-type') !== 'submit-form')
      if (!sendParams) buttonWrapper.find('.button-style').removeAttr('onClick')

    buttonWrapper.find('.button-style').on('click', function (e) {
      e.preventDefault()
    })
  },

  initSettingForm: function (form, keditor) {
    form.append(
      templateForm({
        children: templates.FORM_LAYOUT,
        advancedTemplate: templates.ADVANCED_TAB,
        newTabs: templates.TABS,
        isContainer: true,
      })
    )

    initTimeDelay.call(self, form, keditor)
    initAnimations(form,keditor)
    hideMobileDesktop(form, null, keditor, '.button-style', 'edit')

    buttonComponent = new ButtonComponent(form, keditor)
    buttonComponent.init()
  },

  showSettingForm: function (form, component, keditor) {
    showTimeDelay.call(self, form, component, keditor)
    showAnimations(form,keditor)
    hideMobileDesktop(form, component.find('.button-style'), keditor, null, '')

    buttonComponent.component = component
    buttonComponent.show()
  },
}
