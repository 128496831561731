import KEditor from "keditor";
import {
  componentSpacing,
  textOptions,
  textEditor,
  mobileFontSize,
} from "./helpers/helpers";
import { newSimpleItem } from "./helpers/configList";
import {
  templateForm,
  hideMobileDesktop,
} from "./helpers/form.settings";

import initTimeDelay from  "./helpers/timeDelay/initSettings";
import showTimeDelay from  "./helpers/timeDelay/showSettings";
import {initAnimations, showAnimations} from './helpers/animations'

let itemId = 0; // controle interno do ID do item

let CKEDITOR;
KEditor.components["faq"] = {
  settingEnabled: true,

  settingTitle: "FAQ",

  emptyContent:
    '<p class="text-muted lead text-center" id="item-0"><br />[Nenhuma pergunta encontrada]<br /><br /></p>',

  options: textOptions(),

  init: function (contentArea, container, component, keditor) {
    const self = this;
    const options = self.options;

    let componentContent = component.find(".keditor-component-content");
    let listContent = component.find(".FAQ");
    if (listContent.length === 0) {
      componentContent.append(
        `<div class="col-sm-12 FAQ">${self.emptyContent}</div>`
      );
    } else {
      let listLength = listContent.children().last().attr("class").split(" ");
      listLength = listLength[listLength.length - 1];
      const lastItem = listLength[listLength.length - 1]; // pega o ultimo item da lista

      itemId = listContent.find("p") === self.emptyContent ? 0 : lastItem;
    }

    textEditor(
      `.keditor-component-content`,
      component,
      keditor,
      options,
      contentArea,
      container,
      "summary"
    );
    CKEDITOR = keditor.iframeWindow.window.CKEDITOR
  },

  getContent: function (component, keditor) {
    let componentContent = component.find(".keditor-component-content");
    let id = componentContent.attr("id");
    let editor = CKEDITOR.instances[id];
    if (editor) {
      return editor.getData();
    } else {
      return componentContent.html();
    }
  },

  destroy: function (component, keditor) {
    let id = component.children(".keditor-component-content").attr("id");
    CKEDITOR.instances[id] && CKEDITOR.instances[id].destroy();
  },

  initSettingForm: function (form, keditor) {
    const self = this;
    const options = self.options;

    form.append(templateForm({children: formLayout}));

    removeQuestion(form, keditor, itemId, self.emptyContent);
    questionConfig(form, keditor, itemId);

    //Adicionar nova pergunta
    const itemGroup = form.find("#item-group");
    form.find("#plus-item").on("click", function () {
      itemId++;
      const component = keditor.getSettingComponent();
      const contentArea = component.find(`.keditor-content-area`);
      const container = component.parent();

      const list = component.find(`.FAQ`);
      const item = `
      <article class="article-${itemId}">
        <details class="question p-4 border-b border-grey">
          <summary class="flex items-center">
            <h3 class="summary-${itemId}">A pergunta vem aqui</h3>
            <button class="ml-auto">
              <svg
                class="fill-current opacity-75 w-4 h-4 -mr-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"
                />
              </svg>
            </button>
          </summary>

          <p class="mt-4 leading-normal text-${itemId}">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fuga
            perspiciatis quidem sunt animi. Veniam accusamus illum, iste,
            hic aperiam ratione nemo, doloremque aliquid ipsum magnam
            dolorum cumque ducimus! Nobis, officia.
          </p>
        </details>
      </article>
      `;

      list.find("p").html() === $(self.emptyContent).html()
        ? list.html(item)
        : list.append(item);
      itemGroup.append(newSimpleItem(itemId));
      removeQuestion(form, keditor, itemId, self.emptyContent);
      questionConfig(form, keditor, itemId);

      // Campo Pergunta já preenchido
      form
        .find(`#item-input-${itemId}`)
        .val(component.find(`.summary-${itemId}`).html());

      textEditor(
        `.keditor-component-content`,
        component,
        keditor,
        options,
        contentArea,
        container,
        "summary"
      );
    });

    //Espaçamentos
    componentSpacing(".perguntas", form, null, keditor, 2);

    hideMobileDesktop(form, null, keditor, ".perguntas", "edit");

    mobileFontSize(".keditor-component-content", 20, form, null, keditor, 2);
  },

  showSettingForm: function (form, component, keditor) {
    // collapseConfig();
    const self = this;
    const itemGroup = form.find("#item-group").html("");
    const listContent = component.find(".FAQ");
    // lista de itens
    let listLength = listContent.children().last().attr("class").split(" ");
    listLength = listLength[listLength.length - 1];
    const lastItem = listLength[listLength.length - 1]; // pega o ultimo item da lista
    // faz o append dos itens
    itemId = listContent.find("p") === this.emptyContent ? 0 : lastItem;
    for (let j = 1; j <= lastItem; j++) {
      if (component.find(`.article-${j}`).length !== 0) {
        itemGroup.append(newSimpleItem(j));
        removeQuestion(form, keditor, j, self.emptyContent);
        questionConfig(form, keditor, j);
        // Campo Pergunta
        form
          .find(`#item-input-${j}`)
          .val(component.find(`.summary-${j}`).html());
      }
    }
    // Espaçamentos
    componentSpacing(component.find(".perguntas"), form, component, keditor, 1);

    hideMobileDesktop(form, component.find(".perguntas"), keditor, null, "");

    mobileFontSize(listContent.find("span"), 20, form, component, keditor, 1);
  },
};

const formLayout = `
<div class="form-settings-head">
  Layout<span
    ><i class="fa fa-caret-down" aria-hidden="true"></i
  ></span>
</div>
<div class="form-group row" style="margin: 1rem 0rem">
  <div class="tab-content col-sm-12">
    <div class="form-group" id="item-group"></div>
    <hr />
    <div class="form-group">
      <button 
        class="mt-2 btn btn-primary w-100"
        id="plus-item"
      >
        Adicionar Pergunta
      </button>
    </div>
  </div>
</div>
`;

function removeQuestion(form, keditor, id, msg = "") {
  let item = form.find(`#question-${id}`);
  const remove = form.find(`#remove-item-${id}`);
  remove.on("click", function () {
    const component = keditor.getSettingComponent();

    const itemView = component.find(`.article-${id}`);
    item.remove();
    itemView.remove();
    const listContentChildren = component.find(".FAQ").children();
    if (listContentChildren.length === 0) {
      component
        .find(".perguntas")
        .html(`<div class="col-sm-12 FAQ">${msg}</div>`);
    }
  });
}

function questionConfig(form, keditor, id) {
  form.find(`#item-input-${id}`).on("keyup", function () {
    const component = keditor.getSettingComponent();

    component.find(`.summary-${id}`).html(this.value);
  });
}
