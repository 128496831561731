import QuizModel from '../../quizModel'
import { ModalBase } from '../../../../keditor/modal/template/modalBase'
import BaseComponent from '../../../containers/baseComponent'
import { multipleChoiceTextEditor, multipleChoice, multipleChoiceImageChoose } from './ModalMultipleChoices'
import {MODAL_ID, MULTIPLE_CHOICE, OPEN_TEXT} from './constants'

const editQuizButton = /*html*/ `
<div class="d-flex flex-wrap">
  <div class="col-sm-12 my-3">
    <div 
      class="upload-button"
      id="open-modal"
      data-toggle="modal" 
      data-target="#${MODAL_ID}"
    >
      Editar Quiz
    </div>
  </div>
</div>
`

const MODAL_BODY = /*html*/ `
    <div id="add-item" class="mb-2">
      <span id="new-item">
          Adicionar pergunta múltipla escolha
      </span>
    </div>
    <hr>
    <div id="form-config"></div>
    <div id="empty-content">
        Lista vazia
    </div>
`

const MODAL_FOOTER = /*html*/ `
    <button type="button" class="btn cancel" data-dismiss="modal">
        Cancelar
    </button>
    <button type="button" class="btn save save-list-modal"  data-dismiss="modal">
        Salvar
    </button>
`

// const idTextEditorBuilder = (context, id) => {
//     return `${context}-text-editor-${id}`
// }
const openText = () => ``

const itemType = (type) => {
  switch (type) {
    case MULTIPLE_CHOICE:
      return multipleChoice
    case OPEN_TEXT:
      return openText
    default:
      break
  }
}

class ModalEditor extends BaseComponent {
  editQuizButton = editQuizButton
  #main = '#form-config'
  #emptyContent = '#empty-content' 

  #list = []

  #method(target, action, callback) {
    $(`#${MODAL_ID}`).find('.modal-body').off(action, target)
    $(`#${MODAL_ID}`).find('.modal-body').on(action, target, callback)
  }

  editOrder() {
    const self = this
    this.#method('.move-down', 'click', function (e) {
      const index = $(this).data('index')
      if (index + 1 < QuizModel.questions.length)
        [QuizModel.questions[index], QuizModel.questions[index + 1]] = [
          QuizModel.questions[index + 1],
          QuizModel.questions[index],
        ]

      self.#render()
    })

    this.#method('.move-up', 'click', function (e) {
      const index = $(this).data('index')
      if (index > 0)
        [QuizModel.questions[index], QuizModel.questions[index - 1]] = [
          QuizModel.questions[index - 1],
          QuizModel.questions[index],
        ]

      self.#render()
    })

    this.#method('.delete', 'click', function (e) {
      const index = $(this).data('index')
      if(QuizModel.questions.length === 1) return alert('Não é possivel ter um Quiz sem questões!')
      if (confirm('Tem certeza que deseja excluir este item?')) {
        QuizModel.questions = QuizModel.questions.filter((el, i) => i !== index)
      }

      self.#render()
    })

    this.#method('#new-item', 'click', function (e) {
      QuizModel.questions.push(
        QuizModel.defaultMultipleChoiceQuestion()
      )
      self.#render()
    })

    /** ReviewThis
     * Adicionar aqui eventHandle onClick para o botão salvar do form
     * Sobrescrever o QuizModel global pelo QuizModel Temporário
    */
  }

  initModal() {
    const modal = $(
      ModalBase({
        id: MODAL_ID,
        title: 'Configure seu Quiz',
        footer: MODAL_FOOTER,
        body: MODAL_BODY,
        info: '',
      })
    )

    modal.appendTo(QuizModel.keditor.wrapper)
    this.editOrder()
  }

  #render() {
    let list = ''
    QuizModel.questions.map((el, index) => {
      if (!el.type) return
      // options += /*html*/ `<option id="${index}" value="${index}">${el.question}</option>`
      // options += /*html*/ `<option id="${index}" value="${index}">Pergunta ${index + 1}</option>`
      list += itemType(el.type)(el, index)
    })

    $(`#${MODAL_ID}`).find(this.#main).html(list)
    multipleChoiceTextEditor()
    multipleChoiceImageChoose()

    $(`#${MODAL_ID}`).find(this.#emptyContent).hide()
    if (!QuizModel.questions.length) $(`#${MODAL_ID}`).find(this.#emptyContent).show()
  }

  show() {
    /** ReviewThis
     * Sobrescrever o QuizModel Temporário pelo Quiz Model global aqui
     */
    this.#render()
  }
}

const ModalEditorInstance = new ModalEditor()
export default ModalEditorInstance
