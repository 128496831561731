import { useTextShadow, useTextSpacing } from '../../../microComponents'

const TextSpacing = useTextSpacing({ isFirst: true })

const TextShadow = useTextShadow({ isFirst: true })

const FORM_ADVANCE_CONFIG = /*html*/ `
<div class="form-settings-head">
ESPAÇAMENTOS E SOMBRA<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>

<div class="form-group" style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    ${TextSpacing.template}
    <h6 
      class="col-sm-12 mt-3 mb-3"
      style="color: #fff; font-weight: bold;"
    >Sombra</h6>
    ${TextShadow.template}
  </div>
</div>
`

export default FORM_ADVANCE_CONFIG
