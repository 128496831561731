import {
    useAlignmentBlock,
    useBorderColor,
    useBorderRadius,
    useBorderStyle,
    useBorderWidth,
    useStickyPosition,
    useStickySize,
    useStickyToggleClose,
  } from '../../../microComponents'

const alignmentBlock = useAlignmentBlock({ isFirst: false })

const borderRadius = useBorderRadius({
    label: 'Curvatura',
    customOptions: [{name: '0px'},{name: '5px'},{name: '10px'},{name: '15px'}]
})

const borderWidth = useBorderWidth({
    label: 'Espessura',
})

const borderColor = useBorderColor({
    label: 'Cor',
    isFirst: true,
})
const borderStyle = useBorderStyle({
    label: 'Estilo',
    isFirst: true,
})
const stickySize = useStickySize({
    label: 'Tamanho',
    isFirst: true,
})
const stickyPosition = useStickyPosition({
    label: 'Posição',
    isFirst: true,
})
const stickyToggleClose = useStickyToggleClose({
    label: 'Ícone de fechar',
})


export const FORM_ADVANCED = /*html*/ `
  <div class="form-settings-head">
    BORDAS DO VÍDEO <span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
  </div>
  <div class="form-group" style="margin: 1rem 0rem">
    <div class="tab-content">
      <div class="d-flex flex-wrap">
        ${borderColor.template}
        ${borderStyle.template}
      </div>
      <div class="d-flex flex-wrap">
        ${borderRadius.template}
        ${borderWidth.template}
      </div>
    </div>
  </div>
  <div class="form-settings-head">
    OPÇÕES DE VÍDEO FIXO <span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
  </div>
  <div class="form-group" style="margin: 1rem 0rem">
    <div class="tab-content">
      <div class="d-flex flex-wrap">
      ${stickySize.template}
      ${stickyPosition.template}
      </div>
      <!--<hr />
      <div class="d-flex flex-wrap">
      ${stickyToggleClose.template}
      </div>-->
      <div class="d-flex flex-wrap">
      <p class="text-muted col-sm-12 mt-3" style="font-size: 12px !important; font-style: italic;">
        *Esta opção, fixa o vídeo na página quando não estiver visível. Para evitar comportamento indesejado, não ative esta configuração em mais de um elemento por página.
      </p>
      </div>
    </div>
  </div>
`


export default /*html*/ `
<div class="form-settings-head">
  Layout<span
    ><i class="fa fa-caret-down" aria-hidden="true"></i
  ></span>
</div>
<div class="form-group row" style="margin: 1rem 0rem">
  <div class="tab-content col-sm-12">
    <label for="video-type">Tipo de vídeo</label>
    <select id="video-type" class="form-control">
    <option value="youtube-type">Youtube</option>
    <option value="vimeo-type">Vimeo</option>
    <option value="panda-type">Panda</option>
    <!--<option value="custom">Custom</option>-->
    </select>

    <label for="video-embed" class="url-video mt-3">URL do vídeo</label>
    <input type="text" id="video-embed" class="form-control" placeholder="URL do vídeo" />

    <label id="video-embed-id-label" for="video-embed-id" style="display: none;" class="id-video mt-3">ID do vídeo</label>
    <input type="text" id="video-embed-id" style="display: none;" class="form-control" placeholder="ID do vídeo" />

    <label for="optional-width" class="mt-3">Largura opcional</label>
    <div class=" input-group input-group-sm">
      <input 
        type="number" 
        name="optional-width" 
        class="form-control" 
        id="optional-width" 
        value="" 
        placeholder="100"
      />
      <div class="input-group-prepend">
        <span class="input-group-text">px</span>
      </div>
    </div>
  </div>
  <div class="d-flex flex-wrap ">
    ${alignmentBlock.template}
  </div>
  <div class="tab-content col-sm-12">
    <hr />
    <div class="mt-3 video-autoplay" style="display: flex;">
      <label class="col-sm-10 p-0 m-0"
        >Autoplay
        <!--<i
          class="fa fa-info-circle"
          aria-hidden="true"
          style="font-size: 14px"
        ></i>-->
      </label>
      <div class="col-sm-2">
        <div class="button-switch">
          <input
            class="switch"
            type="checkbox"
            readonly
            checked
            id="youtube-autoplay"
          />
          <label
            class="lbl-off"
            for="youtube-autoplay"
          ></label>
          <label
            class="lbl-on"
            for="youtube-autoplay"
          ></label>
        </div>
      </div>
    </div>

    <div style="display: flex;" class="mt-3 video-branding">
      <label class="col-sm-10 p-0 m-0"
        >Esconder infos
        <!--<i
          class="fa fa-info-circle"
          aria-hidden="true"
          style="font-size: 14px"
        ></i>-->
      </label>
      <div class="col-sm-2">
        <div class="button-switch">
          <input
            class="switch"
            type="checkbox"
            readonly
            checked
            id="youtube-branding"
          />
          <label
            class="lbl-off"
            for="youtube-branding"
          ></label>
          <label
            class="lbl-on"
            for="youtube-branding"
          ></label>
        </div>
      </div>
    </div>

    <div style="display: flex;" class="mt-3 video-control">
      <label class="col-sm-10 p-0 m-0"
        >Esconder Controles
        <!--<i
          class="fa fa-info-circle"
          aria-hidden="true"
          style="font-size: 14px"
        ></i>-->
      </label>
      <div class="col-sm-2">
        <div class="button-switch">
          <input
            class="switch"
            type="checkbox"
            readonly
            checked
            id="youtube-controls"
          />
          <label
            class="lbl-off"
            for="youtube-controls"
          ></label>
          <label
            class="lbl-on"
            for="youtube-controls"
          ></label>
        </div>
      </div>
    </div>    

    <div style="display: flex;" class="mt-3 video-fullscreen">
      <label class="col-sm-10 p-0 m-0"
        >Permitir Tela Cheia
        <!--<i
          class="fa fa-info-circle"
          aria-hidden="true"
          style="font-size: 14px"
        ></i>-->
      </label>
      <div class="col-sm-2">
        <div class="button-switch">
          <input
            class="switch"
            type="checkbox"
            readonly
            checked
            id="video-fullscreen"
          />
          <label
            class="lbl-off"
            for="youtube-controls"
          ></label>
          <label
            class="lbl-on"
            for="youtube-controls"
          ></label>
        </div>
      </div>
    </div>    
  </div>
</div>
`
