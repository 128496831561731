import CarouselModel from '../../carouselModel'
import { MODAL_ID } from './constants'
import openGalleryButton from '../../../helpers/gallery.template.js' //'../../helpers/gallery.template.js'

export const carouselItem = (item, index) => {

    const navItem = /*html*/ `
    <div class="fields-config">
      <div class="field-moves">
        <a 
          class="move-icon move-up" 
          role="button"
          data-index="${index}"
        >
          <img src="bundle/assets/icons/arrow_up.svg" />
        </a>
        <a 
          class="move-icon move-down" 
          role="button"
          data-index="${index}"
          >
          <img style="transform: rotate(180deg);" src="bundle/assets/icons/arrow_up.svg" />
        </a>
        <a 
          class="move-icon delete" 
          role="button"
          data-index="${index}"
          >
          <img src="bundle/assets/icons/trash.svg" />
        </a>
      </div>
    </div>
    `

    return /*html*/ `
        <div class="field-group" id="${index}">
            ${navItem}
            <div class="fields-inputs">
                <h5>Item do Carrossel</h5>  
                <div class="mt-4">
                    <h5>Adicionar imagem</h5>
                </div>  
                <div class="col-sm-12 p-0">
                    <a href="#" style="font-size: 14px" id="clear-img-question">Remover imagem</a>
                    <div class="row" >
                        ${openGalleryButton(true, true, {"type":"image", "id":index}, item.imgsrc)}
                    </div>
                </div>  
                <div class="row">
                    <div class="col-sm-12 mt-4">
                        <h5>Título (opcional)</h5>
                    </div>  
                    <div class="col-sm-12 mt-2">
                        <input 
                            class="form-control carouseltitle" 
                            placeholder="Digite aqui o título" 
                            type="text" 
                            maxLength="60"
                            data-state='{"type":"title", "id":${index}}'
                            value="${item.title}"
                        /> 
                    </div>   
                </div> 
                <div class="row">
                    <div class="col-sm-12 mt-4">
                        <h5>Subtítulo (opcional)</h5>
                    </div>  
                    <div class="col-sm-12 mt-2">
                        <input 
                            class="form-control carouselsubtitle" 
                            placeholder="Digite aqui o subtítulo" 
                            type="text" 
                            maxLength="150"
                            data-state='{"type":"subtitle", "id":${index}}'
                            value="${item.subtitle}"
                        /> 
                    </div> 
                </div>      
                    
            </div>
        </div>
        `
}

function onChangeTextEditor(event) {
  const { target } = event
  let { value } = target
  const element = $(target)
  const state = element.data('state')

  const itemIndex = state.id//CarouselModel.itemsTemp.findIndex(el => el.id === state.id)
  const item = itemIndex !== -1 && {...CarouselModel.itemsTemp[itemIndex]}

  if (item) {
    //item[state.type] = value
    if (state.type === 'title') {
        item.title = value
    }else{
        item.subtitle = value
    }
    CarouselModel.itemsTemp[itemIndex] = item
  }
}

function onChangeImage(target, form, src) {
  const state = form.find('.change-image-button').data('state')
  
  if (state.type !== 'image') return ;

  const itemIndex = state.id//CarouselModel.itemsTemp.findIndex(el => el.id === state.id)
  const item = itemIndex !== -1 && {...CarouselModel.itemsTemp[itemIndex]}

  if(item) CarouselModel.itemsTemp[itemIndex].imgsrc = src
}

export function itemTextsEdit() {
  $(`#${MODAL_ID}`)
    .find('.modal-body')
    .on('keyup', '.carouseltitle', (e) => onChangeTextEditor(e))

  $(`#${MODAL_ID}`)
    .find('.modal-body')
    .on('keyup', '.carouselsubtitle', (e) => onChangeTextEditor(e))
}


export function itemImageChoose() {
  $(`#${MODAL_ID}`)
  .find('.modal-body')
  .on('click', CarouselModel.openGallery, (e) => {

    /**
     * If user click at the div.info inside div#photo-galery-2 the e.target will be it e.e'
     * The line below handle this and get the right parent (the button wrapper)
     */
    const openGalleryButtonWrapper = $(e.target).hasClass('info') ? $(e.target).parent().parent() : $(e.target).parent()
    
    CarouselModel.keditor.options.locale.modalImage.form = openGalleryButtonWrapper
    CarouselModel.keditor.options.locale.modalImage.isGeneric = true
    CarouselModel.keditor.options.locale.modalImage.callback = onChangeImage
      
  })

  $(`#${MODAL_ID}`)
  .find('.modal-body')
  .on('click', '#clear-img-question', (e) => {

    const imgformContainer = $(e.target).parent()
    const galleryBtns = imgformContainer.find(CarouselModel.openGallery)
    
    onChangeImage(null, imgformContainer, '')
    
    imgformContainer.find('.row:first').html(
      openGalleryButton(true, true, $(galleryBtns[0]).data('state'), '')
    )

  })
}
