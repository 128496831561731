import {
  useFontSelector,
  useColor,
  useFontStyle,
  useFontSizeResponsive,
  useShowElement
} from '../../../microComponents'

const fontFamily = useFontSelector({
  label: 'Fonte',
  isFirst: true,
  fullWidth: true,
})

const fontSizeTitle = useFontSizeResponsive({
  customFormRef: 'font-size-responsive-Title',
  label: 'Tamanho',
  inputMaxLength: 100,
})

const fontSizeDescription = useFontSizeResponsive({
  customFormRef: 'font-size-responsive-description',
  label: 'Tamanho',
  inputMaxLength: 100,
})

const titleColor = useColor({
  customFormRef: 'title-color',
  label: 'Cor do Texto',
})

const bgHeaderColor = useColor({
  customFormRef: 'bg-header-color',
  label: 'Cor do Fundo',
})

// const descriptionColor = useColor({
//   customFormRef: 'description-color',
//   label: 'Cor do Texto',
// })

const bgBodyColor = useColor({
  customFormRef: 'bg-body-color',
  label: 'Cor do Fundo',
})

const showIcon = useShowElement({
  customFormRef: 'show-icon',
  label: 'Mostrar Ícone',
})

const titleFontWeight = useFontStyle({
  label: 'Peso',
})

const showOpened = useShowElement({
  customFormRef: 'show-opened',
  label: 'Começar aberto',
})

const headerConfigBlock = /*html*/ `
<div class="form-settings-head">
  CABEÇALHO<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group " style="margin: 1rem 0rem">
  <div class="tab-content col-sm-12 mt-3">
    <label for="button-text">Texto</label>
    <input type="text" class="form-control" id="title-text" />
    <hr class="col-sm-12" style="width: 100%"/>
    <div class="row">
      ${showIcon.template}
    </div>
    <hr class="col-sm-12" style="width: 100%"/>
    <div class="row">
      ${showOpened.template}
    </div>
    <div class="row">
    <p class="text-muted col-sm-12 mt-3" style="font-size: 12px !important; font-style: italic;">
      * A mudança dessa configuração só será possível vê-la na página final publicada.
    </p>
    </div>
  </div>
</div>
`


const stylesConfigBlock = /*html*/ `
<div class="form-settings-head">
  LAYOUT<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group " style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    ${fontFamily.template}
    <hr class="col-sm-12" style="width: 100%"/>
    <h6 class="col-sm-12 mb-2" style="width: 100%">Cabeçalho</h6>
    ${fontSizeTitle.template}
    ${titleFontWeight.template}
    ${titleColor.template}
    ${bgHeaderColor.template}
    <hr class="col-sm-12" style="width: 100%"/>
    <h6 class="col-sm-12 mb-2" style="width: 100%">Descrição</h6>
    ${fontSizeDescription.template}
    
    ${bgBodyColor.template}
  </div>
</div>
`


export default /*html*/ `
${headerConfigBlock}
${stylesConfigBlock}
`
