import KEditor from "keditor";
import {
	componentSpacing,
	textOptions,
	textEditor,
	changeOneClass,
	verifyHasClass,
	mobileFontSize,
} from "./helpers/helpers";
import {
	templateForm,
	fontSizeOptions,
	hideMobileDesktop,
} from "./helpers/form.settings";

import initTimeDelay from  "./helpers/timeDelay/initSettings";
import showTimeDelay from  "./helpers/timeDelay/showSettings";
import {initAnimations, showAnimations} from './helpers/animations'

import {
	hmltIconsElementString,
	allIcons,
	filter,
} from "./helpers/completeListIcons";

import ColorPicker from "./helpers/colorPicker";
const colorPicker = new ColorPicker()
let CKEDITOR;
KEditor.components["mention"] = {
	settingEnabled: true,

	settingTitle: "Menção",

	options: textOptions(),

	init: function (contentArea, container, component, keditor) {
		let self = this;
		let options = self.options;
		 
		textEditor(
			// ".keditor-component-content",
			".media-body",
			component,
			keditor,
			options,
			contentArea,
			container,
			"icon"
		);
		CKEDITOR = keditor.iframeWindow.window.CKEDITOR
	},

	getContent: function (component, keditor) {
		let componentContent = component.find(".keditor-component-content");
		let id = componentContent.attr("id");
		let editor = CKEDITOR.instances[id];
		if (editor) {
			return editor.getData();
		} else {
			return componentContent.html();
		}
	},

	destroy: function (component, keditor) {
		let id = component.children(".keditor-component-content").attr("id");
		CKEDITOR.instances[id] && CKEDITOR.instances[id].destroy();
	},

	initSettingForm: function (form, keditor) {
    const component = () => keditor.getSettingComponent()
		const self = this;
		const options = self.options;

		form.append(templateForm({children: formLayout}));
		initTimeDelay.call(self, form, keditor);
		initAnimations(form,keditor)
		mobileFontSize(".keditor-component-content", 20, form, null, keditor, 2);

		//Style
		const search = form.find(".searchIcon");
		const items = form.find("#menuItems");
		const dropdownButton = form.find("#dropdownMenuButton");
		const contents = hmltIconsElementString();
		items.append(contents);
		search.on("keyup", function () {
			filter(this.value, items);
		});

		form.find(".dropdown-item").on("click", function () {
			const icon = component().find("#icon-mention");
			let type = $(this).attr("class").split(" fa ");

			const classString = changeOneClass(icon, "fa-", type[1]);
			icon.removeClass();
			icon.addClass(classString);

			const label = type[1].split("-").join(" ");
			dropdownButton.html(`<i class="fa ${type[1]} mx-1"></i>${label}`);
		});

		//Color
		colorPicker.init(form, color => {
			const icon = component().find("#icon-mention");
			icon.css("color", color);
    })

		//Size
		let iconSize = form.find("#icon-size");
		iconSize.on("change", function () {
			let icon = component().find("#icon-mention");
			let size = this.value;

			icon.removeClass("fz-4x fz-3xi fz-4xi fz-5xi fz-6xi");
			if (size) {
				icon.addClass(size);
			}
		});

		//Espaçamentos
		componentSpacing(".media", form, null, keditor, 2);

		hideMobileDesktop(form, null, keditor, ".media", "edit");

		mobileFontSize(".keditor-component-content", 20, form, null, keditor, 2);
	},

	showSettingForm: function (form, component, keditor) {
		showTimeDelay.call(self,form, component, keditor);
		showAnimations(form,keditor)
		const inputSize = form.find("#icon-size");
		const dropdownButton = form.find("#dropdownMenuButton");

		const icon = component.find("#icon-mention");
		const dropdownIcon = verifyHasClass(icon, Object.keys(allIcons));
		const label = dropdownIcon.split("-").join(" ");

		dropdownButton.html(`<i class="fa ${dropdownIcon} mx-1"></i> ${label}`);
		inputSize.val(
			verifyHasClass(icon, ["fz-4x", "fz-3xi", "fz-4xi", "fz-5xi", "fz-6xi"])
		);
		//Color		
    colorPicker.show(form, icon.css("color"))

		// Espaçamentos
		componentSpacing(component.find(".media"), form, component, keditor, 1);

		hideMobileDesktop(form, component.find(".media"), keditor, null, "");

		const componentContent = component
			.find(".keditor-component-content")
			.children()
			.children();

		mobileFontSize(
			componentContent.find("span"),
			20,
			form,
			component,
			keditor,
			1
		);
	},
};

const formLayout = `
<div class="form-settings-head">
  Layout<span
    ><i class="fa fa-caret-down" aria-hidden="true"></i
  ></span>
</div>
<div class="form-group row" style="margin: 1rem 0rem">
  <label for="icon-type" class="col-sm-12">Icone</label>
  <div class="col-sm-12 dropdown">
    <button class="col-sm-12 btn btn-secondary dropdown-toggle d-flex align-items-center justify-content-between" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Icones
    </button>
    <div class="col-sm-12 dropdown-menu" aria-labelledby="dropdownMenuButton">
      <div class="dropdown-header">
        <input type="search" class="form-control searchIcon" placeholder="address book" autofocus="autofocus">
      </div>
      <div id="menuItems"></div>
    </div>
  </div>

  <label for="icon-size" class="col-sm-12 mt-3">Tamanho</label>
  <div class="col-sm-12">
    <select id="icon-size" class="form-control">
      <option value="fz-4x">Muito Pequeno</option>
      <option value="fz-3xi">Pequeno</option>
      <option value="fz-4xi">Médio</option>
      <option value="fz-5xi">Grande</option>
      <option value="fz-6xi">Muito Grande</option>
    </select>
  </div>
  
  <label for="input-color" class="col-sm-12 mt-3">Cor do Icone</label>
  <div id="input-color" class="input-group col-sm-12">
		<input data-id="#color-picker" class="color-picker-component form-control" />
  </div>
</div>
<div class="form-settings-head">
  Responsivo<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>

  <div class="form-group row" style="margin: 1rem 0rem">
    <div class="col-sm-12">
      <label for="input-color">Tamanho da Fonte no Celular</label>
      <select class="form-control mobile-font-size">
          ${fontSizeOptions(20)}
      </select>
    </div>
  </div>
`;
