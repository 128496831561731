import { useAlignmentWithIcon, useColor, useFontSelector, useFontSizeResponsive, useInputSpacing } from '../../../microComponents'

const fontFamily = useFontSelector({
  label: 'Fonte',
})
const alignment = useAlignmentWithIcon({})

const fontSizeMobile = useFontSizeResponsive({
  label: 'Tamanho',
  customFormRef: 'font-size-text',
})

const fontSizeIcon = useFontSizeResponsive({
  label: 'Tamanho',
  customFormRef: 'font-size-icon',
})

const color = useColor({
  label: 'Cor',
})

const iconColor = useColor({
  label: 'Cor',
  customFormRef: 'input-icon-color',
})

const itemSpacing = useInputSpacing({
  label: 'Espaço entre itens',
  customFormRef: 'input-margin-bottom',
  isFirst: true,
})

const inputCards = ({ icon = '', value = '', size = '3' }) => /*html*/ `
<li class="nav-item col-sm-${size} m-0" style="padding: 1px">
  <a
    class="nav-link nav-item-custom d-flex w-100 justify-content-center"

    id="${icon}"
    data-toggle="pill"
    href="#"
    role="tab"
    data-value="${value}"
    aria-controls=""
    aria-selected="true"
  >
    <i class="fa fa-${icon}" data-value="${value}"></i>
  </a>
</li>
`

const editList = /*html*/ `
<div class="d-flex flex-wrap">
  <div class="col-sm-12 my-3">
    <div 
      class="upload-button"
      id="open-modal"
      data-toggle="modal" 
      data-target="#modal-list"
    >
      Editar lista
    </div>
  </div>
</div>
`
const typograph = /*html*/ `
<div class="form-settings-head">
  Tipografia <span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-custom " style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
  ${fontSizeMobile.template}
  ${fontFamily.template} ${color.template}
  ${alignment.template}
  </div>
</div>
`

export default /*html*/ `
<div class="items-config-block">
${editList}
</div>
<div class="form-settings-head">
  Layout <span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group " style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    <div class="col-sm-6">
      <label>Tipo de lista</label>
      <ul class="nav nav-pills" id="list-type" role="tablist">
        ${inputCards({ value: 'list-icon', icon: 'list', size: 2 })}
        ${inputCards({ value: 'none', icon: 'times', size: 2 })}
      </ul>
    </div>
    ${itemSpacing.template}
  </div>
</div>
<div id="list-icon">
  <div class="form-settings-head">
    Estilo dos Ícones <span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
  </div>
  <div class="form-group" style="margin: 1rem 0rem">
    <div class="d-flex flex-wrap">
      ${fontSizeIcon.template}
      ${iconColor.template}
    </div>
  </div>
</div>
${typograph}
`
