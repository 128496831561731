import { useFontSelector, useFontSizeResponsive, useColor, useHeight, useImageFit } from '../../microComponents'
import ModalEditor from '../components/ModalEditor'

const fontFamilyTitle = useFontSelector({
  label: 'Fonte',
  customFormRef: 'font-family-title',
  isFirst: false,
  fullWidth: false,
})

const fontFamilySubtitle = useFontSelector({
  label: 'Fonte',
  customFormRef: 'font-family-subtitle',
  isFirst: false,
  fullWidth: false,
})

const fontSizeTitle = useFontSizeResponsive({
  label: 'Tamanho',
  customFormRef: 'font-size-title',
  isFirst: true,
  inputMaxLength: 52, //mobile only
})

const fontSizeSubtitle = useFontSizeResponsive({
  label: 'Tamanho',
  customFormRef: 'font-size-subtitle',
  isFirst: true,
  inputMaxLength: 32, //mobile only
})

const titleColor = useColor({
  customFormRef: 'title-color',
  label: 'Cor',
  isFirst: false,
})

const subTitleColor = useColor({
  customFormRef: 'subtitle-color',
  label: 'Cor',
  isFirst: false,
})

const height = useHeight({
  label: 'Altura do Carrossel',
  fullWidth: false,
  isFirst: true,
  customOptions: [
    { value: '180px', label: 'Pequeno' },
    { value: '380px', label: 'Médio' },
    { value: '720px', label: 'Grande' },
    { value: '100vh', label: 'Altura da Tela' },
  ]
})

const imgFit = useImageFit({
  label: 'Encaixe das Imagens',
  fullWidth: false,
  isFirst: true,
  // customOptions: [
  //   { value: 'none', label: 'Auto' },
  //   { value: 'contain', label: 'Conter' },
  //   { value: 'cover', label: 'Cobrir' },
  // ],
  //isBgImg: false
})


const ToggleConfig = ({ label, formRef }) => /*html*/ `
<div class="d-flex mt-3">
  <label class="col-sm-10 p-0 m-0">${label}</label>
  <div class="col-sm-2 p-0 m-0">
    <div class="button-switch col-sm-2">
      <input 
        type="checkbox" 
        id="${formRef}" 
        class="switch" 
      />
      <label for="${formRef}" class="lbl-off"></label>
      <label for="${formRef}" class="lbl-on"></label>
    </div>
  </div>
</div>
`

const itemsConfigBlock = /*html*/ `
<div class="form-settings-head">
  LAYOUT<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group " style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    <h6 class="col-sm-12 mb-2" style="width: 100%">Título</h6>
    ${fontSizeTitle.template}
    ${fontFamilyTitle.template}
    ${titleColor.template}
    <hr class="col-sm-12" style="width: 100%"/>
    <h6 class="col-sm-12 mb-2" style="width: 100%">Subtítulo</h6>
    ${fontSizeSubtitle.template}
    ${fontFamilySubtitle.template}
    ${subTitleColor.template}
  </div>
</div>
`

export default /*html*/ `
${ModalEditor.editButton}
<div class="form-settings-head">
  NAVEGAÇÃO<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group " style="margin: 1rem 0rem">
  <div class="tab-content col-sm-12">
    ${ToggleConfig({ label: 'Mostrar Setas', formRef: 'showArrows' })}
    ${ToggleConfig({ label: 'Mostrar Pontos', formRef: 'showDots' })}
    ${ToggleConfig({ label: 'Repetir', formRef: 'loop' })}
    ${ToggleConfig({ label: 'Autoplay', formRef: 'autoplay' })}
    <hr class="col-sm-12" style="width: 100%"/>
    <div class="row">
      ${height.optionsTemplate}
      ${imgFit.template}
    </div>
  </div>
</div>
${itemsConfigBlock}
`
