import KEditor from 'keditor'

import { templateForm } from './helpers/form.settings'

import * as template from './containers/countdown/template'
import initSettings from './containers/countdown/initSettings'
import showSettings from './containers/countdown/showSettings'
import CountdownComponent from './containers/countdown/methods'

const countdownComponent = new CountdownComponent()
KEditor.components['countdown'] = {
  settingEnabled: true,
  settingTitle: 'Contador',

  /**
   * Function will be called when initializing a component with this type
   * @param {jQuery} contentArea
   * @param {jQuery} container
   * @param {jQuery} component
   * @param {KEditor} keditor KEditor instance which is calling this function
   */
  init: function (contentArea, container, component, keditor) {
    
    const componentId = component.attr('id')
    const footer_system = keditor.options.contentScripts.footer_system

    //Procura por uma chamada desse component já existente no footer_system
    if (footer_system.content.countdownArr.indexOf(componentId)) {
      //Se não existir, adiciona a chamada da função countdown para esse componente
      footer_system.content.countdownArr.push(componentId)
    }
  },

  /**
   * Function will be called when deleting component
   * @param {jQuery} component
   * @param {KEditor} keditor KEditor instance which is calling this function
   */
  destroy: function (component, keditor) {
    //Remove a chamada da função countdown desse componente no footer_system
    const componentId = component.attr('id')
    const footer_system = keditor.options.contentScripts.footer_system

    const index = footer_system.content.countdownArr.indexOf(componentId)
    if (index > -1) {
      footer_system.content.countdownArr.splice(index, 1)
    }
  },

  /**
   * Initialize setting form of this type
   * @param {jQuery} form Form contains all setting of this type and is child of `div[id="keditor-setting-forms"]`
   * @param {KEditor} keditor KEditor instance which is calling this function
   */
  initSettingForm: function (form, keditor) {
    //Builds the Component Form
    form.append(templateForm({ children: template.FORM_LAYOUT }))

    //Call Component Init
    initSettings.call(self, form, keditor)
    countdownComponent.keditor = keditor
    countdownComponent.form = form
    countdownComponent.init()
  },

  /**
   * Show setting form for this type. This function will be called when user clicks on setting button of component when setting panel is hidden. You can fulfill form controls in this function.
   * @param {jQuery} form Form contains all setting of this type and is child of `div[id="keditor-setting-forms"]`
   * @param {jQuery} component Component will be applied setting
   * @param {KEditor} keditor KEditor instance which is calling this function
   */
  showSettingForm: function (form, component, keditor) {
    showSettings.call(self, form, component, keditor)

    countdownComponent.component = component
    countdownComponent.show()
  },
}
