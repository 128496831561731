import KEditor from 'keditor'
import CollapseComponent from './containers/collapse'
import { hideMobileDesktop } from './helpers/form.settings'
import { textOptions, textEditor, componentSpacing } from './helpers/helpers'
import initTimeDelay from './helpers/timeDelay/initSettings'
import showTimeDelay from './helpers/timeDelay/showSettings'
import {initAnimations, showAnimations} from './helpers/animations'

let CKEDITOR;

let collapseComponent = new CollapseComponent()
KEditor.components['collapse'] = {
  
  settingEnabled: true,
  settingTitle: 'FAQ',

  init: function (contentArea, container, component, keditor) {
    collapseComponent.keditor = keditor
    collapseComponent.component = component
    collapseComponent.create()

    //initialize ckeditor at description
    //let options = textOptions(true, 'Font,')
    textEditor(`.card-body`, component.children('.keditor-component-content'), keditor, keditor.options, contentArea, container, '', true, 'Font,')
    CKEDITOR = keditor.iframeWindow.window.CKEDITOR
  },

  getContent: function (component, keditor) {
    let componentContent = component.find('.keditor-component-content')
    let id = componentContent.attr('id')
    let editor = CKEDITOR.instances[id]
    if (editor) {
      return editor.getData()
    } else {
      componentContent.find('.card-body').prop('contenteditable', false)
      return componentContent.html()
    }
  },

  destroy: function (component, keditor) {
    let id = component.children('.keditor-component-content').attr('id')
    CKEDITOR.instances[id] && CKEDITOR.instances[id].destroy()
  },

  initSettingForm: function (form, keditor) {
    form.html(collapseComponent.template)
    collapseComponent.form = form
    collapseComponent.init()

    componentSpacing('.accordion', form, null, keditor, 2)
    initTimeDelay.call(self, form, keditor)
    initAnimations(form,keditor)
    hideMobileDesktop(form, null, keditor, '.accordion', 'edit')
  },

  showSettingForm: function (form, component, keditor) {
    collapseComponent.component = component
    collapseComponent.show()

    componentSpacing(component.find('.accordion'), form, component, keditor, 1)
    showTimeDelay.call(self, form, component, keditor)
    showAnimations(form,keditor)
    hideMobileDesktop(form, component.find('.accordion'), keditor, null, '')
  },
}