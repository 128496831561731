import {
  useAlignmentBlock,
  useColor,
  useFontSizeResponsive,
  useIconSelect,
  useWrapperWithLink,
} from '../../../microComponents'

const iconColor = useColor({
  label: 'Cor',
})

const iconSelect = useIconSelect({
  label: 'Icone',
  hasShowConfig: false,
})

const fontSizeMobile = useFontSizeResponsive({
  label: 'Tamanho',
  inputMaxLength: 100,
})
const iconAlignment = useAlignmentBlock({})

const iconLink = useWrapperWithLink({ isFirst: true })

export default /*html*/ `
<div class="form-settings-head">
  LAYOUT <span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group" style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    ${iconSelect.template}
    ${fontSizeMobile.template}
    ${iconAlignment.template}
    ${iconColor.template}
  </div>
</div>

<div class="form-settings-head">
  LINKS <span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group" style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    ${iconLink.template}
  </div>
</div>
`
