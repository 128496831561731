import { hideMobileDesktop } from '../../helpers/form.settings'
import { componentSpacing } from '../../helpers/helpers'
import showTimeDelay from '../../helpers/timeDelay/showSettings'
import { showAnimations } from '../../helpers/animations'

export default function (form, component, keditor) {
  //Fills General Fields
  hideMobileDesktop(form, component.find('.countdown-container'), keditor, null, '')
  componentSpacing(component.find('.countdown-container'), form, component, keditor, 1)
  showTimeDelay.call(self, form, component, keditor)
  showAnimations(form,keditor)

}
