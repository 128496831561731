import { templateForm } from '../../helpers/form.settings'
import { verifyHasClass } from '../../helpers/helpers'
import { useBackgroundColor, useColor, useFontSizeResponsive } from '../../microComponents'
import BaseComponent from '../baseComponent'
import { FORM_LAYOUT } from './templates'

const useComponents = [
  { config: useBackgroundColor },
  { config: useFontSizeResponsive },
  { config: useColor, customFormRef: 'text-color-editor' },
]

export default class ProgressComponent extends BaseComponent {
  #elements = {
    percent: {
      data: null,
      selector: '#percent',
    },
    barText: {
      data: null,
      selector: '#bar-text',
    },
    barHeight: {
      data: null,
      selector: '#bar-height',
    },
  }

  template() {
    return templateForm({ children: FORM_LAYOUT })
  }

  set form(newForm) {
    this._form = newForm
    Object.keys(this.#elements).map((key) => {
      this.#elements[key].data = this._form.find(this.#elements[key].selector)
    })

    useComponents.map((component) => {
      const aux = component.config({
        customFormRef: component.customFormRef,
        form: this._form,
        keditor: this._keditor,
        elementRef: () => this.getRef('.progress-bar'),
      })

      this[aux.componentName] = aux
    })

    this.textColor = useColor({
      form: this._form,
      keditor: this._keditor,
      customFormRef: 'text-color-editor',
      elementRef: () => this.getRef('.progress-bar'),
    })
  }

  method(target, action, callback) {
    return this.#elements[target].data.on(action, callback)
  }

  getMethodVal(target, value) {
    return this.#elements[target].data.val(value)
  }

  init() {
    if (!this._form) return

    this.textColor.init()
    this.backgroundColor.init()
    this.fontSizeResponsive.init(() => this.getRef('.progress-bar'))

    this.method('barText', 'keyup', (e) => {
      this.getRef('.progress-bar').html(e.target.value)
    })

    this.method('percent', 'change', (e) => {
      this.getRef('.progress-bar').css('width', `${e.target.value}%`)
      this.getRef('.progress-bar').attr('width', `${e.target.value}`)
    })

    this.method('barHeight', 'change', (e) => {
      const progress = this.getRef('.progress')

      let size = e.target.value
      progress.removeClass('20 fz-lg 40 fz-2x 60 fz-3x')

      if (size == '20') {
        progress.addClass('20 fz-lg')
      } else if (size == '40') {
        progress.addClass('40 fz-2x')
      } else if (size == '60') {
        progress.addClass('60 fz-3x')
      }
      progress.css('height', size)
    })
  }

  show() {
    if (!this._component || !this._form) return

    this.textColor.show()
    this.backgroundColor.show()
    this.fontSizeResponsive.show(() => this.getRef('.progress-bar'))

    this.getMethodVal('percent', this.getRef('.progress-bar').attr('width').split('%').join(''))
    this.getMethodVal('barText', this.getRef('.progress-bar').html().trim())
    this.getMethodVal('barHeight', verifyHasClass(this.getRef('.progress'), ['20', '40', '60']))
  }
}
