import QuizModel from '../quizModel'
import { DEFAULT_ACTION_SELECT } from '../../../constants'

function getFunctionalityValue(e) {    
    if (!e) return

    const { form, data, options } = e
    const value = data.showMessage ? 'message' : 'redirect'

    const findOption = options.find((el) => el.value == value)
  form.find('#dropdown-set-functionality').html(findOption ? findOption.text : DEFAULT_ACTION_SELECT)

    form.find(QuizModel.redirectConfigRef).show()
    form.find(QuizModel.messageConfigRef).hide()
    if (data.showMessage) {
      form.find(QuizModel.redirectConfigRef).hide()
      form.find(QuizModel.messageConfigRef).show()
    }
  }

export default getFunctionalityValue