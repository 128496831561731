import { templateForm } from '../../helpers/form.settings'
import ColorPicker from '../../helpers/colorPicker'
import {
    useFontSelector,
    useColor,
    useFontStyle,
    useFontSizeResponsive,
    useShowElement
} from '../../microComponents'
import BaseComponent from '../baseComponent'
import { FORM_LAYOUT } from './templates'

const colorPicker = new ColorPicker()

export default class CollapseComponent extends BaseComponent {

    #wrapper = '.accordion'
    #header = '.card-header'
    #headerContent = '#card-header-content'
    #headerTitle = '.card-header-title'
    #headerIcon = '.card-header-icon-wrapper'
    #body = '.card-body'

  // SETTERS
  set form(newForm) {
    this._form = newForm
    
    const defaultValues = {
        form: this._form,
        keditor: this._keditor,
        elementRef: () => this.getRef(this.#wrapper),
    }

    this.fontSelector = useFontSelector(defaultValues)

    this.fontSizeTitle = useFontSizeResponsive({
        ...defaultValues,
        customFormRef: 'font-size-responsive-Title',
        customComponentName: 'fontSizeTitle',
    })

    this.fontSizeDescription = useFontSizeResponsive({
        ...defaultValues,
        customFormRef: 'font-size-responsive-description',
        customComponentName: 'fontSizeDescription',
    })

    this.titleColor = useColor({
        form: this._form,
        keditor: this._keditor,
        elementRef: () => this.getRef(this.#header),
        customFormRef: 'title-color',
    })

    // this.descriptionColor = useColor({
    //     form: this._form,
    //     keditor: this._keditor,
    //     elementRef: () => this.getRef(this.#body).find('span'),
    //     customFormRef: 'description-color',
    // })

    this.showIcon = useShowElement({
        form: this._form,
        keditor: this._keditor,
        elementRef: () => this.getRef(this.#headerIcon),
        customFormRef: 'show-icon',
        customShowPropValue: 'flex'
    })

    //header title weight
    this.fontStyle = useFontStyle({
        form: this._form,
        keditor: this._keditor,
        elementRef: () => this.getRef(this.#headerTitle),
    })
    
  }

  template() {
    return templateForm({ children: FORM_LAYOUT })
  }

  create() {
    if (!this._component || !this._keditor) return
  }

  init() {
    if (!this._form) return

    this.fontSelector.init()

    this.fontSizeTitle.init(() => this.getRef(this.#headerContent))
    this.fontSizeDescription.init(() => this.getRef(this.#body).find('span'))

    this.titleColor.init()
    // this.descriptionColor.init()

    //Title Input Text
    this._form.find('#title-text').on('keyup', (event) => {
        this.getRef(this.#headerTitle).text(event.target.value)
    })

    //Cor de Fundo do Header (title)
    colorPicker.init(
        this._form,
        (color) => {
            this.getRef(this.#header).css('background-color', color)
        },
        '#bg-header-color'
    )
    //Cor de Fundo do Body (Description) 
    colorPicker.init(
        this._form,
        (color) => {
            this.getRef(this.#body).css('background-color', color)
        },
        '#bg-body-color'
    )

    //Show Opened
    this._form.find('#show-opened').on('click', (event) => {
        const isChecked = $(event.target).prop('checked')
        this.getRef(this.#header)[isChecked ? "removeClass" : "addClass"]("collapsed")
    })

    this.showIcon.init()

    //Font Weight
    this.fontStyle.init()
  }

  show() {
    if (!this._component || !this._form) return

    this.fontSelector.show()

    this.fontSizeTitle.show(() => this.getRef(this.#headerContent))
    this.fontSizeDescription.show(() => this.getRef(this.#body).find('span'))

    this.titleColor.show()
    // this.descriptionColor.show()

    //Title Input Text
    this._form.find('#title-text').val(this.getRef(this.#headerTitle).text().trim())

    //Cores de Fundo
    colorPicker.show(this._form, this.getRef(this.#header).css('background-color'), '#bg-header-color')
    colorPicker.show(this._form, this.getRef(this.#body).css('background-color'), '#bg-body-color')

    //Show Opened
    this._form.find('#show-opened').prop('checked',!this.getRef(this.#header).hasClass("collapsed"))

    this.showIcon.show()

    //Font Weight
    this.fontStyle.show()
  }
}