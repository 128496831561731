import {
  useIconSelect,
  useFontSelector,
  useFontSizeResponsive,
  useColor,
  useAlignmentWithIcon,
  useInputSpacing,
} from '../../microComponents'
import { templateForm } from '../../helpers/form.settings'
import * as templates from './templates'
import BaseComponent from '../baseComponent'
import initModalList from '../../../keditor/modal/initModalList'
import { verifyHasClass } from '../../helpers/helpers'

const useComponents = [
  { config: useFontSelector },
  { config: useAlignmentWithIcon },
  { config: useFontSizeResponsive, customFormRef: 'font-size-icon' },
  { config: useFontSizeResponsive, customFormRef: 'font-size-text' },
  { config: useColor },
  { config: useIconSelect },
]

export default class ListComponent extends BaseComponent {
  #listData = {
    icon: {
      size: {
        default: 14,
        mobile: 14,
        block: true,
      },
      color: '#222',
    },
    type: '',
    item: {
      bottomSpacing: 10,
    },
    text: {
      color: '',
      alignment: {
        default: 'default-text-left',
        mobile: 'mobile-text-left',
        block: true,
      },
    },
    list: [
      // {
      //   description: '',
      //   icon: ''
      // }
    ],
  }

  #ulRef = '.list-content'
  #iconRef = '.icon'
  #liRef = 'li.item'

  set form(form) {
    this._form = form

    useComponents.map((component) => {
      const aux = component.config({
        customFormRef: component.customFormRef,
        form: this._form,
        keditor: this._keditor,
        elementRef: () => this.getRef(this.#ulRef),
      })
      this[aux.componentName] = aux
    })

    this.iconColor = useColor({
      form: this._form,
      keditor: this._keditor,
      customFormRef: 'input-icon-color',
      elementRef: () => this.getRef(this.#iconRef),
    })

    this.alignmentWithIcon = useAlignmentWithIcon({
      form: this._form,
      keditor: this._keditor,
      elementRef: () => this.getRef(this.#ulRef),
    })
    this.color = useColor({
      form: this._form,
      keditor: this._keditor,
      elementRef: () => this.getRef('li span'),
    })

    this.fontSizeIcon = useFontSizeResponsive({
      form: this._form,
      keditor: this._keditor,
      customFormRef: 'font-size-icon',
    })

    this.itemSpacing = useInputSpacing({
      form: this._form,
      keditor: this._keditor,
      customFormRef: 'input-margin-bottom',
      customSpacingProp: 'margin-bottom',
      elementRef: () => this.getRef(this.#liRef),
    })
  }

  //GETTERS
  get template() {
    return $(templateForm({ children: templates.FORM_LAYOUT }))
  }

  //METHODS
  create() {
    const list = this._component.find(this.#ulRef)
  }

  init() {
    if (!this._form) return

    this.fontSelector.init()
    this.color.init((color) => {
      this.#listData.text.color = color
      this.#setDataList()
    })
    this.fontSizeResponsive.init(() => this._component.find(this.#ulRef))
    this.alignmentWithIcon.init(({ target, value, isBlock }) => {
      this.#listData.text.alignment[target] = value ? value : isBlock

      //Set display grid
      this._component.find(this.#ulRef).css('display', 'grid')

      this.#setDataList()
    })
    this.iconColor.init((color) => {
      this.#listData.icon.color = color
      this.#setDataList()
    })
    this.fontSizeIcon.init(
      () => this.getRef(this.#iconRef),
      ({ target, value, isBlocked }) => {
        if (target) this.#listData.icon.size[target] = value
        this.#listData.icon.size.block = isBlocked
        if (isBlocked) {
          this.#listData.icon.size.mobile = value
        }

        this.#setDataList()
      }
    )

    this.itemSpacing.init( (mbValue) => {
      this.#listData.item.bottomSpacing = mbValue
      this.#setDataList()
    })

    this.method('open-modal', 'click', () => {
      initModalList({
        page: this._keditor,
        form: this._form,
        keditor: this._keditor,
        formData: this.#listData.list,
        onSave: (e) => {
          this.#listData.list = e
          this.#renderView()
        },
      })
    })

    this._form
      .find('#list-type')
      .find(`.nav-item`)
      .on('click', (e) => {
        const value = $(e.target).attr('data-value')
        const list = this.getRef(this.#ulRef)

        list.attr({ 'data-list_type': value })
        if ('list-icon,none'.indexOf(value) != -1) {
          list.css('list-style-type', 'none')
        }
        this.#changeListType(value)
      })
  }

  show() {
    const list = this._component.find(this.#ulRef)
    const classes = (type) => `${type}-text-center ${type}-text-left ${type}-text-right ${type}-text-justify`

    // SET LIST DATA
    if (!list.attr('data-list')) {
      this.#listData.list = []
      list.find('li').each((el) => {
        const item = list.find('li')[el]
        const icon = $(item).find('.icon').length ? $(item).find('.icon').attr('class').replace('icon fa', '') : ''
        const text = $(item).find('span').html()
        this.#listData.list.push({ description: text, icon })
      })
      this.#listData.icon = {
        size: {
          default: this._component.find(this.#iconRef).attr('data-font_size_default'),
          mobile: this._component.find(this.#iconRef).attr('data-font_size_mobile'),
          block:
            this._component.find(this.#iconRef).attr('data-lock') &&
            JSON.parse(this._component.find(this.#iconRef).attr('data-lock')),
        },
        color: this._component.find(this.#iconRef).css('color'),
      }

      const defaultClass = verifyHasClass(this._component.find('li span'), classes('default').split(' '))
      const mobileClass = verifyHasClass(this._component.find('li span'), classes('mobile').split(' '))
      this.#listData.text = {
        alignment: {
          default: defaultClass || 'default-text-left',
          mobile: mobileClass || 'mobile-text-left',
          block:
            this._component.find('li span').attr('data-block-align') &&
            JSON.parse(this._component.find('li span').attr('data-block-align')),
        },
        color: this._component.find('li span').css('color'),
      }
      list.attr('data-list', JSON.stringify(this.#listData))
    } else {
      this.#listData = JSON.parse(list.attr('data-list'))
      if(!this.#listData['item'] || !this.#listData['item'].bottomSpacing) this.#listData['item'] = {bottomSpacing: 10};
    }

    this.iconColor.show(() => {
      return this._component.find(this.#iconRef).css('color') || this.#listData.icon.color
    })
    this.fontSizeIcon.show(() => this._component.find(this.#iconRef))
    this.fontSizeResponsive.show(() => this._component.find(this.#ulRef))
    this.fontSelector.show()
    this.color.show()
    this.alignmentWithIcon.show()
    this.itemSpacing.show()

    this._form.find('#list-icon').hide()
    if (!list.attr('data-list_type') || list.attr('data-list_type') === 'list-icon') {
      list.attr({ 'data-list_type': 'list-icon' })

      this._form.find('#list-icon').show()

      this.#listData.type = 'list-icon'
    } else {
      this.#listData.type = list.attr('data-list_type')
    }

    this._form.find('#list-type').find('.nav-item-custom').removeClass('active')
    this._form.find('#list-type').find(`.nav-item-custom[data-value='${this.#listData.type}']`).addClass('active')
  }

  // HELPERS

  #renderView() {
    let list = ''
    this.#setDataList()
    this.#listData.list.forEach((el, index) => {
      list += /*html*/ `
      <li class="item d-flex align-items-center" style="margin-bottom: ${this.#listData.item.bottomSpacing}px">
        ${this.#renderPrefix(this.#listData.type, el.icon)}
        <span 
          style="color: ${this.#listData.text.color}"
          data-block-align="${this.#listData.text.alignment.block}" 
        >
          ${el.description.replace('<p>', '').replace('</p>', '')}
        </span>
      </li>
      `
    })

    this._component.find(this.#ulRef).html(list)
  }

  #renderPrefix(type, value) {
    const prefixes = {
      'list-icon': () => {
        const currentWidth = this._keditor.options.currentDeviceWidth
        const fontSize = currentWidth !== '100%' ? this.#listData.icon.size.mobile : this.#listData.icon.size.default
        return /*html*/ `
          <i
            data-font_size_default="${this.#listData.icon.size.default}"
            data-font_size_mobile="${this.#listData.icon.size.mobile}"
            data-lock="${this.#listData.icon.size.block}"
            style="margin-right: 13px; font-size: ${fontSize}px; color: ${this.#listData.icon.color}" 
            class="icon fa ${value}"
          ></i>
        `
      },
      none: () => {
        return ''
      },
    }

    return prefixes[type]()
  }

  #changeListType(type) {
    const types = {
      'list-icon': () => {
        this._form.find('#list-icon').show()
        this.#listData.type = 'list-icon'
      },
      none: () => {
        this._form.find('#list-icon').hide()
        this.#listData.type = 'none'
      },
    }

    types[type]()

    this.#renderView()
  }

  #setDataList() {
    const list = this._component.find(this.#ulRef)
    list.attr('data-list', JSON.stringify(this.#listData))
  }
}
