import {
  useBorderColor,
  useBorderRadius,
  useBorderStyle,
  useBorderWidth,
  useDropdown,
  useToggleButton,
} from '../../../microComponents'

const borderRadius = useBorderRadius({
  label: 'Curvatura',
})

const borderWidth = useBorderWidth({
  label: 'Espessura',
})

const borderColor = useBorderColor({
  label: 'Cor',
  isFirst: true,
})
const borderStyle = useBorderStyle({
  label: 'Estilo',
  isFirst: true,
})

const Select = useDropdown({
  label: '',
  customOptions: [
    {
      value: 'default-config',
      text: 'Ir para URL',
      icon: 'fa fa-globe',
    },
    {
      value: 'roll-to-element',
      text: 'Rolar para um elemento da página',
      icon: 'fa fa-arrows-v',
    },
    {
      value: 'open-popup',
      text: 'Abrir Popup da página',
      icon: 'fa fa-exclamation',
    },
  ],
})
const ToggleButton = useToggleButton({ label: 'Abrir em nova aba' })

const LINK_FUNCTION = /*html*/ `
<div class="form-settings-head">
  FUNÇÃO<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group" style="margin: 1rem 0rem">
  <div class="tab-content col-sm-12 ">
    ${Select.template}
    <div class="mt-3" id="default-config">
      <label>Link do Botão</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <button class="form-control dropdown-toggle" id="button-target-label" style="background-color: #e9ecef;"
            type="button" data-value="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="input-group-text">px</span>
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item button-target-type" href="#" data-value="https://">https://</a>
            <a class="dropdown-item button-target-type" href="#" data-value="http://">http://</a>
          </div>
        </div>
        <input type="text" class="form-control" id="button-link" />
      </div>
      ${ToggleButton.template}
      <div class="d-flex col-sm-12 mt-3">
        <label class="col-sm-10 p-0 m-0">Enviar parâmetros da página</label>
        <div class="col-sm-2 p-0 m-0">
          <div class="button-switch col-sm-2">
            <input 
              type="checkbox" 
              id="send-page-params" 
              class="switch" 
            />
            <label for="send-page-params" class="lbl-off"></label>
            <label for="send-page-params" class="lbl-on"></label>
          </div>
        </div>
      </div> 
    </div>
    <!-- link-element -->
    <div class="mt-3" id="roll-to-element">
      <label>Link do Botão</label>
      <div class="input-group">
        <div class="input-group-prepend" style="height: 35px">
          <span class="input-group-text" id="basic-addon1">#</span>
        </div>
        <input 
          style="height: 35px" 
          type="text" 
          id="roll-to-element-config"
          class="form-control" 
          placeholder="ID-do-elemento" 
          aria-label="ID-do-elemento" 
          aria-describedby="basic-addon1"
        />
      </div>
    </div>
  </div>
</div>
`

export const FORM_ADVANCED = /*html*/ `
<div class="border-config">
  <div class="form-settings-head">
    BORDAS DA IMAGEM <span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
  </div>
  <div class="form-group" style="margin: 1rem 0rem">
    <div class="tab-content">
      <div class="d-flex flex-wrap">
        ${borderColor.template}
        ${borderStyle.template}
      </div>
      <div class="d-flex flex-wrap">
        ${borderRadius.template}
        ${borderWidth.template}
      </div>
    </div>
  </div>
  ${LINK_FUNCTION}
</div>
`
