import "../styles/keditor-component-form.less";

import $ from "jquery";
import KEditor from "keditor";
import {
  verifyHasClass,
  textOptions,
  textEditor,
  componentSpacing,
  mobileFontSize,
  changeOneClass,
} from "./helpers/helpers";
import {
  dropdownIconConfig,
  textConfig,
  removeItem,
  colorConfig,
  iconConfig,
  newItem,
} from "./helpers/configList";
import {
  templateForm,
  fontSizeOptions,
  hideMobileDesktop,
} from "./helpers/form.settings";

import initTimeDelay from "./helpers/timeDelay/initSettings";
import showTimeDelay from "./helpers/timeDelay/showSettings";
import {initAnimations, showAnimations} from './helpers/animations'

import { filter } from "./helpers/completeListIcons";
import { hmltIconsElementString, allIcons } from "./helpers/completeListIcons";

import ColorPicker from "./helpers/colorPicker";
const colorPicker = new ColorPicker();

const contents = hmltIconsElementString(); // carrega todos os icones
let itemId = 0; // controle interno do ID do item
let arrIds = [];
let CKEDITOR;
KEditor.components["list"] = {
  emptyContent:
    '<p class="text-muted lead text-center item-0"><br />[Nenhum item encontrado, favor adicionar um novo item]<br /><br /></p>',

  settingEnabled: true,

  settingTitle: "Lista com Título",

  options: textOptions(true),

  init: function (contentArea, container, component, keditor) {
    let self = this;
    let options = self.options;
    arrIds = [];

    let componentContent = component.find(".keditor-component-content");
    let listContent = component.find(".list-content");
    if (listContent.length === 0) {
      componentContent.append(
        `<div class="list-content" data-type="container-content">${self.emptyContent}</div>`
      );
    } else {
      let listLength = listContent.children().last().attr("class").split(" ");
      listLength = listLength[listLength.length - 1];
      const lastItem = listLength[listLength.length - 1]; // pega o ultimo item da lista

      itemId = listContent.find("p") === self.emptyContent ? 0 : lastItem;
    }

    let i = 1;
    component.find(".media").each(function () {
      // if (component.find(`div.item:nth-child(${i})`).length) {
      const compView = $(this); //component.find(`div.item:nth-child(${i})`);

      arrIds.push({
        id: i,
        component: compView,
      });
      // }
      i++;
    });

    textEditor(
      ".keditor-component-content",
      component,
      keditor,
      options,
      contentArea,
      container,
      ".icon-text-group",
      true
    );
    CKEDITOR = keditor.iframeWindow.window.CKEDITOR
  },

  getContent: function (component, keditor) {
    let componentContent = component.find(".keditor-component-content");
    let id = componentContent.attr("id");
    let editor = CKEDITOR.instances[id];
    if (editor) {
      return editor.getData();
    } else {
      return componentContent.html();
    }
  },

  destroy: function (component, keditor) {
    let id = component.find(".keditor-component-content").attr("id");
    CKEDITOR.instances[id] && CKEDITOR.instances[id].destroy();
  },

  initSettingForm: function (form, keditor) {
    const component = () => keditor.getSettingComponent();

    let self = this;
    form.html(templateForm({children: formLayout}));

    initTimeDelay.call(self, form, keditor);
    initAnimations(form,keditor)

    // Icon Size
    const iconSize = form.find("#icon-size");
    iconSize.on("change", function () {
      const icons = component().find(".media").find("i");
      icons.removeClass("fz-lg fz-2x fz-3x fz-4x fz-5x");
      icons.addClass(this.value);
    });

    const itemSpacing = form.find("#item-spacing");
    itemSpacing.on("change", function () {
      const firstChild = component().find(".list-content").children().first();

      component()
        .find(".media")
        .not(firstChild)
        .css("margin-top", `${this.value}px`);
    });

    //Color Icon
    colorPicker.init(form, (color) => {
      const icon = component().find(".icon-text");
      icon.css("color", color);
    });

    // // Tamanho da fonte do titulo
    const fontSize = form.find("#btn-font-size").on("input", function () {
      const text = component().find(".item-title");

      form.find("#btn-font").val(this.value);
      text.css("font-size", `${this.value}px`);
    });

    // // Tamanho da fonte do texto
    const fontSizeText = form
      .find("#btn-font-size-text")
      .on("input", function () {
        const text = component().find(".item-text").find("p");

        form.find("#btn-font-text").val(this.value);
        const value = this.value;
        text.css({ fontSize: "" });
        text.find("span").css({ fontSize: "" });
        text.each(function () {
          const item = $(this).children().last();
          item.css("fontSize", value + "px");
        });
      });

    const addItem = form.find("#plus-item");
    const itemGroup = form.find("#item-group");
    // Adicionar um novo item na lista
    addItem.on("click", function () {
      itemId++;
      const component = keditor.getSettingComponent();

      const list = component.find(`.list-content`);
      const margin =
        list.find("p").html() !== $(self.emptyContent).html()
          ? `style="margin-top: ${itemSpacing.val()}px"`
          : "";

      const random = Math.floor(Math.random() * 9999);
      const newItemId = !list.find(".item-0").length
        ? random //list.children().length + 1
        : 1;

      const listItem = `
      <div 
        class="media list-item-${newItemId}" 
        ${margin}
      >
        <i 
          id="icon-${newItemId}" 
          class="fa fa-check ${iconSize.val() || "fz-3x"} icon-text mr-3"
          style="color: ${form.find("#input-color").find("input").val()}"
        ></i>
        <div class="media-body">
          <h5 
          style="font-size: ${fontSize.val()}px"
            class="item-title-${newItemId} item-title ${
        form.find(".mobile-font-size").val() || "font-20"
      }"
          >
            Titulo
          </h5>
          <div class="item-text-${newItemId} item-text">
            <p>
              <span 
                style="font-size: ${fontSizeText.val()}px"
                class="${
                  form.find(".mobile-font-size-text").val() || "font-16"
                }"
              >
                Clique aqui para editar
              </span>
            </p>
          </div>
        </div>
      </div>
      `;

      if (list.find(".item-0").length) {
        list.html(listItem);
        form.find(".list-config").show();
      } else list.append(listItem);

      const lastItemId = list.children().length;

      arrIds.push({
        id: random, // arrIds.length + 1,
        component: component.find("div.media").eq(lastItemId - 1), //component.find(`div.item:nth-child(${lastItemId})`),
      });

      // itemGroup.append(newItem(itemId));
      itemGroup.append(
        formListItem(random, '<i class="fa fa-check" ></i>', lastItemId)
      );

      formIcon(form, keditor, contents, random);
      formRemoveItem(form, keditor, random, self.emptyContent);
    });

    mobileFontSize(".item-title", 20, form, null, keditor, 2);
    mobileFontSize(
      ".item-text",
      20,
      form,
      null,
      keditor,
      2,
      ".mobile-font-size-text"
    );

    //Espaçamentos
    componentSpacing(".list-content", form, null, keditor, 2);

    hideMobileDesktop(form, null, keditor, ".list-content", "edit");
  },

  showSettingForm: function (form, component, keditor) {
    showTimeDelay.call(self, form, component, keditor);
    showAnimations(form,keditor)
    const componentContent = component
      .find(".keditor-component-content")
      .children()
      .children();

    mobileFontSize(
      componentContent.find(".item-title"),
      20,
      form,
      component,
      keditor,
      1
    );

    mobileFontSize(
      component.find(".item-text").find("span"),
      20,
      form,
      component,
      keditor,
      1,
      ".mobile-font-size-text"
    );

    const self = this;
    const listContent = component.find(".list-content");
    const itemGroup = form.find("#item-group").html("");
    // const inputColor = form.find("#input-color");
    const iconSize = form.find("#icon-size");
    const itemSpacing = form.find("#item-spacing");

    const inputFontSize = form.find("#btn-font-size");
    const labelFontSize = form.find("#btn-font");
    const inputFontSizeText = form.find("#btn-font-size-text");
    const labelFontSizeText = form.find("#btn-font-text");

    const icon = component.find(".icon-text");
    const span = component.find(".item-title");
    const text = component.find(".item-text").find("p").find("span");

    inputFontSize.val(span.length ? span.css("font-size").split("px")[0] : 20);
    labelFontSize.val(span.length ? span.css("font-size").split("px")[0] : 20);

    inputFontSizeText.val(
      text.length ? text.css("font-size").split("px")[0] : 18
    );
    labelFontSizeText.val(
      text.length ? text.css("font-size").split("px")[0] : 18
    );

    // lista de itens
    let listLength = listContent.children().last().attr("class").split(" ");
    listLength = listLength[listLength.length - 1];
    const lastItem = listLength[listLength.length - 1]; // pega o ultimo item da lista
    // faz o append dos itens
    itemId = listContent.find("p") === this.emptyContent ? 0 : lastItem;
    iconSize.val(
      verifyHasClass(icon, ["fz-lg", "fz-2x", "fz-3x", "fz-4x", "fz-5x"]) ||
        "fz-3x"
    );

    arrIds = [];

    if (!component.find(".item-0").length) {
      itemSpacing.val(
        component.find(".media:not(:first)").length
          ? component
              .find(".media:not(:first)")
              .css("margin-top")
              .split("px")
              .join("")
          : 8
      );

      let i = 0;
      component.find(".media").each(function () {
        // const compView = component.find(`div.media:nth-child(${i + 1})`);
        const compView = component.find(`div.media`).eq(i);
        // arrIds[i].component = compView;
        arrIds.push({
          id: i + 1,
          component: compView,
        });
        i++;
      });

      arrIds.forEach((el, index) => {
        const compView = el.component;
        const icon = compView.find("i");
        const dropdownIcon = verifyHasClass(icon, Object.keys(allIcons));
        itemGroup.append(
          formListItem(el.id, `<i class="fa ${dropdownIcon}" ></i>`, index + 1)
        );

        formIcon(form, keditor, contents, el.id);
        formRemoveItem(form, keditor, el.id, self.emptyContent);
      });
    }
    //Color Icon
    colorPicker.show(form, icon.css("color") || "rgb(33, 37, 41)");

    // Espaçamentos
    componentSpacing(listContent, form, component, keditor, 1);

    hideMobileDesktop(form, listContent, keditor, null, "");
  },
};

const formLayout = `
<div class="form-settings-head">
  Layout<span
    ><i class="fa fa-caret-down" aria-hidden="true"></i
  ></span>
</div>
<div class="form-group row" style="margin: 1rem 0rem">
  <div class="tab-content col-sm-12">
    <div class="list-config">
      ${colorConfig}
      
      <label for="icon-size" >Tamanho dos Icones</label>
      <select id="icon-size" class="form-control">
        <option value="fz-lg">Muito Pequeno</option>
        <option value="fz-2x">Pequeno</option>
        <option value="fz-3x">Médio</option>
        <option value="fz-4x">Grande</option>
      </select>

      <div class="tab-content mt-3">
        <label>Tamanho do Título</label>

        <div class="row">
          <div class="col-sm-9" style="display: flex; margin-right: 0px !important;">
            <input  id="btn-font-size" type="range" style="width: 100%" max="72" min="10" />
          </div>

          <div class="col-sm-3">
            <div class="input-group input-group-sm">
              <input type="text" id="btn-font" class="form-control" value="00" readonly />
            </div>
          </div>
        </div>
      </div>

      <div class="tab-content mt-3">
        <label>Tamanho do Texto</label>

        <div class="row">
          <div class="col-sm-9" style="display: flex; margin-right: 0px !important;">
            <input  id="btn-font-size-text" type="range" style="width: 100%" max="72" min="10" />
          </div>

          <div class="col-sm-3">
            <div class="input-group input-group-sm">
              <input type="text" id="btn-font-text" class="form-control" value="00" readonly />
            </div>
          </div>
        </div>
      </div>

      <hr />

      <label for="item-spacing">Espaçamento entre os itens</label>
      <input
        type="number"
        class="form-control"
        id="item-spacing"
        min="0"
      />
      
      <hr />
    </div>

    <button 
      class="mt-2 btn btn-primary w-100"
      id="plus-item"
    >
      Adicionar item
    </button>

    <hr />

    <div id="item-group" ></div>
    
  </div>
</div>
<div class="form-settings-head">
  Responsivo<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>

  <div class="form-group row" style="margin: 1rem 0rem">
    <div class="tab-content col-sm-12" >
      <label for="input-color">Tamanho da Fonte no Celular</label>
      <div class="tab-content">
        <label for="input-color">Título</label>
        <select class="form-control mobile-font-size">
            ${fontSizeOptions(20)}
        </select>
      </div>

      <div class="tab-content mt-3">
        <label for="input-color mt-3">Texto</label>
        <select class="form-control mobile-font-size-text">
            ${fontSizeOptions(20)}
        </select>
      </div>
    </div>
  </div>
`;

function formListItem(id, icon, inputPos) {
  return `
  <div 
    class="d-flex flex-nowrap mt-2 item-config"
    id="item-${id}"
  >
    <div 
      class="dropdown mr-1 col-sm-4 mx-0 px-0" 
      id="dropdown-${id}"
    >
      <button
        class="btn btn-primary
          dropdown-toggle d-flex justify-content-between
          align-items-center w-100 h-100"
        type="button"
        id="item-icon-${id}"
        data-toggle="dropdown"
        aria-haspopup="true" 
        aria-expanded="false" 
        data-offset="-40,0"
      >
      ${icon}
      </button>
      <div 
        class="dropdown-menu col-sm-12" 
        aria-labelledby="item-icon" 
      >
        <div class="dropdown-header">
          <input
            type="search"
            class="form-control searchIcon"
            placeholder="address book"
            autofocus="autofocus"
          />
        </div>
        <div class="menuItems" id="menuItems-${id}"></div>
      </div>
    </div>

    <input 
      id="text-${id}"
      type="text"
      class="form-control text-field"
      placeholder="Item da lista"
      value="item #${inputPos}"
      readonly
    />

    <div class="col-sm-2 h-100 mx-0 px-0" >
      <button 
        class="btn btn-danger text-white remove-item" 
      >
        <i class="fa fa-trash fz-lg"></i>
      </button>
    </div>
  </div>`;
}

function formIcon(form, keditor, content, id) {
  // const items = form.find(`.menuItems`);
  const items = form.find(`#menuItems-${id}`);
  const search = form.find(".searchIcon");
  const dropdownButton = form.find(`#item-icon-${id}`);
  items.append(content);

  search.on("keyup", function () {
    filter(this.value, items);
  });

  items.find(".dropdown-item").on("click", function () {
    const component = keditor.getSettingComponent();
    const hasId = arrIds.find((el) => el.id == id);

    if (hasId) {
      const item = hasId.component;
      const icon = item.find("i");

      let type = $(this).attr("class").split(" fa ");

      if (icon.length !== 0) {
        const classString = changeOneClass(icon, "fa-", type[1]);
        icon.removeClass();
        icon.addClass(classString);
      } else {
        item.prepend(`
            <i class="fa ${type[1]} fz-2x icon-text mr-3" ></i>
          `);
      }

      dropdownButton.html(`<i class="fa ${type[1]}" ></i>`);
    }
  });
}

function formRemoveItem(form, keditor, id, msg = "") {
  const item = form.find(`#item-${id}`);
  item.find(`.remove-item`).on("click", function () {
    const component = keditor.getSettingComponent();
    // const itemView = component.find(`div.item:nth-child(${id})`);
    const listLength = component.find(".list-content").children().length;

    const hasId = arrIds.find((el) => el.id == id);

    if (arrIds.indexOf(hasId) != -1) {
      arrIds.splice(arrIds.indexOf(hasId), 1);

      component.find(hasId.component).remove();
      item.remove();
      const textField = form.find(`.text-field`); //.find("input");
      let i = 1;
      textField.each(function () {
        this.value = "item #" + i;
        i++;
      });

      arrIds = arrIds.map((el, index) => {
        const newId = el.id; //index + 1;

        form.find(`.item-config`).eq(index).attr("id", `item-${newId}`);
        return {
          id: newId,
          component: el.component,
        };
      });
    }

    if (listLength === 1) {
      component.find(".list-content").html(`
        <li 
          class="d-flex item align-items-center justify-content-center item-0"
        >${msg}</li>`);

      form.find(".list-config").hide();

      return;
    }
  });
}
