import KEditor from 'keditor'

import VideoComponent from './containers/video'

import { hideMobileDesktop } from './helpers/form.settings'

import initTimeDelay from './helpers/timeDelay/initSettings'
import showTimeDelay from './helpers/timeDelay/showSettings'
import {initAnimations, showAnimations} from './helpers/animations'

import { componentSpacing, applyAlignment } from './helpers/helpers'

let videoComponent = new VideoComponent()

const DEFAULT_PANDA_CONTROLS = 'play-large,play,progress,current-time,volume,captions,settings,pip,fullscreen'

KEditor.components['youtube'] = {

  settingEnabled: true,
  settingTitle: 'Vídeo',

  init: function (contentArea, container, component, keditor, action = '') {
    videoComponent.keditor = keditor
    videoComponent.component = component
    videoComponent.create(action)
    
    let iframe = component.find('iframe')
    let wrapper = iframe.parent()
    keditor.initIframeCover(iframe, wrapper)
  },
  
  destroy: function (component, keditor) {
    videoComponent.keditor = keditor
    videoComponent.component = component
    videoComponent.delete()
  },

  initSettingForm: function (form, keditor) {
    form.html(videoComponent.template)
    videoComponent.keditor = keditor
    videoComponent.form = form
    videoComponent.init()

    initTimeDelay.call(self, form, keditor)
    initAnimations(form, keditor)
    
    //Set this so we can use it inside the events
    let thisObj = this

    const videoType = form.find('#video-type').on('change', function () {
      form.find('#video-embed').attr('placeholder', `Custom embed`)
      form.find('.url-video').html('Tag do vídeo')
      form.find('.video-branding').hide()
      form.find('.video-control').hide()
      form.find('.video-autoplay').show()
      form.find('.video-fullscreen').hide()
      form.find('#video-embed-id').hide()
      form.find('#video-embed-id-label').hide()
      form.find('#video-embed-id').val('')

      const value = this.value.split('-type')[0]

      const type = value.charAt(0).toUpperCase() + value.slice(1)
      form.find('#video-embed').attr('placeholder', `Digite aqui a URL do ${type}`)
      form.find('.url-video').html(`URL do ${type}`)

      if (this.value == 'youtube-type')
      {
        form.find('.video-branding').show()
        form.find('.video-control').show()
      }
      else if (this.value == 'panda-type')
      {
        form.find('.video-autoplay').hide()
        form.find('.video-fullscreen').show()
        form.find('#video-embed-id-label').show()
        form.find('#video-embed-id').show()
        form.find('#video-embed-id').val(thisObj.getPandaVideoIdByURL(form.find('#video-embed').val()))
      }

      return this.value
    })

    form.find('#video-embed').on('change', function (e) {
      e.preventDefault()
      const type = videoType.val()

      let inputData = this.value
      let regex
      let match
      let embed
      let videoId = ''
      if (type == 'youtube-type')
      {
        regex = /^(?:http(?:s)?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user)\/))([^\?&\"'>]+)/
        match = inputData.match(regex)
        embed = match && match[1] ? `https://www.youtube.com/embed/${match[1]}` : ''
      } else if (type == 'vimeo-type')
      {
        // regex = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
        regex = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i
        match = inputData.match(regex)
        embed = match && match[1] ? `https://player.vimeo.com/video/${match[1]}?byline=0&portrait=0&badge=0` : ''
        // } else {
        //   regex = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/i
        //   match = inputData.match(regex)
        //   embed = match ? match[0] : ''
      }
      else if (type == 'panda-type')
      {
        regex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}pandavideo\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/i
        match = inputData.match(regex)
        embed = match ? match[0] + '&controls=' + DEFAULT_PANDA_CONTROLS : ''

        if (form.find('#video-fullscreen').is(':checked') === false)
        {
          embed = embed.replace(',fullscreen', '')
        }

        videoId = thisObj.getPandaVideoIdByURL(embed)

        form.find('#video-embed').val(embed)
        form.find('#video-embed-id').val(videoId)
      }

      if (embed != '' && (type == 'youtube-type' || type == 'vimeo-type' || type == 'panda-type')) {
        keditor.getSettingComponent().find('.embed-responsive-item').attr('src', embed)
      // } else if (embed != '' && type == 'custom') {
      //   keditor.getSettingComponent().find('.embed-responsive').html(embed)
      //   keditor.getSettingComponent().find('.embed-responsive').find('iframe').addClass('embed-responsive-item')
      } else {
        let msg = 'URL inválida'
        if (inputData == '') msg = 'Favor preencher o campo Vídeo Embed'
        //alert(msg)
      }
    })

    let btn169 = form.find('.btn-youtube-169')
    btn169.on('click', function (e) {
      e.preventDefault()

      keditor
        .getSettingComponent()
        .find('.embed-responsive')
        .removeClass('embed-responsive-4by3')
        .addClass('embed-responsive-16by9')
    })

    let btn43 = form.find('.btn-youtube-43')
    btn43.on('click', function (e) {
      e.preventDefault()

      keditor
        .getSettingComponent()
        .find('.embed-responsive')
        .removeClass('embed-responsive-16by9')
        .addClass('embed-responsive-4by3')
    })

    let chkAutoplay = form.find('#youtube-autoplay')
    chkAutoplay.on('click', function () {
      let embedItem = keditor.getSettingComponent().find('.embed-responsive-item')
      let currentUrl = embedItem.attr('src')
      let videoId = embedItem.attr('data-video-id')

      let auxUrl = '?autoplay=' + (chkAutoplay.is(':checked') ? 1 : 0)

      if (chkBranding.is(':checked') || chkControls.is(':checked')) {
        const and = '&'

        if (chkBranding.is(':checked')) auxUrl += and + 'showinfo=0'
        if (chkControls.is(':checked')) auxUrl += and + 'controls=0'
      }

      let newUrl = videoType.val() !== 'panda-type' ? currentUrl.replace(/(\?.+)+/, '') + auxUrl : currentUrl + auxUrl

      embedItem.attr('src', newUrl)
      form.find('#video-embed').val(newUrl)
      form.find('#video-embed-id').val(videoId)
    })

    let chkControls = form.find('#youtube-controls')
    chkControls.on('click', function () {
      let embedItem = keditor.getSettingComponent().find('.embed-responsive-item')
      let currentUrl = embedItem.attr('src')

      let auxUrl = '?controls=' + (chkControls.is(':checked') ? 0 : 1)

      if (chkBranding.is(':checked') || chkAutoplay.is(':checked')) {
        const and = '&'

        if (chkBranding.is(':checked')) auxUrl += and + 'showinfo=0'
        if (chkAutoplay.is(':checked')) auxUrl += and + 'autplay=1'
      }

      let newUrl = currentUrl.replace(/(\?.+)+/, '') + auxUrl

      embedItem.attr('src', newUrl)
      form.find('#video-embed').val(newUrl)
    })

    let chkBranding = form.find('#youtube-branding')
    chkBranding.on('click', function () {
      let embedItem = keditor.getSettingComponent().find('.embed-responsive-item')
      let currentUrl = embedItem.attr('src')

      let auxUrl = '?showinfo=' + (chkBranding.is(':checked') ? 0 : 1)

      if (chkControls.is(':checked') || chkAutoplay.is(':checked')) {
        const and = '&'

        if (chkControls.is(':checked')) auxUrl += and + 'controls=0'
        if (chkAutoplay.is(':checked')) auxUrl += and + 'autplay=1'
      }

      let newUrl = currentUrl.replace(/(\?.+)+/, '') + auxUrl

      embedItem.attr('src', newUrl)
      form.find('#video-embed').val(newUrl)
    })

    let chkAllowFullscreen = form.find('#video-fullscreen')
    chkAllowFullscreen.on('click', function ()
    {
      let embedItem = keditor.getSettingComponent().find('.embed-responsive-item')
      let currentUrl = embedItem.attr('src')

      
      let allow = chkAllowFullscreen.is(':checked')

      embedItem.attr('allowfullscreen', allow)

      if (allow)
      {
        if(currentUrl.indexOf('fullscreen') === -1)
        {
          currentUrl += ',fullscreen'
        }
      }
      else
      {
        currentUrl = currentUrl.replace(',fullscreen', '')
      }

      embedItem.attr('src', currentUrl)

      form.find('#video-embed').val(currentUrl)
    })

    form.find('#optional-width').on('change', function () {
      keditor.getSettingComponent().find('.embed-responsive').css('width', `${this.value}`)
    })

    form.find('#optional-height').on('change', function () {
      keditor.getSettingComponent().find('.embed-responsive').css('height', `${this.value}`)
    })

    //Espaçamentos
    componentSpacing('.youtube-wrapper', form, null, keditor, 2)

    hideMobileDesktop(form, null, keditor, '.youtube-wrapper', 'edit')
  },

  showSettingForm: function (form, component, keditor) {
    videoComponent.component = component
    videoComponent.show()

    showTimeDelay.call(self, form, component, keditor)
    showAnimations(form,keditor)
    let embedItem = component.find('.embed-responsive-item')
    let ytWrapper = component.find('.youtube-wrapper')
    let chkAutoplay = form.find('#youtube-autoplay')
    let chkBranding = form.find('#youtube-branding')
    let chkControls = form.find('#youtube-controls')
    let chkAllowFullscreen = form.find('#video-fullscreen')
    let src = embedItem.attr('src')

    const videoType = form.find('#video-type')
    const videoEmbed = form.find('#video-embed')
    const videoId = form.find('#video-embed-id')
    videoId.hide()
    form.find('#video-embed-id-label').hide()

    form.find('#optional-width').val(embedItem.css('width').split('px')[0])
    // form.find("#optional-width").val("");

    videoEmbed.val(component.find('.embed-responsive').html())
    form.find('.url-video').html('Tag do vídeo')
    form.find('.video-branding').hide()
    form.find('.video-control').hide()

    if (src.indexOf('youtube.com') != -1) {
      form.find('.video-branding').show()
      form.find('.video-control').show()
      form.find('.video-autoplay').show()
      videoType.val('youtube-type')
      form.find('.url-video').html('URL do Youtube')
      videoEmbed.val(src)
    }
    if (src.indexOf('vimeo.com') != -1)
    {
      form.find('.url-video').html('URL do Vimeo')
      form.find('.video-autoplay').show()
      videoEmbed.val(src)
      videoType.val('vimeo-type')
    }
    if (src.indexOf('pandavideo.com') != -1)
    {
      form.find('.url-video').html('URL do Panda')
      form.find('.video-autoplay').hide()
      form.find('.video-fullscreen').show()
      videoEmbed.val(src)
      videoId.show()
      form.find('#video-embed-id-label').show()
      
      videoId.val(this.getPandaVideoIdByURL(src))

      videoType.val('panda-type')
    }

    chkAutoplay.prop('checked', src.indexOf('autoplay=1') !== -1)
    chkBranding.prop('checked', src.indexOf('showinfo=0') !== -1)
    chkControls.prop('checked', src.indexOf('controls=0') !== -1)
    chkAllowFullscreen.prop('checked', embedItem.attr('allowfullscreen') === 'true')

    // Espaçamentos
    componentSpacing(ytWrapper, form, component, keditor, 1)

    hideMobileDesktop(form, ytWrapper, keditor, null, '')
  },

  getPandaVideoIdByURL: function (url)
  {
    let regexId = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i
    let matchId = url.match(regexId)
    let videoRegexId = matchId ? matchId[0] : ''

    return videoRegexId
  },
}
