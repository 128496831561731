import openGalleryButton from '../../../../helpers/gallery.template'
import {
  useFontSizeResponsive,
  useText,
  useFontSelector,
  useFontStyle,
  useImageSelect,
  usePosition,
  useRating,
  useColor,
  useAlignment,
  useWidth,
  useShowElement,
  useImageWidth,
} from '../../../../microComponents'

// Imagem
const ImageWidth = useImageWidth({
  label: 'Largura',
  fullWidth: true,
})

const PositionImage = usePosition({
  customFormRef: 'image-position',
  label: 'Posição',
  fullWidth: true,
})

const ShowImage = useShowElement({
  customFormRef: 'show-img',
  label: 'Mostrar',
  isFirst: true,
})

// Classificação
const RatingAlignment = useAlignment({
  customFormRef: 'rating-alignment',
  label: 'Alinhamento',
})

const ShowRating = useShowElement({
  customFormRef: 'show-rating',
  label: 'Mostrar',
  isFirst: true,
})

const RatingColor = useColor({
  customFormRef: 'rating-color',
  label: 'Cor',
})

const PositionRating = usePosition({
  customFormRef: 'rating-position',
  label: 'Posição',
})

const Rating = useRating({
  label: '',
})

// Nome do Testemunho
const TextColor = useColor({
  customFormRef: 'text-color',
  label: 'Cor',
  isFirst: false,
})

const TextAlignment = useAlignment({
  customFormRef: 'text-alignment',
  label: 'Alinhamento',
})

const PositionText = usePosition({
  customFormRef: 'testimonial-position',
  label: 'Posição',
})

const FontSizeTitle = useFontSizeResponsive({
  customFormRef: 'font-size-Title',
  label: 'Tamanho',
})

const TextTestimonial = useText({
  label: 'Nome',
})

const FontStyle = useFontStyle({
  label: 'Peso',
})

const FontSelector = useFontSelector({ label: 'Fonte' })

const FontSizeTestimonial = useFontSizeResponsive({
  customFormRef: 'font-size-Testimonial',
  label: 'Tamanho',
  isFirst: true,
})

export default /*html */ `
<div class="form-settings-head">
  IMAGEM<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group " style="margin: 1rem 0rem">
  ${ShowImage.template}
  ${openGalleryButton()}
  <div class="tab-content photo-config">
    <div class="d-flex">
      ${ImageWidth.template}
    </div>
  </div>
  ${PositionImage.template}
</div>

<div class="form-settings-head">
  TESTEMUNHO<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group" style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    ${TextTestimonial.template}
    ${FontStyle.template}
    ${FontSelector.template}
    ${FontSizeTitle.template}
    ${PositionText.template}
    ${TextAlignment.template}
    ${TextColor.template}
  </div>
</div>

<div class="form-settings-head">
  AVALIAÇÃO<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group row" style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    ${ShowRating.template}
    ${Rating.template}
    ${RatingColor.template}
    ${PositionRating.template}
    ${RatingAlignment.template}
  </div>
</div>
<div class="form-settings-head">
  DEPOIMENTO<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group row" style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    ${FontSizeTestimonial.template}
  </div>
</div>
`
