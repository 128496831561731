import {
  useAlignmentBlock,
  useBorderColor,
  useBorderRadius,
  useBorderStyle,
  useBorderWidth,
  useImageWidth,
  useDropdown,
  useToggleButton,
} from '../../../microComponents'
import BaseComponent from '../../baseComponent'
import { IMAGE_PANEL, IMAGE_TARGET } from '../constant'
import openGalleryButton from '../../../helpers/gallery.template.js'
import { MODAL_ID } from '../../../../ReactComponents/Popup/shared/constants'
import { DEFAULT_ACTION_SELECT } from '../../../../constants'

export default class PhotoComponent extends BaseComponent {
  #image = IMAGE_TARGET
  #panel = IMAGE_PANEL
  #borderConfig = '.border-config'
  #anchor = '.image-anchor'
  #galleryBtnWrapper = '#galerybtnwrapper'
  #alt = '#image-alt'

  #options = [
    {
      value: 'default-config',
      text: 'Ir para URL',
      icon: 'fa fa-globe',
    },
    {
      value: 'roll-to-element',
      text: 'Rolar para um elemento da página',
      icon: 'fa fa-arrows-v',
    },
    {
      value: 'open-popup',
      text: 'Abrir Popup da página',
      icon: 'fa fa-exclamation',
    },
  ]

  #elements = {
    sendParams: {
      data: null,
      selector: '#send-page-params',
    },
    rollToElement: {
      data: null,
      selector: '#roll-to-element-config',
    },
    buttonLink: {
      data: null,
      selector: '#button-link',
    },
    dropdownLabel: {
      data: null,
      selector: '#button-target-label',
    },
  }

  constructor() {
    super()
  }

  set form(newForm) {
    this._form = newForm
    const useComponents = [
      { config: useBorderWidth, target: () => this.getRef(this.#image) },
      { config: useBorderColor, target: () => this.getRef(this.#image) },
      { config: useBorderRadius, target: () => this.getRef(this.#image) },
      { config: useBorderStyle, target: () => this.getRef(this.#image) },
      { config: useImageWidth, target: () => this.getRef(this.#image) },
      { config: useAlignmentBlock, target: () => this.getRef(this.#panel) },
      { config: useDropdown, target: () => this.getRef(this.#panel).find(this.#anchor) },
      { config: useToggleButton, target: () => this.getRef(this.#panel).find(this.#anchor) },
    ]

    useComponents.map((component) => {
      const aux = component.config({
        customFormRef: component.customFormRef,
        form: this._form,
        keditor: this._keditor,
        elementRef: component.target,
      })
      this[aux.componentName] = aux
    })

    Object.keys(this.#elements).map((key) => {
      this.#elements[key].data = this._form.find(this.#elements[key].selector)
    })
  }

  init() {
    if (!this._keditor || !this._form) return

    this.borderWidth.init()
    this.borderRadius.init()
    this.borderColor.init()
    this.borderStyle.init()
    this.toggleButton.init()
    this.dropdown.init(
      this.#setFunctionality({
        form: this._form,
        options: this.#options,
      })
    )

    this.#elements.rollToElement.data.on('keyup', (event) => {
      const {
        target: { value },
      } = event
      const newValue = '#' + value.replace('#', '')
      const anchor = this.getRef(this.#panel).find(this.#anchor)
      const image = this.getRef(this.#image)

      if (anchor.length) {
        anchor.removeAttr('target')
        anchor.attr({
          [this.#setDataAtribute('roll-to-element')]: newValue,
          href: newValue,
        })
        if (!value.length) image.unwrap()
      } else {
        image.wrap(/*html*/ `
          <a 
            class='image-anchor' 
            data-element-target="${newValue}"
            data-config-type="roll-to-element"
            href='${newValue}'
          ></a>`)
      }
    })

    this.#elements.buttonLink.data.on('keyup', (event) => {
      const btnTargetLabel = this.#elements.dropdownLabel.data
      const anchor = this.getRef(this.#anchor)
      const image = this.getRef(this.#image)

      let value = event.target.value

      const valueValidation = this.#validationHref(value)
      let target = ''

      if (valueValidation == '#' || !valueValidation) anchor.removeAttr('target')
      else if (this._form.find('#switch-open-new-page').is(':checked')) {
        target = '_blank'
      }

      if (valueValidation) {
        btnTargetLabel.html(valueValidation != '#' ? valueValidation : 'https://')
        btnTargetLabel.attr('data-value', valueValidation)
        const vew = value.replace(valueValidation, '')
        // $(this).val(vew);
        value = valueValidation + vew
      } else {
        btnTargetLabel.html('https://')
        btnTargetLabel.attr('data-value', 'https://')
        if (value.length) value = 'https://' + value
      }

      if (anchor.length) {
        anchor.removeAttr('target')
        anchor.attr({
          [this.#setDataAtribute('default-config')]: value,
          href: value,
          target,
        })
        if (!value.length) image.unwrap()
      } else {
        image.wrap(/*html*/ `
          <a 
            class='image-anchor' 
            data-default-href="${value}"
            data-config-type="default-config"
            href='${value}'
            target="${target}"
          ></a>`)
      }
    })

    // Enviar parametros
    this.#elements.sendParams.data.on('click', (event) => {
      const anchor = this.getRef(this.#panel).find(this.#anchor)
      const image = this.getRef(this.#image)

      if (!anchor.length) {
        image.wrap(/*html*/ `
          <a 
            class='image-anchor'
            data-send-params="${$(event.target).is(':checked')}"
            onclick="submitAction(event)"
          ></a>`)
      }

      if ($(event.target).is(':checked')) {
        anchor.removeAttr('href')
        anchor.attr({
          'data-send-params': $(event.target).is(':checked'),
          onClick: `submitAction(event)`,
        })
      } else {
        if (anchor.attr('data-default-href')) {
          anchor.attr({
            'data-send-params': $(event.target).is(':checked'),
            href: anchor.attr('data-default-href') || '',
          })
        }

        anchor.removeAttr('onClick')
      }
    })
  }

  show() {
    if (!this._component) return

    //Show Galery Button correct State
    this._form.find(this.#galleryBtnWrapper).html(
      openGalleryButton(
        false, 
        false, 
        {}, 
        this.getRef(this.#image).attr('src') || '' //Load img if already have
      )
    )

    let anchorRef = this.getRef(this.#anchor)
    if (!anchorRef.length)
    {
      this.getRef(this.#image).wrap(/*html*/ `
        <a
          class='image-anchor'
          data-config-type="none"
        ></a>`)
      
      anchorRef = this.getRef(this.#anchor)

      this._form.find('#dropdown-set-functionality').html(DEFAULT_ACTION_SELECT)

      this.#switchVisibility('none')
    }

    if (!this.getRef(this.#image).attr('src')) return this._form.find(this.#borderConfig).css('display', 'none')

    this._form.find(this.#borderConfig).css('display', 'block')
    this._form.find(this.#alt).val(this.getRef(this.#image).attr('alt'))

    this.borderWidth.show()
    this.borderRadius.show()
    this.borderColor.show()
    this.borderStyle.show()
    this.toggleButton.show()
    this.dropdown.show(
      this.#getFunctionalityValue({
        element: anchorRef,
        form: this._form,
        options: this.#options,
      })
    )

    // ANCHOR VALUES
    const value = anchorRef.attr('data-config-type') || 'default-config'
    const validationHref = this.#validationHref(anchorRef.attr('href'))
    const target = validationHref || ''
    const elementTarget = anchorRef.attr('data-element-target') || '#'
    const defaultHref = anchorRef.attr('data-default-href') || 'https://'
    const sendParams = anchorRef.attr('data-send-params') && JSON.parse(anchorRef.attr('data-send-params'))

    if (value != 'default-config') anchorRef.removeAttr('onClick')
    else if (sendParams) {
      anchorRef.removeAttr('href')
      anchorRef.attr('onClick', `submitAction(event)`)
    }

    if (anchorRef && !anchorRef.attr('data-config-type')) anchorRef.attr('data-config-type', 'default-config')

    this.#elements.dropdownLabel.data.html(this.#anchorTarget(target) || 'html://')
    this.#elements.dropdownLabel.data.attr('data-value', target)

    this.#elements.rollToElement.data.val(elementTarget)
    this.#elements.buttonLink.data.val(defaultHref)

    this.#elements.sendParams.data.prop('checked', !!sendParams)

    if (value == 'open-popup')
    {
      if (!anchorRef.length)
      {
        this.getRef(this.#image).wrap(/*html*/ `
          <a
            class='image-anchor'
            data-config-type="${value}"
            data-toggle="modal"
            data-target="#${MODAL_ID}"
          ></a>`)
      }
    }
  }

  // HELPERS
  #getFunctionalityValue(e) {
    if (!e || !this.getRef(this.#anchor))
    {
      return
    }
    const { form, element, options } = e
    const value = !element.attr('data-config-type') ? 'default-config' : element.attr('data-config-type')

    const findOption = options.find((el) => el.value === value)
    form.find('#dropdown-set-functionality').html(findOption ? findOption.text : DEFAULT_ACTION_SELECT)

    this.#switchVisibility(value)
  }

  #setFunctionality({ form, options }) {
    const self = this
    form
      .find('#set-functionality')
      .find('.dropdown-item')
      .on('click', function (event) {
        $('.unsaved').show()
        const element = self.getRef(self.#anchor)

        const { target } = event
        const value = $(target).attr('value')
        const currentInputValue = self.#switchVisibility(value)
        //Muda o texto do botão (dropdown)
        const findOption = options.find((el) => el.value == value)
        form.find(`#dropdown-set-functionality`).html(findOption ? findOption.text : DEFAULT_ACTION_SELECT)

        // verifica o tipo do valor no href
        const valueValidation = value !== 'submit-form' && self.#validationHref(currentInputValue)

        if (valueValidation == '#' || !valueValidation) element.removeAttr('target')

        if (form.find('#switch-open-new-page').is(':checked'))
        {
          element.attr('target', '_blank')
        }

        if (element.attr('data-send-params') && JSON.parse(element.attr('data-send-params')))
        {
          element.removeAttr('href')
          element.attr('onClick', `submitAction(event)`)
        } else
        {
          element.removeAttr('onClick')
        }

        //armazena o valor do tipo de configuração (o que aparece no select)
        const href = (!valueValidation ? '#' : '') + currentInputValue
        element.removeAttr('href')
        element.removeAttr('onClick')
        element.removeAttr('data-toggle')
        element.removeAttr('data-target')

        const image = self.getRef(self.#image)

        switch (value)
        {
          case 'submit-form':
            element.attr({
              'data-config-type': value,
              onClick: 'submitForm()'
            })
            break;
          case 'open-popup':
            if (!element.length)
            {
              image.wrap(/*html*/ `
                <a
                  class='image-anchor'
                  data-config-type="${value}"
                  data-toggle="modal"
                  data-target="#${MODAL_ID}"
                ></a>`)
            }
            else
            {
              element.removeAttr('data-default-href')
            }
            
            element.attr({
              'data-config-type': value,
              'data-toggle': "modal",
              'data-target': `#${MODAL_ID}`
            })
            break;
          default:
            element.attr({
              'data-config-type': value,
              [self.#setDataAtribute(value)]: href,
              href: href,
            })
            break;
        }
      })
  }

  // Retorna o atributo que será setado
  #setDataAtribute(value) {
    const config = {
      'default-config': 'data-default-href',
      'roll-to-element': 'data-element-target',
    }
    return config[value]
  }

  // Método interno somente para mudar a visibilidade das configurações
  #switchVisibility(value) {
    switch (value) {
      case 'default-config':
        this._form.find('#roll-to-element').hide()
        this._form.find('#default-config').show()
        return this._form.find('#button-link').val()
      case 'roll-to-element':
        this._form.find('#roll-to-element').show()
        this._form.find('#default-config').hide()
        return this._form.find('#roll-to-element-config').val()
      case 'open-popup':
        this._form.find('#roll-to-element').hide()
        this._form.find('#default-config').hide()
        break
      default:
        this._form.find('#roll-to-element').hide()
        this._form.find('#default-config').hide()
        break
    }
  }

  #validationHref(value) {
    if (!value) return null
    const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|#|www\.|)/
    let valueMatch = value.match(regex)

    if (valueMatch && valueMatch[0]) {
      const valTarget = valueMatch[0].indexOf('www.') != -1 ? valueMatch[0].split('www.')[0] : valueMatch[0]

      let label = '#'
      if (valTarget != '#' && valTarget.length) label = valTarget
      else if (!valTarget.length) label = 'https://'

      return label
    }
    return null
  }

  #anchorTarget(type) {
    switch (type) {
      case 'http://':
        return 'http://'
      default:
        return 'https://'
    }
  }
}
