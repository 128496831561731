// import '../styles/keditor-component-form.less'
import KEditor from 'keditor'
import { componentSpacing } from '../helpers/helpers'
import { hideMobileDesktop } from '../helpers/form.settings'
import initTimeDelay from '../helpers/timeDelay/initSettings'
import showTimeDelay from '../helpers/timeDelay/showSettings'
import {initAnimations, showAnimations} from '../helpers/animations'
import CarouselControl from './carouselControl'

const  CarouselComponent = new  CarouselControl()

KEditor.components['carousel'] = {
  emptyContent: `<p class="text-muted lead text-center"><br />[Nenhum item encontrado, favor adicionar um novo item]<br /><br /></p>`,

  settingEnabled: true,

  settingTitle: 'Carossel',

  init: function (contentArea, container, component, keditor) {
     CarouselComponent.keditor = keditor
     CarouselComponent.component = component
     CarouselComponent.create()
  },

  initSettingForm: function (form, keditor) {
    form.html( CarouselComponent.template)
     CarouselComponent.form = form
     CarouselComponent.init()

    initTimeDelay.call(self, form, keditor)
    initAnimations(form,keditor)
    hideMobileDesktop(form, null, keditor, '.carousel', 'edit')
    //Espaçamentos
    componentSpacing('.carousel', form, null, keditor, 2)
  },

  showSettingForm: function (form, component, keditor) {
    CarouselComponent.component = component
    CarouselComponent.show()

    showTimeDelay.call(self, form, component, keditor)
    showAnimations(form,keditor)
    hideMobileDesktop(form, component.find('.carousel'), keditor, null, '')
    // Espaçamentos
    componentSpacing(component.find('.carousel'), form, component, keditor, 1)
  },
}
