import { templateForm } from '../../helpers/form.settings'
import BaseComponent from '../baseComponent'
import { FORM_LAYOUT } from './templates'
import initModalForm from '../../../keditor/modal/initModalForm'
import { INTEGRATIONS_TYPE } from '../../../constants'
import {
  useBackgroundColor,
  useBorderColor,
  useBorderRadius,
  useBorderWidth,
  useColor,
  useFontSelector,
  useFontSizeResponsive,
} from '../../microComponents'
import fontResizeEventListener from '../../helpers/fontResizeEventListener'
import { componentSpacing } from '../../helpers/helpers'

const useComponents = [
  {
    config: useFontSizeResponsive,
    customFormRef: 'font-size-labels',
    target: 'label',
    customComponentName: 'fontSizeResponsiveLabel',
  },
  {
    config: useColor,
    customFormRef: 'color-labels',
    target: 'label',
    customComponentName: 'colorLabel',
  },
  { config: useFontSizeResponsive, target: 'input' },
  { config: useBackgroundColor, target: 'input' },
  { config: useBorderColor, target: 'input' },
  { 
    config: useBorderRadius, 
    target: 'input', 
    customOptions: [{name: 'Quadrada', value: '0px'},{ name: '3px'},{ name: '5px'},{ name: '10px'},{ name: '15px'},{ name: 'Circular', value: '50px'}]
  },
  { config: useBorderWidth, target: 'input' },
  { config: useColor, target: 'input' },
  { config: useFontSelector, target: 'form' },
]

export default class FormComponent extends BaseComponent {
  #formRef = 'form'
  #formData = []
  constructor() {
    super()
    this._integration
    this._emailMktIntegration
    this._initialized = false
  }

  get template() {
    return templateForm({ children: FORM_LAYOUT })
  }

  set keditor(Keditor) {
    this._keditor = Keditor
    this._integration = this._keditor?.options?.integrations
    this._emailMktIntegration = this._keditor?.options?.integrations?.data?.find(
      (integration) => integration.type === INTEGRATIONS_TYPE.EMAIL
    )
  }

  set form(newForm) {
    this._form = newForm

    useComponents.map((component) => {
      const aux = component.config({
        form: this._form,
        keditor: this._keditor,
        customFormRef: component.customFormRef,
        elementRef: () => this._component.find(component.target),
        customOptions: component.customOptions
      })

      this[component.customComponentName || aux.componentName] = aux
    })
  }

  #method(target, action, callback) {
    return this._form.find(`#${target}`).on(action, callback)
  }

  #existForm() {
    if(!this._integration) return

    const findIds = this._integration.dataId.filter((id) => {
      const component = this._keditor.iframeBody.find(`#${id}`)

      return component.length > 0
    })

    this._integration.dataId = findIds
  }

  #renderView() {
    let inputs = ''
    const fontMobile = this._component.find(this.#formRef).find('input').attr('data-font_size_mobile') || 18
    const fontDesktop = this._component.find(this.#formRef).find('input').attr('data-font_size_default') || 18
    const fontMobileLabel = this._component.find(this.#formRef).find('label').attr('data-font_size_mobile') || 16
    const fontDesktopLabel = this._component.find(this.#formRef).find('label').attr('data-font_size_default') || 16

    const labels = {
      name: 'Nome',
      email: 'E-mail',
      phone: 'Celular',
    }

    const style = {
      backgroundColor: this._component.find(this.#formRef).find('input').css('backgroundColor'),
      borderColor: this._component.find(this.#formRef).find('input').css('borderColor'),
      borderRadius: this._component.find(this.#formRef).find('input').css('borderRadius'),
      borderWidth: this._component.find(this.#formRef).find('input').css('borderWidth'),
      borderStyle: this._component.find(this.#formRef).find('input').css('borderStyle'),
      color: this._component.find(this.#formRef).find('input').css('color'),
      colorLabel: this._component.find(this.#formRef).find('label').css('color'),
    }

    this.#formData.forEach((el) => {
      if (el.shows)
        inputs += /*html*/ `
      <div class="form-group">
      ${
        !el.hideTitle
          ? /*html*/ `<label 
          for="${el.target}" 
          data-font_size_default="${fontDesktopLabel}"
          data-font_size_mobile="${fontMobileLabel}" 
          style="font-weight: 700; font-size: ${fontDesktopLabel}px; color: ${style.colorLabel};"
        >${labels[el.target]}</label>`
          : ''
      }
        <input
          class="form-control"
          id="${el.target}"
          name="${el.target}"
          placeholder="${el.placeholder}"
          autocomplete="${el.textSuggestion ? 'on' : 'off'}"
          type="${el.target == 'email' ? 'email' : 'text'}"
          value=""
          data-font_size_default="${fontDesktop}"
          data-font_size_mobile="${fontMobile}" 
          style="
            color: ${style.color};
            border-width: ${style.borderWidth}; 
            border-color: ${style.borderColor}; 
            border-radius: ${style.borderRadius}; 
            border-style: ${style.borderStyle}; 
            background-color: ${style.backgroundColor}; 
            font-size: ${fontDesktop}px"
          ${el.isRequired ? 'required' : ''}
        />
      </div>
      `
    })
    this._component.find(this.#formRef).html(inputs)

    const currentWidth = this._keditor.options.currentDeviceWidth
    fontResizeEventListener(currentWidth, this._keditor)
  }

  // Quando o componente é inicializado
  create() {
    if (!this._component || !this._component.find(this.#formRef)) return
    this.#existForm()
    const elId = this._component.attr('id')

    if (this._integration?.data?.length && !this._integration.dataId.includes(elId)) this._integration.dataId.push(elId)

    if (!this._component.find(this.#formRef).attr('data-form')) {
      const inputs = this._component.find(this.#formRef).find('.form-group').find('input')

      inputs.map((el) => {
        const input = inputs[el]
        const placeholder = input.getAttribute('placeholder')
        const name = input.getAttribute('name')
        const required = input.getAttribute('required') != null
        const autocomplete = input.getAttribute('autocomplete') != 'off'

        const parent = input.parentNode.closest('.form-group')
        const shows = parent.style.display != 'none'
        const auxObj = {
          target: name,
          placeholder,
          isRequired: required,
          textSuggestion: autocomplete,
          hideTitle: false,
          shows,
        }

        this.#formData.push(auxObj)
      })

      this._component.find(this.#formRef).attr('data-form', JSON.stringify(this.#formData))
    } else {
      const formData = JSON.parse(this._component.find(this.#formRef).attr('data-form'))
      this.#formData = formData
    }

    this.#renderView()
    
    if (!this._integration?.data?.length) {
      this._component.find(this.#formRef).parent().append(/*html*/ `
        <div class="integration-undefined text-danger" style="
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: flex-end;
            border: 1px solid #ff000045;
        ">
          <span style="
              bottom: -10px;
              position: absolute;
              background: white;
              left: 10px;
          ">Você ainda não definiu uma integração</span>
        </div>
      `)
    }
  }

  saveForm(newForm) {
    this.#formData = newForm
    this.#renderView()
    this._component.find(this.#formRef).attr('data-form', JSON.stringify(newForm))
  }

  // Quando a sidebar de config é aberta pela primeira vez
  init() {
    if (!this._form) return

    this.#method('open-modal', 'click', () => {
      initModalForm({
        page: this._keditor,
        keditor: this._keditor,
        formData: this.#formData,
        onSave: (e) => {
          this.saveForm(e)
        },
      })
    })

    this.fontSizeResponsive.init(() => this._component.find(this.#formRef).find('input'))
    this.fontSizeResponsiveLabel.init(() => this._component.find(this.#formRef).find('label'))
    this.fontSelector.init()
    this.color.init()
    this.colorLabel.init()

    this.borderRadius.init()
    this.borderColor.init()
    this.borderWidth.init()
    this.backgroundColor.init()

    //Espaçamentos
    componentSpacing('form', this._form, null, this._keditor, 2)
  }

  // Quando a sidebar é aberta
  show() {
    this.fontSizeResponsive.show(() => this._component.find(this.#formRef).find('input'))
    this.fontSizeResponsiveLabel.show(() => this._component.find(this.#formRef).find('label'))
    this.fontSelector.show()
    this.color.show()
    this.colorLabel.show()

    this.borderRadius.show()
    this.borderColor.show()
    this.borderWidth.show()
    this.backgroundColor.show()

    const form = this.getRef(this.#formRef)
    // Espaçamentos
    componentSpacing(form, this._form, this._component, this._keditor, 1)
  }
}
