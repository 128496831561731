import {
  useBackgroundColor,
  useBorderColor,
  useBorderRadius,
  useBorderWidth,
  useColor,
  useFontSelector,
  useFontSizeResponsive,
} from '../../../microComponents'

const fontFamily = useFontSelector({
  label: 'Fonte',
  isFirst: true,
})

const backgroundColor = useBackgroundColor({
  label: 'Cor do campo',
})

const borderColor = useBorderColor({
  label: 'Cor da borda',
  isFirst: true,
})

const borderWidth = useBorderWidth({
  label: 'Espessura da borda',
  isFirst: true,
})

const borderRadius = useBorderRadius({
  label: 'Arredondamento',
  customOptions: [{name: 'Quadrada', value: '0px'},{ name: '3px'},{ name: '5px'},{ name: '10px'},{ name: '15px'},{ name: 'Circular', value: '50px'}]
})

const fontSize = useFontSizeResponsive({
  label: 'Tamanho',
  isFirst: true,
})

const color = useColor({
  label: 'Cor',
})
const colorLabels = useColor({
  label: 'Cor',
  customFormRef: 'color-labels',
})

const fontSizeLabels = useFontSizeResponsive({
  label: 'Tamanho',
  isFirst: true,
  customFormRef: 'font-size-labels',
})

export default /*html*/ `
<div class="d-flex flex-wrap">
  <div class="col-sm-12 my-3">
    <div 
      class="upload-button"
      id="open-modal"
      data-toggle="modal" 
      data-target="#modal-form"
      data-type="photo"
    >
      Editar formulário
    </div>
  </div>
</div>

<div class="form-settings-head">
  TIPOGRAFIA <span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group" style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
    ${fontFamily.template}
    <hr class="col-sm-12" style="width; 100%"/>
    <h6 class="col-sm-12 my-3">Campos</h6>
    ${fontSize.template}
    ${color.template}
    <hr class="col-sm-12" style="width; 100%"/>
    <h6 class="col-sm-12 my-3">Títulos</h6>
    ${fontSizeLabels.template}
    ${colorLabels.template}
  </div>
</div>

<div class="form-settings-head">
  ESTILO <span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group" style="margin: 1rem 0rem">
  <div class="d-flex flex-wrap">
  ${borderWidth.template}
  ${borderColor.template}
  ${borderRadius.template}
  ${backgroundColor.template}
  </div>
</div>
`
