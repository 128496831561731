import BaseComponent from '../../../components/containers/baseComponent'
import { useIconSelect } from '../../../components/microComponents'

export default class ListModalComponent extends BaseComponent {
  #list = []
  #editor = []

  #divConfig = '#form-config'
  #modalBody = '.modal-body'
  #modalID = '#modal-list'
  #emptyContent = '#empty-content'

  set data(newObject) {
    this.#list = []
    newObject.map((el) => {
      this.#list.push(el)
    })
  }

  get list() {
    return this.#list
  }

  #renderView() {
    let body = ''

    this.#list.forEach((el, index) => {
      const iconSelect = useIconSelect({
        label: 'Icone',
        hasShowConfig: false,
        customFormRef: 'input-icon-select-' + index,
        fullWidth: true,
        noPadding: true,
      })

      body += /*html*/ `
      <div class="field-group" id="${index}">
        <div class="fields-config">
          <div class="field-moves">
            <a 
              class="move-icon move-up" 
              role="button"
              data-index="${index}"
            >
              <img src="bundle/assets/icons/arrow_up.svg" />
            </a>
            <a 
              class="move-icon move-down" 
              role="button"
              data-index="${index}"
              >
              <img style="transform: rotate(180deg);" src="bundle/assets/icons/arrow_up.svg" />
            </a>
            <a 
              class="move-icon delete" 
              role="button"
              data-index="${index}"
              >
              <img src="bundle/assets/icons/trash.svg" />
            </a>
          </div>
        </div>

        
        <div class="fields-inputs">
          <h5>Item ${index + 1}</h5>
          <div class="d-flex flex-wrap my-3">
            ${iconSelect.template}
          </div>
          <label class="mt-2">Texto</label>
          <div  class="text-editor px-1" id="text-editor-${index}">
            ${el.description}
          </div>
        </div>
      </div>
      `
    })

    $(this.#modalID).find(this.#divConfig).html(body)

    $(this.#modalID).find(this.#emptyContent).hide()
    if (!this.#list.length) $(this.#modalID).find(this.#emptyContent).show()

    this.#editorConfig()

    this.#list.forEach((el, index) => {
      const iconSelect = useIconSelect({
        customFormRef: 'input-icon-select-' + index,
        form: $(this.#modalID).find(this.#divConfig).find('.fields-inputs'),
        keditor: this._keditor,
        elementRef: () => $(this.#modalID).find(this.#divConfig).find('fields-inputs'),
      })

      this.#list[index].icon = el.icon || 'fa-check'

      iconSelect.init((icon) => {
        this.#list[index].icon = icon
      })
      iconSelect.show(() => {
        return this.#list[index].icon
      })
    })
  }

  #method(target, action, callback) {
    $(this.#modalID).find(this.#modalBody).off(action, target)
    $(this.#modalID).find(this.#modalBody).on(action, target, callback)
  }

  #editorConfig() {
    const options = {
      modules: {
        toolbar: ['bold', 'italic', 'underline', 'strike'],
      },
      placeholder: 'Compose an epic...',
      // theme: 'snow',
      theme: 'bubble',
    }

    this.#editor = []
    this.#list.forEach((_, index) => {
      this.#editor.push(new Quill(`#text-editor-${index}`, options))
    })

    this.#editor.forEach((quill, index) => {
      quill.on('text-change', (delta, oldDelta, source) => update({ quill, index }))
      quill.on('editor-change', function (eventName, ...args) {
        if (eventName === 'text-change') {
          // args[0] will be delta
          // console.log(...args)
        } else if (eventName === 'selection-change') {
          // args[0] will be old range
        }
      })
    })
    const update = ({ quill, index }) => {
      const editor = $(quill.container).find(quill.root)
      this.#list[index].description = editor.html()
    }
  }

  edit() {
    const self = this
    this.#method('.move-down', 'click', function (e) {
      const index = $(this).data('index')
      if (index + 1 < self.#list.length)
        [self.#list[index], self.#list[index + 1]] = [self.#list[index + 1], self.#list[index]]

      self.#renderView()
    })

    this.#method('.move-up', 'click', function (e) {
      const index = $(this).data('index')
      if (index > 0) [self.#list[index], self.#list[index - 1]] = [self.#list[index - 1], self.#list[index]]

      self.#renderView()
    })

    this.#method('.delete', 'click', function (e) {
      const index = $(this).data('index')
      if (confirm('Tem certeza que deseja excluir este item?')) {
        self.#list = self.#list.filter((el, i) => i !== index)
      }

      self.#renderView()
    })

    this.#method('#new-item', 'click', function (e) {
      self.#list.push({ description: 'Novo item', icon: 'fa-check' })
      self.#renderView()
    })
  }

  show() {
    this.#renderView()
  }
}
