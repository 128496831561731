import $ from "jquery";
import KEditor from "keditor";
import { templateForm } from "./helpers/form.settings";
import {
  applySpacing,
  setSpaceInputValues,
} from "./helpers/helpers";

KEditor.components["googlemap"] = {
  init: function (contentArea, container, component, keditor) {
    let iframe = component.find("iframe");
    let wrapper = iframe.parent();
    keditor.initIframeCover(iframe, wrapper);
  },

  settingEnabled: true,

  settingTitle: "Configuracões do Google Maps",

  initSettingForm: function (form, keditor) {
    form.append(
      templateForm({
        children:`<form class="form-horizontal">
        <div class="form-group">
            <div class="col-sm-12">
                <button type="button" class="btn btn-block btn-primary btn-googlemap-edit">Update Map</button>
            </div>
        </div>
        <div cs="form-group">
            <label class="col-sm-6">Aspect Ratio</label>
            <div class="col-sm-12">
                <button type="button" class="btn btn-sm btn-default btn-googlemap-169">16:9</button>
                <button type="button" class="btn btn-sm btn-default btn-googlemap-43">4:3</button>
            </div>
        </div>
    </form>`})
    );

    let btnEdit = form.find(".btn-googlemap-edit");
    btnEdit.on("click", function (e) {
      e.preventDefault();

      let inputData = prompt("Please enter Google Map embed code in here:");
      let iframe = $(inputData);
      let src = iframe.attr("src");
      if (iframe.length > 0 && src && src.length > 0) {
        keditor
          .getSettingComponent()
          .find(".embed-responsive-item")
          .attr("src", src);
      } else {
        alert("Your Google Map embed code is invalid!");
      }
    });

    let btn169 = form.find(".btn-googlemap-169");
    btn169.on("click", function (e) {
      e.preventDefault();

      keditor
        .getSettingComponent()
        .find(".embed-responsive")
        .removeClass("embed-responsive-4by3")
        .addClass("embed-responsive-16by9");
    });

    let btn43 = form.find(".btn-googlemap-43");
    btn43.on("click", function (e) {
      e.preventDefault();

      keditor
        .getSettingComponent()
        .find(".embed-responsive")
        .removeClass("embed-responsive-16by9")
        .addClass("embed-responsive-4by3");
    });

    //Padding
    let pElement = form.find(".p-element");
    pElement.on("change", function () {
      let component = keditor.getSettingComponent();
      let formContent = component.find(".googlemap-wrapper");
      applySpacing(this, formContent, "padding", form);
    });

    //Margin
    let mElement = form.find(".m-element");
    mElement.on("change", function () {
      let component = keditor.getSettingComponent();
      let formContent = component.find(".googlemap-wrapper");
      applySpacing(this, formContent, "margin", form);
    });

    let customClass = form.find(".custom-class");
    customClass.on("change", function () {
      let component = keditor.getSettingComponent();
      let formContent = component.find(".googlemap-wrapper");
      formContent.attr("class", this.value);
    });
  },

  showSettingForm: function (form, component, keditor) {
    let formContent = component.find(".googlemap-wrapper");

    setSpaceInputValues("margin", "m-element", formContent, form);
    setSpaceInputValues("padding", "p-element", formContent, form);
    let customClass = form.find(".custom-class");
    customClass.val(formContent.attr("class"));
    let keditorID = form.find(".keditor-id");
    keditorID.val(component.attr("id"));
  },
};
