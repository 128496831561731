 // RENDER
 const renderItemConfig = ({ id, config }) => {
    const dropdownGroup = /*html*/ `
    <p class="btn-group w-100 collapse-group" role="group">
      <a 
        class="btn btn-light col-sm-9 overflow-hidden text-truncate collapse-title" 
        data-toggle="collapse" 
        href="#${id}" 
        role="button"
        data-target="#${id}" 
        aria-expanded="true" 
        aria-controls="${id}"
        style="border: 1px solid #ccc; color: #222"
      >
        ${config.question}
      </a>
      <a 
        class="btn btn-light col-sm-1 move-down px-0" 
        role="button"
        data-move-id="${id}"
        style="border: 1px solid #ccc"
      >
        <i style="cursor: pointer;" class="fa fa-arrow-down"></i>
      </a>
      <a 
        class="btn btn-light col-sm-1 move-up px-0" 
        role="button" 
        data-move-id="${id}"
        style="border: 1px solid #ccc"
      >
        <i style="cursor: pointer;" class="fa fa-arrow-up"></i>
      </a>
      <a 
        class="btn btn-danger col-sm-1 px-0 delete" 
        role="button"
        data-delete-id="${id}"
      >
        <i style="cursor: pointer; color: #fff" class="fa fa-trash-o"></i>
      </a>
    </p>
    `

    let answers = ''
    let options = ''
    const abcd = 'ABCD'
    for (let i = 0; i < 4; i++) {
      answers +=
        /*html*/
        `
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text py-0">${abcd[i]}</span>
          </div>
          <input 
            class="form-control answer-input" 
            value="${config.answers[i] || ''}" 
            placeholder="Digite aqui a resposta" 
            id="${id}-answer-${i}"
            maxLength="26"
            data-question-id="${id}"
            data-answer-index="${i}"
            type="text" 
          />
        </div>
      `
      const isChecked = config.correct == i
      options += /*html*/ `
        <option ${isChecked ? 'selected' : ''} value="${i}">
          ${abcd[i]}
        </option>
      `
    }

    return /*html*/ `
    <div class="my-3" data-config="${id}">
      ${dropdownGroup}
      <div class="collapse" id="${id}">
        <div class="card p-3">
          <label>Pergunta</label>
          <input 
            class="form-control question" 
            placeholder="Digite aqui a pergunta" 
            type="text" 
            maxLength="60"
            data-question-id="${id}"
            value="${config.question}"
          />
          <div class="invalid-feedback">
            É obrigatório ter uma pergunta
          </div>
          <hr />
          <label> Respostas </label>
          <div 
            class="text-danger" 
            id="erro-${id.replace('config-', '')}" 
            style="display: none"
          >É obrigatório ter pelo menos uma resposta</div>
          ${answers}
          <label class="mt-3"> Resposta correta </label>
          <select 
            class="form-control correct-select"
            data-question-id="${id}"
          >
            ${options}
          </select>
        </div>
      </div>
    </div>
    `
  }

export default renderItemConfig