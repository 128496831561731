import KEditor from 'keditor'
import { verifyHasClass, componentSpacing } from './helpers/helpers'
import { hideMobileDesktop, templateForm } from './helpers/form.settings'

import initTimeDelay from './helpers/timeDelay/initSettings'
import showTimeDelay from './helpers/timeDelay/showSettings'
import {initAnimations, showAnimations} from './helpers/animations'

import ColorPicker from './helpers/colorPicker'
const colorPicker = new ColorPicker()
import { useAlignmentBlock } from '../components/microComponents'

const AlignmentBlock = useAlignmentBlock({ isFirst: true })

KEditor.components['divisor'] = {
  settingEnabled: true,

  settingTitle: 'Divisor',

  init: function (contentArea, container, component, keditor) {},

  initSettingForm: function (form, keditor) {
    const component = () => keditor.getSettingComponent()
    form.empty().append(templateForm({ children: formLayout }))

    const alignmentBlock = useAlignmentBlock({ form, elementRef: () => component().find('.divisor-align') })
    alignmentBlock.init('justify-content')

    initTimeDelay.call(self, form, keditor)
    initAnimations(form,keditor)

    form.find('#hr-style').on('change', function () {
      const divisor = component().find('.divisor')

      divisor.css('border-top-style', this.value)
    })

    form.find('#hr-width').on('change', function () {
      const divisor = component().find('.divisor-wrapper')
      divisor.removeClass('w-5 w-15 w-30 w-50 w-70 w-85 w-95 w-100')
      divisor.addClass(`w-${this.value}`)
      // divisor.css("width", `${this.value}%`);
    })

    form.find('#hr-height').on('change', function () {
      const divisor = component().find('.divisor')
      divisor.css('border-top-width', `${this.value}px`)
    })

    //Color
    colorPicker.init(form, (color) => {
      const icon = component().find('.divisor')
      icon.css('border-top-color', color)
    })

    //Aligin
    const inputAlign = form.find('#button-align')
    inputAlign.on('change', function () {
      const buttonWrapper = keditor.getSettingComponent().find('.divisor-wrapper')
      buttonWrapper.removeClass('text-center text-left text-right')
      buttonWrapper.addClass(this.value)
    })

    //Espaçamentos
    componentSpacing('.divisor', form, null, keditor, 2)

    hideMobileDesktop(form, null, keditor, '.divisor', 'edit')
  },

  showSettingForm: function (form, component, keditor) {
    showTimeDelay.call(self, form, component, keditor)
    showAnimations(form,keditor)
    const divisor = component.find('.divisor')
    const divisorAlign = component.find('.divisor-align')

    const alignmentBlock = useAlignmentBlock({ form, elementRef: () => divisorAlign })
    alignmentBlock.show()

    form.find('#hr-style').val(divisor.css('border-top-style'))
    form
      .find('#hr-width')
      .val(
        verifyHasClass(component.find('.divisor-wrapper'), [
          'w-5',
          'w-15',
          'w-30',
          'w-50',
          'w-70',
          'w-85',
          'w-95',
          'w-100',
        ])
          .split('w-')
          .join('')
      )
    form.find('#hr-height').val(divisor.css('border-top-width').split('px')[0])

    //Color
    colorPicker.show(form, divisor.css('border-top-color'))

    // Espaçamentos
    componentSpacing(divisor, form, component, keditor, 1)

    hideMobileDesktop(form, divisor, keditor, null, '')
  },
}

const formLayout = /*html*/ `
<div class="form-settings-head">
  Layout<span
    ><i class="fa fa-caret-down" aria-hidden="true"></i
  ></span>
</div>
<div class="form-group row" style="margin: 1rem 0rem">
  <div class="col-sm-12">
    <label for="hr-style">Estilo</label>
    <select id="hr-style" class="form-control">
      <option value="solid">Sólido</option>
      <option value="dashed">Sublinhado</option>
      <option value="dotted">Pontilhado</option>
    </select>
  </div>
  <div class="col-sm-12 mt-3">
    <label for="hr-width">Largura</label>
    <select id="hr-width" class="form-control">
      <option value="5">5%</option>
      <option value="15">15%</option>
      <option value="30">30%</option>
      <option value="50">50%</option>
      <option value="70">70%</option>
      <option value="85">85%</option>
      <option value="95">95%</option>
      <option value="100">100%</option>
    </select>
  </div>
  <div class="d-flex flex-wrap mt-3">
    ${AlignmentBlock.template}
  </div>
  <div class="col-sm-12 mt-3">
    <label for="hr-height">Altura</label>
    <input 
      type="number" 
      id="hr-height" 
      class="form-control"
      value=""
      min="0"
    />
  </div>
  <div class="col-sm-12 mt-3">
    <label for="input-color">Cor de Fundo</label>
    <div id="input-color" class="input-group">
      <input data-id="#color-picker" class="color-picker-component form-control" />
    </div>
  </div>
</div>
`
