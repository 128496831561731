import { useFontSelector, useFontSizeResponsive, useColor, useDropdown, useWidth, useShowElement } from '../microComponents'
import ColorPicker from '../helpers/colorPicker'
import { templateForm } from '../helpers/form.settings'
import * as templates from './layouts'
import ModalEditor from './components/ModalEditor'
import {MODAL_ID} from './components/ModalEditor/constants'
import {
  getFunctionalityValue,
  renderConfigView,
  changeView,
  save,
  handleChangeQuestion,
  handleChangeAnswer,
  handleInputChange,
  handleAction,
  validationHref,
} from './utils'

import QuizModel from './quizModel'

const colorPicker = new ColorPicker()

export default class QuizControl {
  // SETTERS
  set component(newComponent) {
    QuizModel.component = newComponent
  }

  set keditor(keditor) {
    QuizModel.keditor = keditor
  }

  set form(form) {
    QuizModel.form = form

    Object.keys(QuizModel.elements).map((key) => {
      QuizModel.elements[key].data = QuizModel.form.find(QuizModel.elements[key].selector)
    })

    const defaultValues = {
      form: QuizModel.form,
      keditor: QuizModel.keditor,
      elementRef: () => QuizModel.getRef(QuizModel.quiz),
    }

    this.fontSelector = useFontSelector(defaultValues)
    this.fontSizeTitle = useFontSizeResponsive({
      ...defaultValues,
      customFormRef: 'font-size-text',
      customComponentName: 'fontSizeTitle',
    })
    this.fontSizeAnswers = useFontSizeResponsive({
      ...defaultValues,
      customFormRef: 'font-size-answers',
      customComponentName: 'fontSizeAnswers',
    })
    this.optionsSelector = useDropdown({
      ...defaultValues,
      customFormRef: 'quiz-action',
      customOptions: [
        {
          value: 'redirect',
          text: 'Ir para URL',
          icon: 'fa fa-globe',
        },
        {
          value: 'message',
          text: 'Exibir Mensagem',
          icon: 'fa fa-envelope',
        },
      ],
    })

    this.widthSelector = useWidth({
      ...defaultValues,
    })

    this.primaryColor = useColor({
      form: QuizModel.form,
      keditor: QuizModel.keditor,
      elementRef: () => QuizModel.getRef(QuizModel.primaryColor),
      customFormRef: 'primary-color',
    })

    this.showProgress = useShowElement({
      form: QuizModel.form,
      keditor: QuizModel.keditor,
      elementRef: () => QuizModel.getRef(QuizModel.progressRef),
      customFormRef: 'show-progress',
      customShowPropValue: 'flex'
    })

  }

  //GETTERS
  get template() {
    return $(templateForm({ children: templates.FORM_LAYOUT }))
  }

  create() {
    if (!QuizModel.component && !QuizModel.keditor) return
    const quizContainer = QuizModel.component.find(QuizModel.quizContainerRef)
    const data = JSON.parse(quizContainer.attr('data-quiz'))

    QuizModel.questions = data.quiz

    QuizModel.resetColors()

    QuizModel.curPrimaryColor = quizContainer.attr('data-primary-color') ?? QuizModel.curPrimaryColor
    QuizModel.curSecondaryColor = quizContainer.attr('data-secondary-color') ?? QuizModel.curSecondaryColor
    QuizModel.curTertiaryColor = quizContainer.attr('data-tertiary-color') ?? QuizModel.curTertiaryColor

    changeView(quizContainer.attr('data-preview'), false)

    ModalEditor.initModal()
  }

  init() {
    if (!QuizModel.form) return

    this.fontSizeTitle.init(() => QuizModel.getRef(QuizModel.titleRef))
    this.fontSizeAnswers.init(() => QuizModel.getRef(QuizModel.answersRef))
    this.fontSelector.init()
    this.widthSelector.init()
    this.showProgress.init((isChecked) => {QuizModel.curShowProgressBool = isChecked})
    this.primaryColor.init((newColor) => {QuizModel.curPrimaryColor = newColor})

    //Cor de Fundo da Barra de Progresso
    colorPicker.init(
      QuizModel.form,
      (color) => {
        const elementRef = QuizModel.getRef(QuizModel.primaryColorBg)
        elementRef.css('background-color', color)
        QuizModel.curPrimaryColor = color
        QuizModel.getRef(QuizModel.quizContainerRef)?.attr('data-primary-color', color)
      },
      '#primary-color'
    )
    //Cor do progresso da Barra de Progresso
    colorPicker.init(
      QuizModel.form,
      (color) => {
        const elementRef = QuizModel.getRef(QuizModel.secondaryColorBg)
        elementRef.css('background-color', color)
        QuizModel.curSecondaryColor = color
        QuizModel.getRef(QuizModel.quizContainerRef)?.attr('data-secondary-color', color)
      },
      '#secondary-color'
    )
    //Cor do fundo dos Campos
    colorPicker.init(
      QuizModel.form,
      (color) => {
        const elementRef = QuizModel.getRef(QuizModel.tertiaryColorBg)
        elementRef.css('background-color', color)
        QuizModel.curTertiaryColor = color
        QuizModel.getRef(QuizModel.quizContainerRef)?.attr('data-tertiary-color', color)
      },
      '#tertiary-color'
    )

    QuizModel.elements.selectView.data.on('change', (e) => {
      QuizModel.getRef(QuizModel.quizContainerRef).attr('data-preview', e.target.value)
      changeView(parseInt(e.target.value))
    })

    this.optionsSelector.init(handleAction({ form: QuizModel.form, options: this.optionsSelector.options }))

    QuizModel.elements.showCorrect.data.on('click', (e) => {
      const isChecked = $(e.target).prop('checked')
      QuizModel.showCorrect = isChecked
    })

    QuizModel.elements.openNewTab.data.on('click', (e) => {
      const isChecked = $(e.target).prop('checked')
      QuizModel.openNewTab = isChecked
      save(false)
    })

    QuizModel.elements.quizHrefType.data.on('click', '.dropdown-item', (e) => {
      const value = $(e.target).attr('data-value')
      QuizModel.form.find('#quiz-target-label')[0].innerHTML = value

      QuizModel.href = value

      const valueValidation = validationHref(QuizModel.redirectTo)

      QuizModel.redirectTo = QuizModel.redirectTo.replace(valueValidation, value)

      QuizModel.form.find(QuizModel.elements.quizLink.selector)[0].value = QuizModel.redirectTo

      save(false)
    })

    QuizModel.elements.inputMessage.data.on('keyup', function (e) {
      const { value } = e.target
      QuizModel.getRef(QuizModel.message)[0].innerHTML = value
    })

    QuizModel.elements.quizLink.data.on('keyup', function (e) {
      const { value } = e.target
      const scheme = QuizModel.form.find('#quiz-target-label')[0].innerHTML.trim()
      //adicionar https ou http se não tiver
      let newLink = value
      if(value.indexOf(scheme) == -1) {
        newLink = scheme + value
      }
      //salvar
      QuizModel.redirectTo = newLink
      save(false)
    })

    $(`#${MODAL_ID}`).find('.save').on('click', () => {
      const self = this
      save()
      self.show()
    })
  }

  show() {
    if (!QuizModel.component || !QuizModel.form) return

    this.fontSizeTitle.show(() => QuizModel.getRef(QuizModel.titleRef))
    this.fontSizeAnswers.show(() => QuizModel.getRef(QuizModel.answersRef))
    this.fontSelector.show()
    this.widthSelector.show()
    this.showProgress.show()
    this.primaryColor.show()

    colorPicker.show(QuizModel.form, QuizModel.getRef(QuizModel.secondaryColorBg).css('background-color'), '#secondary-color')
    colorPicker.show(QuizModel.form, QuizModel.getRef(QuizModel.tertiaryColorBg).css('background-color'), '#tertiary-color')

    QuizModel.elements.inputMessage.data.val(QuizModel.getRef(QuizModel.message)[0].innerHTML)

    const quizContainer = QuizModel.getRef(QuizModel.quizContainerRef)
    const data = JSON.parse(quizContainer.attr('data-quiz'))
    const valueValidation = validationHref(data.redirectTo)
    QuizModel.href = valueValidation
    QuizModel.redirectTo = data.redirectTo
    QuizModel.questions = data.quiz
    QuizModel.showMessage = data.showMessage
    QuizModel.showCorrect = data.showCorrect
    QuizModel.openNewTab = data.openNewTab

    QuizModel.elements.quizLink.data.val(data.redirectTo)

    QuizModel.elements.openNewTab.data.prop('checked', data.openNewTab)
    QuizModel.elements.showCorrect.data.prop('checked', data.showCorrect)

    // QuizModel.elements.quizAction.data.val(!data.showMessage ? 'redirect' : 'message')

    this.optionsSelector.show(
      getFunctionalityValue({ form: QuizModel.form, data, options: this.optionsSelector.options })
    )

    // =============
    renderConfigView()
    /**
     * Line below was commented Due performance improvement
     * Is only necessary to changeView when any change is saved
     */
    //changeView(quizContainer.attr('data-preview'), false)

    ModalEditor.show()
  }
}
