import { MODAL_BODY, MODAL_FOOTER } from './list/templates/index.js'
import ListModalComponent from './list/index.js'
import { ModalBase } from './template/modalBase.js'

const formComponent = new ListModalComponent()
export default function ({ page, form, keditor, formData, onSave }) {
  let self = page

  const customForm = formData || []

  let modal = $(
    ModalBase({
      id: 'modal-list',
      title: 'Configure sua listagem',
      footer: MODAL_FOOTER,
      body: MODAL_BODY(customForm),
      info: 'Selecione parte do texto para mais opções',
    })
  )

  const result = modal.appendTo(self.wrapper)

  formComponent.data = customForm
  formComponent.form = form
  formComponent.keditor = keditor
  formComponent.edit()
  formComponent.show()

  $('.save-list-modal').off('click')
  $('.save-list-modal').on('click', function () {
    onSave(formComponent.list)
  })

  return result
}
