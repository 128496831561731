import {
  useFontSelector,
  useFontSizeResponsive,
  useBackgroundColor,
  useTextSpacing,
  useTextShadow,
} from '../../../microComponents'
import { verifyHasClass } from '../../../helpers/helpers'
const useComponents = [
  { component: useFontSelector },
  { component: useTextSpacing },
  { component: useTextShadow },
  { component: useFontSizeResponsive, name: 'font-size', customComponentName: 'fontSize' },
]

export default class TextComponent {
  #textContent = '.text-content'
  #textContainer = '.text-container'
  #blockConfig = false
  #defaultValue = 'left'
  #mobileValue = 'left'

  #elements = {
    textAlignment: {
      selector: '#text-alignment',
      data: null,
    },
    textAlignmentMobile: {
      selector: '#text-alignment-mobile',
      data: null,
    },
    blockMobile: {
      selector: '#block-mobile',
      data: null,
    },
  }

  constructor({ form, keditor, component }) {
    this._form = form
    this._keditor = keditor
    this._component = component

    useComponents.map((el) => {
      const aux = el.component({
        customFormRef: el.name,
        customComponentName: el.customComponentName,
        form: this._form,
        keditor,
        elementRef: () => this.getRef(this.#textContent),
      })

      this[aux.componentName] = aux
    })

    Object.keys(this.#elements).map((key) => {
      this.#elements[key].data = this._form.find(this.#elements[key].selector)
    })

    this.backgroundColor = useBackgroundColor({
      form: this._form,
      keditor,
      elementRef: () => this.getRef(this.#textContainer),
    })
  }

  //SETTERS
  set keditor(newKeditor) {
    this._keditor = newKeditor
  }

  set component(newComponent) {
    this._component = newComponent
  }

  set form(newForm) {
    this._form = newForm
  }

  //GETTERS
  get keditor() {
    return this._keditor
  }

  get component() {
    return this._component
  }

  get form() {
    return this._form
  }

  getRef(target) {
    return this.keditor.getSettingComponent() && this.keditor.getSettingComponent().find(target)
  }

  init() {
    this.fontSize.init(() => this.getRef(this.#textContent))
    this.backgroundColor.init()
    this.fontSelector.init()
    this.textSpacing.init(() => this.getRef(this.#textContent))
    this.textShadow.init(() => this.getRef(this.#textContent))
    const self = this

    this.#elements.textAlignment.data.on('click', '.nav-item-custom', function (e) {
      const value = $(this).attr('data-value')
      const content = self.getRef(self.#textContent)
      content.css('textAlign', '')
      content.removeClass('default-text-center default-text-left default-text-right default-text-justify')
      content.addClass(`default-text-${value}`)
      self.#defaultValue = value
      if (self.#blockConfig) {
        self.#mobileValue = value
        const mobileValue = `mobile-text-${value}`
        const mobileConfig = self.#elements.textAlignmentMobile.data.find(`[data-value="${value}"]`)
        self.#elements.textAlignmentMobile.data.find('.nav-item-custom').removeClass('active')
        mobileConfig.addClass('active')
        content.removeClass('mobile-text-center mobile-text-left mobile-text-right mobile-text-justify')
        content.addClass(mobileValue)
      }
    })

    this.#elements.textAlignmentMobile.data.on('click', '.nav-item-custom', function (e) {
      if (self.getRef(self.#textContent).attr('data-block-mobile') == 'true') {
        return
      }
      const value = $(this).attr('data-value')

      self.#mobileValue = value
      const content = self.getRef(self.#textContent)
      content.removeClass('mobile-text-center mobile-text-left mobile-text-right mobile-text-justify')
      content.addClass(`mobile-text-${value}`)
    })

    this.#elements.blockMobile.data.on('click', function (e) {
      const icon = $(this).find('i')
      const content = self.getRef(self.#textContent)
      const mobileConfig = self.#elements.textAlignmentMobile.data.find('.nav-item-custom')
      const mobileArr = ['mobile-text-center', 'mobile-text-left', 'mobile-text-right', 'mobile-text-justify']

      if (icon.hasClass('fa-lock')) {
        content.attr('data-block-mobile', false)
        mobileConfig.removeClass('disabled')
        self.#blockConfig = false
      } else {
        self.#blockConfig = true
        mobileConfig.addClass('disabled')
        mobileConfig.removeClass('active')
        content.removeClass(mobileArr)
        content.addClass(`mobile-text-${self.#defaultValue}`)
        content.attr('data-block-mobile', true)

        self.#mobileValue = self.#defaultValue
        self.#elements.textAlignmentMobile.data.find(`[data-value="${self.#defaultValue}"]`).addClass('active')
      }

      icon.toggleClass('fa-lock')
      icon.toggleClass('fa-unlock')
    })
  }

  show() {
    this.fontSize.show(() => this.getRef(this.#textContent))
    this.backgroundColor.show()
    this.fontSelector.show()
    this.textSpacing.show(() => this.getRef(this.#textContent))
    this.textShadow.show(() => this.getRef(this.#textContent))

    this.getRef(this.#textContent).css('textAlign', '')

    const icon = this.#elements.blockMobile.data.find('i')
    const defaultArr = ['default-text-center', 'default-text-left', 'default-text-right', 'default-text-justify']
    const mobileArr = ['mobile-text-center', 'mobile-text-left', 'mobile-text-right', 'mobile-text-justify']

    const aligment = verifyHasClass(this.getRef(this.#textContent), defaultArr) || 'default-text-left'
    const aligmentMobile = verifyHasClass(this.getRef(this.#textContent), mobileArr) || 'mobile-text-left'

    const mobileConfig = this.#elements.textAlignmentMobile.data.find('.nav-item-custom')

    if (!verifyHasClass(this.getRef(this.#textContent), [...mobileArr, ...defaultArr])) {
      this.getRef(this.#textContent).addClass('default-text-left mobile-text-left')
    }

    const isMobileAlignBlocked = this.getRef(this.#textContent).attr('data-block-mobile') == 'false'

    this.#elements.textAlignment.data.find('.nav-item-custom').removeClass('active')
    this.#elements.textAlignment.data.find(`[data-value="${aligment.replace('default-text-', '')}"]`).addClass('active')

    // this.getRef(this.#textContent).bind({
    //   paste: function () {
    //     const editor = this
    //     setTimeout(function () {
    //       var text = $(editor).html()
    //       $(editor).html(text)
    //     }, 1)
    //   },
    // })

    mobileConfig.removeClass('active')
    this.#defaultValue = aligment.replace('default-text-', '')
    switch (!isMobileAlignBlocked) {
      case true:
        icon.removeClass('fa-unlock')
        icon.addClass('fa-lock')
        this.#elements.blockMobile.data.attr('disabled', true)
        this.getRef(this.#textContent).attr('data-block-mobile', true)
        this.#blockConfig = true
        mobileConfig.addClass('disabled')

        this.#mobileValue = aligment.replace('default-text-', '')

        this.#elements.textAlignmentMobile.data
          .find(`[data-value="${aligment.replace('default-text-', '')}"]`)
          .addClass('active')
        break
      case false:
        icon.removeClass('fa-lock')
        icon.addClass('fa-unlock')
        this.#elements.blockMobile.data.attr('disabled', false)
        this.getRef(this.#textContent).attr('data-block-mobile', false)
        this.#blockConfig = false
        mobileConfig.removeClass('disabled')

        this.#mobileValue = aligmentMobile.replace('mobile-text-', '')

        this.#elements.textAlignmentMobile.data
          .find(`[data-value="${aligmentMobile.replace('mobile-text-', '')}"]`)
          .addClass('active')
        break
    }
  }
}
