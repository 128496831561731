import { templateForm } from '../../helpers/form.settings'
import { verifyHasClass } from '../../helpers/helpers'
import {
  useAlignmentBlock,
  useColor,
  useFontSizeResponsive,
  useIconSelect,
  useWrapperWithLink,
} from '../../microComponents'
import BaseComponent from '../baseComponent'
import { FORM_LAYOUT } from './templates'

const useComponents = [useFontSizeResponsive, useIconSelect, useColor, useAlignmentBlock, useWrapperWithLink]
const ICON_WRAPPER = '.icon-wrapper'
const ICON = '.icon'
export default class IconComponent extends BaseComponent {
  #elements = {}

  // SETTERS
  set form(newForm) {
    this._form = newForm

    useComponents.map((component) => {
      const aux = component({
        form: this._form,
        keditor: this._keditor,
        elementRef: () => this.getRef('.icon-wrapper'),
        componentWrapper: ICON_WRAPPER,
        componentTarget: ICON,
      })

      this[aux.componentName] = aux
    })
  }

  template() {
    return templateForm({ children: FORM_LAYOUT })
  }

  method(target, action, callback) {
    return this.#elements[target].data.on(action, (event) => callback)
  }

  create() {
    if (!this._component || !this._keditor) return
  }

  init() {
    if (!this._form) return
    this.fontSizeResponsive.init(() => this.getRef('.icon'))
    this.color.init()
    this.iconSelect.init()
    this.alignmentSelect.init()
    this.wrapperLink.init()
  }

  show() {
    if (!this._component || !this._form) return
    const icon = this._component.find('.icon')

    const findOldSize = verifyHasClass(icon, ['fz-4x', 'fz-3xi', 'fz-4xi', 'fz-5xi', 'fz-6xi'])
    if (findOldSize) {
      const oldSize = {
        'fz-4x': '32',
        'fz-3xi': '48',
        'fz-4xi': '64',
        'fz-5xi': '80',
        'fz-6xi': '96',
      }

      icon.attr({
        'data-font_size_mobile': oldSize[findOldSize],
        'data-font_size_default': oldSize[findOldSize],
      })

      icon.css('font-size', oldSize[findOldSize] + 'px')
      icon.removeClass('fz-4x fz-3xi fz-4xi fz-5xi fz-6xi')
    }

    this.fontSizeResponsive.show(() => this.getRef('.icon'))
    this.color.show()
    this.iconSelect.show()
    this.alignmentSelect.show()
    this.wrapperLink.show()
  }
}
