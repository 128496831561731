import KEditor from 'keditor'
import { textOptions, textEditor, componentSpacing } from './helpers/helpers'

import { hideMobileDesktop, templateForm } from './helpers/form.settings'

import * as templates from './containers/testimonial/templates'

import TestimonialComponent from './containers/testimonial/methods'
import initTimeDelay from './helpers/timeDelay/initSettings'
import showTimeDelay from './helpers/timeDelay/showSettings'
import {initAnimations, showAnimations} from './helpers/animations'

let testimonialComponent
let CKEDITOR;
KEditor.components['card-testimonials'] = {
  settingEnabled: true,

  settingTitle: 'Depoimento',

  emptyContent: '<p class="text-muted lead text-center"><br />[Sem descrição]<br /><br /></p>',

  options: textOptions(true),

  init: function (contentArea, container, component, keditor) {
    let options = this.options

    let componentContent = component.children('.keditor-component-content')

    if (!componentContent.find('.photo-panel').find('.img-product').length) {
      componentContent.find('.photo-panel').append('<img class="img-product" max-width="100%" src="" alt="" />')
    }

    let img = componentContent.find('img')

    img.css('display', 'inline-block')
    img.css('max-width', '100%')

    img.on('click', function (e) {
      e.preventDefault()
    })

    textEditor(`.testimonial`, componentContent, keditor, options, contentArea, container, '', true)
    CKEDITOR = keditor.iframeWindow.window.CKEDITOR
  },

  getContent: function (component, keditor) {
    let componentContent = component.find('.keditor-component-content')
    let id = componentContent.attr('id')
    let editor = CKEDITOR.instances[id]
    if (editor) {
      return editor.getData()
    } else {
      componentContent.find('.testimonial').prop('contenteditable', false)
      return componentContent.html()
    }
  },

  destroy: function (component, keditor) {
    let id = component.children('.keditor-component-content').attr('id')
    CKEDITOR.instances[id] && CKEDITOR.instances[id].destroy()
  },

  initSettingForm: function (form, keditor) {
    form.append(templateForm({ children: templates.FORM_LAYOUT, advancedTemplate: templates.ADVANCED_TAB }))

    initTimeDelay.call(self, form, keditor)
    initAnimations(form,keditor)
    
    testimonialComponent = new TestimonialComponent(form, keditor)
    testimonialComponent.init()
    //Espaçamentos
    componentSpacing('.card-testimonial', form, null, keditor, 2)

    hideMobileDesktop(form, null, keditor, '.card-testimonial', 'edit')
  },

  showSettingForm: function (form, component, keditor) {
    const card = component.find('.card-testimonial')

    testimonialComponent.component = component
    testimonialComponent.show()

    showTimeDelay.call(self, form, component, keditor)
    showAnimations(form,keditor)
    // Espaçamentos
    componentSpacing(card, form, component, keditor, 1)

    hideMobileDesktop(form, card, keditor, null, '')
  },
}
