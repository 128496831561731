import { templateForm } from '../../helpers/form.settings'
import BaseComponent from '../baseComponent'

import { FORM_LAYOUT, FORM_ADVANCED } from './templates'

import {
    useAlignmentBlock,
    useBorderColor,
    useBorderRadius,
    useBorderStyle,
    useBorderWidth,
    useStickyPosition,
    useStickySize,
    useStickyToggleClose,
} from '../../microComponents'

import stickyArrManager from '../../helpers/stickyArrManager.js'


export default class VideoComponent extends BaseComponent {

    #VIDEO_WRAPPER = '.youtube-wrapper'
    #IFRAME_TARGET = 'iframe' 
    #SICKYSIZEATTR = `data-sticky-size` //found in StickySize.js too

    // SETTERS
    set form(newForm) {
        this._form = newForm

        const useComponents = [
            { config: useAlignmentBlock, target: () => this.getRef(this.#VIDEO_WRAPPER)}, 
            { config: useBorderColor, target: () => this.getRef(this.#IFRAME_TARGET)}, 
            { config: useBorderRadius, target: () => this.getRef(this.#IFRAME_TARGET)},
            { config: useBorderStyle, target: () => this.getRef(this.#IFRAME_TARGET)}, 
            { config: useBorderWidth, target: () => this.getRef(this.#IFRAME_TARGET)},
            { config: useStickyPosition, target: () => this.getRef(this.#IFRAME_TARGET)},
            { config: useStickySize, target: () => this.getRef(this.#IFRAME_TARGET)},
            { config: useStickyToggleClose, target: () => this.getRef(this.#IFRAME_TARGET)},
        ]

        useComponents.map((microComponent) => {
            
            const aux = microComponent.config({
                form: this._form,
                keditor: this._keditor,
                elementRef: microComponent.target,
            })

            this[aux.componentName] = aux
        })
    }

    template() {
        return templateForm({ children: FORM_LAYOUT, advancedTemplate: FORM_ADVANCED })
    }

    create(action = '') {
      if (!this._component || !this._keditor) return
      
      const iframeRef = this._component.find(this.#IFRAME_TARGET)

      //Generate unique id to iFrame if not have
      /**
       * This is important to turn Sticky microcomponet easier generic.
       * Dealing direct with the iFrame free us to deal with different parents and childs structures
       */
      if(!iframeRef.attr('id') || action == 'paste'){
        iframeRef.attr("id",this._keditor.generateId())
      }

      //Manage the sticky_function call on footer_system of the current component
      stickyArrManager(
          this._keditor,
          iframeRef.attr('id'),
          iframeRef.attr(this.#SICKYSIZEATTR) ? iframeRef.attr(this.#SICKYSIZEATTR) : 'none'
      )

    }

    delete() {
        if (!this._component || !this._keditor) return
        
        const iframeRef = this._component.find(this.#IFRAME_TARGET)

        //Try to remove sticky_function call on footer_system of this component if found
        stickyArrManager(
            this._keditor,
            iframeRef.attr('id'),
            'none'
        )
    }

    init() {
        if (!this._form || !this._keditor) return

        this.alignmentSelect.init()
        this.borderWidth.init()
        this.borderRadius.init()
        this.borderColor.init()
        this.borderStyle.init()
        this.stickyPosition.init()
        this.stickySize.init()
        this.stickyToggleClose.init()
    }

    show() {
        if (!this._component || !this._form) return

        this.alignmentSelect.show()
        this.borderWidth.show()
        this.borderRadius.show()
        this.borderColor.show()
        this.borderStyle.show()
        this.stickyPosition.show()
        this.stickySize.show()
        this.stickyToggleClose.show()

    }

}